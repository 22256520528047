import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
  label: string;
  value: string;
}

interface Props2 {
  label: string;
  value: string;
  label2: string;
  value2: string;
}

interface PropsR2<T> {
  label: T;
  value: number;
  label2: T;
  value2: number;
  total: number;
  values: T[],
  selected: T[],
  onChange: (selected: T[]) => void,
}

interface Props3 {
  values: string[],
  selected: string[],
  onChange: (selected: string[]) => void,
  label: string;
  value: number;
  label2: string;
  value2: number;
  label3: string;
  value3: number;
  total: number;
}

interface IconCellProps {
  icon: IconProp;
  label: string;
  total: string;
  average: string;
  chartLink: string;
  chartUrl: string;
}

interface CellWithOptionalButtons {
  children: React.ReactNode;
  className?: string;
  height?: number;
  padding?: number;
  color?: string,
  primaryIcon?: IconProp;
  primaryText?: string;
  primaryClick?: () => void;
  primaryBlocked?: boolean;
  secondaryIcon?: IconProp;
  secondaryText?: string;
  secondaryClick?: () => void;
}

export function GridCell(props: CellWithOptionalButtons) {
  const { primaryText, primaryClick, secondaryText, secondaryClick } = props;
  const numButtons = ((primaryText && primaryClick) ? 1 : 0) + ((secondaryText && secondaryClick) ? 1 : 0);
  const buttons = numButtons === 0
    ? null
    : (numButtons === 1
      ? (<div className="border-t border-gray-800">
        <div className="-mt-px flex">
          <div className="w-0 flex-1 flex border-t border-gray-800">
            <span onClick={props.primaryClick} className="relative cursor-pointer -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-400 font-medium border border-transparent rounded-bl-lg hover:text-primary-400 focus:outline-none focus:shadow-outline-blue focus:border-secondary-300 focus:z-10 transition ease-in-out duration-150">
              {props.primaryIcon
                ? <FontAwesomeIcon icon={props.primaryIcon} />
                : null}
              {/* <i className="fas fa-pencil-alt"></i> */}
              <span className="ml-3">{props.primaryText}</span>
            </span>
          </div>
        </div>
      </div>)
      : (<div className="border-t border-gray-800">
        <div className="-mt-px flex">
          <div className="w-0 flex-1 flex border-r border-gray-800">
            <span onClick={props.secondaryClick} className={`relative cursor-pointer -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-400 font-medium border border-transparent rounded-bl-lg hover:text-primary-400 focus:outline-none focus:shadow-outline-blue focus:border-secondary-300 focus:z-10 transition ease-in-out duration-150`}>
              {props.secondaryIcon
                ? <FontAwesomeIcon icon={props.secondaryIcon} />
                : null}
              <span className="ml-3">{props.secondaryText}</span>
            </span>
          </div>
          <div className="-ml-px w-0 flex-1 flex">
            <span onClick={props.primaryBlocked ? () => { } : props.primaryClick} className={`relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-${props.primaryBlocked ? '700 cursor-default' : '400 cursor-pointer hover:text-primary-400 focus:outline-none focus:shadow-outline-blue focus:border-secondary-300 focus:z-10 transition ease-in-out duration-150'} font-medium border border-transparent rounded-br-lg`}>
              {props.primaryIcon
                ? <FontAwesomeIcon icon={props.primaryIcon} />
                : null}
              <span className="ml-3">{props.primaryText}</span>
            </span>
          </div>
        </div>
      </div>)
    );

  // RK: do not expand height to h-full if there are buttons, as this will always require scrolling for the buttons :( needs a better solution
  const fullHeightClass = numButtons === 0 ? 'h-full' : '';
  const heightClass = props.height !== undefined ? `h-${props.height} overflow-y-auto` : '';
  const paddingClass = props.padding !== undefined ? `p-${props.padding} ${fullHeightClass}` : `px-4 py-5 sm:p-6 ${fullHeightClass}`;
  return (<div style={{ backgroundColor: props.color || undefined }} className={`border-gray-800 border overflow-hidden shadow rounded-lg w-full ${heightClass} ${props.className}`}>
    <div className={paddingClass}>
      {props.children}
    </div>
    {buttons}
  </div>
  );
}

export function DataCell(props: Props) {
  return (<GridCell>
    <dl className="grid h-full">
      <dt className="text-sm leading-5 font-medium text-gray-400 truncate">
        {props.label}
      </dt>
      <dd className="mt-1 text-4xl leading-9 font-semibold text-primary-400">
        {props.value}
      </dd>
    </dl>
  </GridCell>);
}

export function GridCellSplit(props: Props2) {
  return (<div className="border-gray-800 border overflow-hidden shadow rounded-lg mt-1 flex justify-center">
    <div className="px-2 py-5 sm:p-4">
      <dl>
        <dt className="text-sm leading-5 font-medium text-gray-400 truncate">
          {props.label}
        </dt>
        <dd className="mt-1 text-3xl leading-9 font-semibold text-primary-400">
          {props.value}
        </dd>
      </dl>
    </div>
    <div className="px-2 py-5 sm:p-4">
      <dl>
        <dt className="text-sm leading-5 font-medium text-gray-400 truncate">
          {props.label2}
        </dt>
        <dd className="mt-1 text-3xl leading-9 font-semibold text-primary-400">
          {props.value2}
        </dd>
      </dl>
    </div>
  </div>);
}

export function GridCell2Responsive<T>(props: PropsR2<T>) {
  const { label, label2, value, value2, total } = props;

  function toggle(val: T) {
    if (props.selected.indexOf(val) >= 0) {
      props.onChange(props.selected.filter((s) => s !== val))
    } else {
      props.onChange([...props.selected, val])
    }
  }

  return (<div className="border-gray-800 border overflow-hidden shadow rounded-lg mt-1 flex justify-around px-12">
    <div className="px-2 py-5 sm:p-4">
      <dl>
        <div onClick={() => toggle(label)} className="text-sm leading-5 font-medium text-gray-400 truncate outline-none cursor-pointer">
          {label}
          {props.selected?.indexOf(label) >= 0 ?
            <FontAwesomeIcon className='ml-1 text-primary-400' size='sm' icon={["fas", "check-circle"]} /> :
            <FontAwesomeIcon className='ml-1 text-gray-400' size='sm' icon={["far", "circle"]} />
          }
        </div>
        <div className='flex flex-row'>
          <dd className="mt-1 text-3xl leading-9 font-semibold text-primary-400">
            {total > 0 ? (100 * value / total).toFixed(0) + '%' : '--'}
          </dd>
          <dd className="mt-1 pt-1 pl-1 text-sm leading-9 font-semibold text-gray-400">
            {value}
          </dd>
        </div>
      </dl>
    </div>
    <div className="px-2 py-5 sm:p-4">
      <dl>
        <div onClick={() => toggle(label2)} className="text-sm leading-5 font-medium text-gray-400 truncate outline-none cursor-pointer">
          {label2}
          {props.selected?.indexOf(label2) >= 0 ?
            <FontAwesomeIcon className='ml-1 text-primary-400' size='sm' icon={["fas", "check-circle"]} /> :
            <FontAwesomeIcon className='ml-1 text-gray-400' size='sm' icon={["far", "circle"]} />
          }
        </div>
        <div className='flex flex-row'>
          <dd className="mt-1 text-3xl leading-9 font-semibold text-primary-400">
            {total > 0 ? (100 * value2 / total).toFixed(0) + '%' : '--'}
          </dd>
          <dd className="mt-1 pt-1 pl-1 text-sm leading-9 font-semibold text-gray-400">
            {value2}
          </dd>
        </div>
      </dl>
    </div>
  </div>);
}


export function GridCellSplit3(props: Props3) {
  const { label, label2, label3, value, value2, value3, total } = props;

  function toggle(val: string) {
    if (props.selected.indexOf(val) >= 0) {
      props.onChange(props.selected.filter((s) => s !== val))
    } else {
      props.onChange([...props.selected, val])
    }
  }

  return (<div className="border-gray-800 border overflow-hidden shadow rounded-lg mt-1 flex justify-around px-8">
    <div className="px-2 py-5 sm:p-4">
      <dl>
        <div onClick={() => toggle(label)} className="text-sm leading-5 font-medium text-gray-400 truncate outline-none cursor-pointer">
          {label}
          {props.selected?.indexOf(label) >= 0 ?
            <FontAwesomeIcon className='ml-1 text-primary-400' size='sm' icon={["fas", "check-circle"]} /> :
            <FontAwesomeIcon className='ml-1 text-gray-400' size='sm' icon={["far", "circle"]} />
          }
        </div>
        <div className='flex flex-row'>
          <dd className="mt-1 text-2xl leading-9 font-semibold text-primary-400">
            {total > 0 ? (100 * value / total).toFixed(0) + '%' : '--'}
          </dd>
          <dd className="mt-1 pt-1 pl-1 text-sm leading-9 font-semibold text-gray-400">
            {value}
          </dd>
        </div>
      </dl>
    </div>
    <div className="px-2 py-5 sm:p-4">
      <dl>
        <div onClick={() => toggle(label2)} className="text-sm leading-5 font-medium text-gray-400 truncate outline-none cursor-pointer">
          {label2}
          {props.selected?.indexOf(label2) >= 0 ?
            <FontAwesomeIcon className='ml-1 text-primary-400' size='sm' icon={["fas", "check-circle"]} /> :
            <FontAwesomeIcon className='ml-1 text-gray-400' size='sm' icon={["far", "circle"]} />
          }
        </div>
        <div className='flex flex-row'>
          <dd className="mt-1 text-2xl leading-9 font-semibold text-primary-400">
            {total > 0 ? (100 * value2 / total).toFixed(0) + '%' : '--'}
          </dd>
          <dd className="mt-1 pt-1 pl-1 text-sm leading-9 font-semibold text-gray-400">
            {value2}
          </dd>
        </div>
      </dl>
    </div>    <div className="px-2 py-5 sm:p-4">
      <dl>
        <div onClick={() => toggle(label3)} className="text-sm leading-5 font-medium text-gray-400 truncate outline-none cursor-pointer">
          {label3}
          {props.selected?.indexOf(label3) >= 0 ?
            <FontAwesomeIcon className='ml-1 text-primary-400' size='sm' icon={["fas", "check-circle"]} /> :
            <FontAwesomeIcon className='ml-1 text-gray-400' size='sm' icon={["far", "circle"]} />
          }
        </div>
        <div className='flex flex-row'>
          <dd className="mt-1 text-2xl leading-9 font-semibold text-primary-400">
            {total > 0 ? (100 * value3 / total).toFixed(0) + '%' : '--'}
          </dd>
          <dd className="mt-1 pt-1 pl-1 text-sm leading-9 font-semibold text-gray-400">
            {value3}
          </dd>
        </div>
      </dl>
    </div>
  </div>);
}

export function IconGridCell(props: IconCellProps) {
  const history = useHistory();
  return (<GridCell primaryText={props.chartLink} primaryClick={() => history.push(props.chartUrl)}>
    <div className="flex items-center">
      <div className="flex-shrink-0 bg-gray-600 rounded-md p-3 h-12 w-12 text-center text-white">
        <FontAwesomeIcon icon={props.icon} />
      </div>
      <div className="ml-5 w-0 flex-1">
        <dl>
          <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
            {props.label}
          </dt>
          <dd className="flex items-baseline">
            <div className="text-2xl leading-8 font-semibold text-gray-100">
              {props.total}
            </div>
            <div className="ml-2 flex items-baseline text-sm leading-5 font-semibold text-primary-400">
              <span className="sr-only">
                Yards per play
              </span>
              {props.average}
            </div>
          </dd>
        </dl>
      </div>
    </div>
  </GridCell>);
}
