import {Storage} from '../hooks/storage';
import {Strength} from './strength';

export enum Hash {
  Left = 'Left',
  Right = 'Right',
  Middle = 'Middle',
  Error = 'Error',
}

export const HashValues = new Map<string, Hash>();
HashValues.set('l', Hash.Left);
HashValues.set('r', Hash.Right);
HashValues.set('m', Hash.Middle);

export enum ODK {
  Offense = 'Offense',
  Defense = 'Defense',
  Kicking = 'Kicking',
  Error = 'Error',
}

export const ODKValues = new Map<string, ODK>();
ODKValues.set('o', ODK.Offense);
ODKValues.set('d', ODK.Defense);
ODKValues.set('k', ODK.Kicking);

export enum PlayType {
  Pass = 'Pass',
  Run = 'Run',
  Error = 'Error',
}

export function playType(value: string) {
  const normalized = value.trim().substr(0, 1).toLocaleLowerCase();
  if (normalized === 'p') return PlayType.Pass;
  if (normalized === 'r') return PlayType.Run;
  return PlayType.Error;
}

const PlayTypeValues = new Map<string, PlayType>();
PlayTypeValues.set('p', PlayType.Pass);
PlayTypeValues.set('r', PlayType.Run);

export enum PlayResult {
  Return = 'Return',
  Penalty = 'Penalty',
  Scramble = 'Scramble',
  Rush = 'Rush',
  Interception = 'Interception',
  Incomplete = 'Incomplete',
  Complete = 'Complete',
  RushTD = 'Rush, TD',
  Good = 'Good',
  Recovered = 'Recovered',
  CompleteTD = 'Complete, TD',
  Timeout = 'Timeout',
  Safety = 'Safety',
  FairCatch = 'Fair Catch',
  Sack = 'Sack',
  NoGood = 'No Good',
  Touchback = 'Touchback',
  Downed = 'Downed',
  OutOfBounds = 'Out of Bounds',
  Fumble = 'Fumble',
  Error = 'Error',
}

export const PlayResultValues = new Map<string, PlayResult>();
PlayResultValues.set('return', PlayResult.Return);
PlayResultValues.set('penalty', PlayResult.Penalty);
PlayResultValues.set('scramble', PlayResult.Scramble);
PlayResultValues.set('rush', PlayResult.Rush);
PlayResultValues.set('interception', PlayResult.Interception);
PlayResultValues.set('incomplete', PlayResult.Incomplete);
PlayResultValues.set('complete', PlayResult.Complete);
PlayResultValues.set('rushtd', PlayResult.RushTD);
PlayResultValues.set('good', PlayResult.Good);
PlayResultValues.set('recovered', PlayResult.Recovered);
PlayResultValues.set('completetd', PlayResult.CompleteTD);
PlayResultValues.set('timeout', PlayResult.Timeout);
PlayResultValues.set('safety', PlayResult.Safety);
PlayResultValues.set('faircatch', PlayResult.FairCatch);
PlayResultValues.set('sack', PlayResult.Sack);
PlayResultValues.set('nogood', PlayResult.NoGood);
PlayResultValues.set('touchback', PlayResult.Touchback);
PlayResultValues.set('downed', PlayResult.Downed);
PlayResultValues.set('outofbounds', PlayResult.OutOfBounds);
PlayResultValues.set('fumble', PlayResult.Fumble);

export enum Direction {
  Left = 'Left',
  Right = 'Right',
  Error = 'Error',
}

export const DirectionValues = new Map<string, Direction>();
DirectionValues.set('l', Direction.Left);
DirectionValues.set('r', Direction.Right);

export enum Tendency {
  Left = 'Left',
  Right = 'Right',
  Balanced = 'Balanced',
  Error = 'Error',
}

export const TendencyValues = new Map<string, Tendency>();
TendencyValues.set('l', Tendency.Left);
TendencyValues.set('r', Tendency.Right);
TendencyValues.set('b', Tendency.Balanced);

export enum PassHitZone {
  DeepThirdLeft = 0,
  DeepThirdMiddle = 1,
  DeepThirdRight = 2,
  CurlFlatLeft = 3,
  HookCurlLeft = 4,
  MidHook = 5,
  HookCurlRight = 6,
  CurlFlatRight = 7,
  FlatSwingLeft = 8,
  FlatSwingRight = 9,
  Error = 10,
}
export function hitZone(value: number): PassHitZone {
  switch (value) {
    case 0: return 0;
    case 1: return 1;
    case 2: return 2;
    case 3: return 3;
    case 4: return 4;
    case 5: return 5;
    case 6: return 6;
    case 7: return 7;
    case 8: return 8;
    case 9: return 9;
    default: return 10;
  }
}

const runGaps = ['Error', 0, 1, 2, 3, 4, 5, 6, 7] as const;
export type RunGap = typeof runGaps[number];
export function runGap(value: number): RunGap {
  switch (value) {
    case 0: return 0;
    case 1: return 1;
    case 2: return 2;
    case 3: return 3;
    case 4: return 4;
    case 5: return 5;
    case 6: return 6;
    case 7: return 7;
    default: return 'Error';
  }
}

export class Store {
  constructor(
    public games: Game[],
  ) { }

  plays(teamId: string, filter: (play: Play) => boolean) {
    return this.games
      .filter(game => game.teamId === teamId)
      .reduce<Play[]>((a, game) => {
        return a.concat(game.plays.filter(filter));
      }, []);
  }

  previousPlay(play: Play) {
    for (const game of this.games) {
      if (game.opponentName === play.opponent) {
        for (const gamePlay of game.plays) {
          if (play.playNumber === gamePlay.playNumber + 1) {
            return gamePlay;
          }
        }
      }
    }
  }

  possessionGame(play: Play) {
    for (const game of this.games) {
      if (game.opponentName === play.opponent || game.teamId === play.opponent) {
        return game.plays
          .filter(thePlay => thePlay.isTouchdown && thePlay.playNumber < play.playNumber)
          .reduce((a, play2) => (game.teamId === play.opponent? (play2.odk === ODK.Offense ? a + 1 : a - 1) : (play2.odk === ODK.Offense ? a - 1 : a + 1)), 0);
      }
    }
  }

  opponentsOf(teamId: string) {
    const opponents: string[] = [];
    for (let game of this.games) {
      if (game.teamId === teamId && !opponents.includes(game.opponentName)) {
        opponents.push(game.opponentName);
      } else if (game.opponentName === teamId && !opponents.includes(game.teamId)) {
        opponents.push(game.teamId);
      }
    }
    return opponents;
  }

  addApiResponse(data: ApiPlay[]) {
    const gameMap = new Map<string, Game>();
    for (const play of data) {
      const key = `${play.team_id}.${play.data.opponent}`;
      const game = gameMap.get(key);
      if (game) {
        game.plays.push(Play.copy(play.data));
      } else {
        gameMap.set(key, new Game(play.team_id, play.opponent_name, [Play.copy(play.data)]));
      }
    }
    for (const game of gameMap.values()) {
      game.plays.sort((a, b) => a.playNumber - b.playNumber);
      this.games.push(game);
    }
  }

  // TODO: check if this is necessary
  changeTeamName(currentName: string, newName: string) {
    this.games.forEach(game => {
      if (game.teamId === currentName) {
        game.teamId = newName;
      } else if (game.opponentName === currentName) {
        const newGame = Game.copy(game);
        newGame.opponentName = newName;
        newGame.plays = game.plays.map((play) => {
          const p = Play.copy(play);
          p.opponent = newName;
          return p;
        });
      }
    });
  }

  static copy(otherStore: Store) {
    return new Store(otherStore.games.map(game => Game.copy(game)));
  }

  static default() {
    return new Store([]);
  }
}

export class Game {
  constructor(
    public teamId: string,
    public opponentName: string,
    public plays: Play[],
  ) { }

  static copy(otherGame: Game) {
    return new Game(
      otherGame.teamId,
      otherGame.opponentName,
      otherGame.plays.map(play => Play.copy(play)),
    );
  }
}

interface ApiPlay {
  team_id: string;
  opponent_name: string;
  data: Play;
}

export class Play {
  offenseStrength: Strength;
  receiverSet: string;
  constructor(
    public opponent: string,
    public date: string,
    public playNumber: number,
    public quarter: number,
    public yardLine: number,
    public series: number,
    public hash: Hash,
    public down: number,
    public distance: number,
    public odk: ODK,
    public type: PlayType,
    public result: PlayResult,
    public offenseFormation: string,
    public offensePlay: string,
    public offenseTendency: Tendency,
    public defenseFormation: string,
    public defensePlay: string,
    public defenseTendency: Tendency,
    public backfield: string,
    public motion: string,
    public direction: Direction,
    public runGapTarget: RunGap,
    public ballCarrier: number,
    public passHitZone: PassHitZone,
    public receiverTargeted: number,
    public gain: number,
  ) {
    const runGap = this.runGapTarget === 'Error' ? '' : this.runGapTarget;
    const passZone = this.passHitZone === PassHitZone.Error ? '' : this.passHitZone;
    this.offenseStrength = new Strength(`${this.type}${this.offenseTendency}${runGap}${passZone}`);
    this.receiverSet = `${this.offenseFormation} ${this.offenseTendency}`;
  }

  get isTouchdown() {
    return this.result === PlayResult.CompleteTD || this.result === PlayResult.RushTD;
  }

  get isComplete() {
    return this.result !== PlayResult.Penalty && this.result !== PlayResult.Incomplete && this.result !== PlayResult.Interception;
  }

  get isSack() {
    return this.result === PlayResult.Sack;
  }

  get isPenalty() {
    return this.result === PlayResult.Penalty;
  }

  get isScramble() {
    return this.result === PlayResult.Scramble || this.result === PlayResult.Rush || this.result === PlayResult.RushTD;
  }

  static fromLine(line: string) {
    const i32 = (_: string) => Math.max(-2147483648, Math.min(2147483648, parseInt(_, 10)));
    const hash = (_: string) => HashValues.get(_.substr(0, 1).toLowerCase()) || Hash.Error;
    const odk = (_: string) => ODKValues.get(_.substr(0, 1).toLowerCase()) || ODK.Error;
    const type = (_: string) => PlayTypeValues.get(_.substr(0, 1).toLowerCase()) || PlayType.Error;
    const res = (_: string) => PlayResultValues.get(_.toLowerCase().replace(/[^a-z]/gi, '')) || PlayResult.Error;
    const dir = (_: string) => DirectionValues.get(_.substr(0, 1).toLowerCase()) || Direction.Error;
    const tend = (_: string) => TendencyValues.get(_.substr(0, 1).toLowerCase()) || Tendency.Error;
    const parts = line.split('|');
    if (parts.length > 22) {
      return new Play(
        parts[2],
        '',
        i32(parts[0]),
        i32(parts[3]),
        i32(parts[4]),
        i32(parts[5]),
        hash(parts[6]),
        i32(parts[7]),
        i32(parts[8]),
        odk(parts[9]),
        type(parts[10]),
        res(parts[11]),
        parts[12],
        parts[13],
        tend(parts[17]),
        'N/I',
        'N/I',
        Tendency.Error,
        parts[14],
        parts[15],
        dir(parts[16]),
        runGap(i32(parts[18])),
        i32(parts[19]),
        hitZone(i32(parts[20])),
        i32(parts[21]),
        i32(parts[22]),
      );
    }
    return undefined;
  }

  static copy(otherPlay: Play) {
    return new Play(
      otherPlay.opponent,
      otherPlay.date,
      otherPlay.playNumber || NaN,
      otherPlay.quarter || NaN,
      otherPlay.yardLine || NaN,
      otherPlay.series || NaN,
      otherPlay.hash,
      otherPlay.down || NaN,
      otherPlay.distance || NaN,
      otherPlay.odk,
      otherPlay.type,
      otherPlay.result,
      otherPlay.offenseFormation,
      otherPlay.offensePlay,
      otherPlay.offenseTendency,
      otherPlay.defenseFormation,
      otherPlay.defensePlay,
      otherPlay.defenseTendency,
      otherPlay.backfield,
      otherPlay.motion,
      otherPlay.direction,
      runGap(parseInt(`${otherPlay.runGapTarget}`, 10)),
      otherPlay.ballCarrier || NaN,
      otherPlay.passHitZone,
      otherPlay.receiverTargeted || NaN,
      otherPlay.gain || 0,
    );
  }
}

export const dataStorage = new Storage<Store>('/api/data', 'data_v2', new Store([]), true);
