import React from 'react';
import { useHistory } from 'react-router-dom';

export function NotFound() {
  function logout() {

  }
  const history = useHistory();
  const returnTo = `${window.location.origin}/logout`;
  return (<div>
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

        <div className="inline-block align-bottom bg-gray-800 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div>
            <div className="mx-auto flex items-center justify-center h-24 w-24 rounded-full bg-red-100">
              <i className="h-12 w-12 text-4xl text-red-600 fas fa-dizzy"></i>
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-100" id="modal-headline">
                Blown Coverage!
              </h3>
              <div className="mt-2">
                <p className="text-sm leading-5 text-gray-500">
                  Sorry, we can't seem to find this page. We apologize for the inconvenience, either go back to the page you were previously on or select login and then log back into the system. If problem persists you can contact our support team.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
              <button type="button" onClick={() => logout()} className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-primary-400 text-base leading-6 font-medium text-white shadow-sm hover:bg-primary-400 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                Log Out
              </button>
            </span>
            <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
              <button onClick={() => history.goBack()} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-700 px-4 py-2 bg-gray-800 text-base leading-6 font-medium text-gray-400 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                Go Back
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>);
}