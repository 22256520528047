import React, { useLayoutEffect, useState } from 'react';
import { defaultTeam, teamStorage, Team } from '../hooks/my-team-hook';

export function InjectStyles() {
  const [team, setTeam] = useState<Team>(defaultTeam());

  useLayoutEffect(() => {
    const s = [
      teamStorage.subscribe(setTeam),
    ];
    return () => s.forEach(s => s?.unsubscribe());
  }, []);

  return (<style>
    {`
    .bg-primary-900 {
      background-color: hsl(${team.primaryHue}, 100%, 15%);
    }
    .bg-primary-800 {
      background-color: hsl(${team.primaryHue}, 100%, 23.75%);
    }
    .bg-primary-700 {
      background-color: hsl(${team.primaryHue}, 100%, 32.5%);
    }
    .bg-primary-600 {
      background-color: hsl(${team.primaryHue}, 100%, 41.25%);
    }
    .bg-primary-500 {
      background-color: hsl(${team.primaryHue}, 100%, 50%);
    }
    .bg-primary-400 {
      background-color: hsl(${team.primaryHue}, 100%, 58.75%);
    }
    .bg-primary-300 {
      background-color: hsl(${team.primaryHue}, 100%, 67.5%);
    }
    .bg-primary-200 {
      background-color: hsl(${team.primaryHue}, 100%, 76.25%);
    }
    .bg-primary-100 {
      background-color: hsl(${team.primaryHue}, 100%, 85%);
    }
    .hover\\:bg-primary-900:hover {
      background-color: hsl(${team.primaryHue}, 100%, 15%);
    }
    .hover\\:bg-primary-800:hover {
      background-color: hsl(${team.primaryHue}, 100%, 23.75%);
    }
    .hover\\:bg-primary-700:hover {
      background-color: hsl(${team.primaryHue}, 100%, 32.5%);
    }
    .hover\\:bg-primary-600:hover {
      background-color: hsl(${team.primaryHue}, 100%, 41.25%);
    }
    .hover\\:bg-primary-500:hover {
      background-color: hsl(${team.primaryHue}, 100%, 50%);
    }
    .hover\\:bg-primary-400:hover {
      background-color: hsl(${team.primaryHue}, 100%, 58.75%);
    }
    .hover\\:bg-primary-300:hover {
      background-color: hsl(${team.primaryHue}, 100%, 67.5%);
    }
    .hover\\:bg-primary-200:hover {
      background-color: hsl(${team.primaryHue}, 100%, 76.25%);
    }
    .hover\\:bg-primary-100:hover {
      background-color: hsl(${team.primaryHue}, 100%, 85%);
    }
    .text-primary-900 {
      color: hsl(${team.primaryHue}, 100%, 15%);
    }
    .text-primary-800 {
      color: hsl(${team.primaryHue}, 100%, 23.75%);
    }
    .text-primary-700 {
      color: hsl(${team.primaryHue}, 100%, 32.5%);
    }
    .text-primary-600 {
      color: hsl(${team.primaryHue}, 100%, 41.25%);
    }
    .text-primary-500 {
      color: hsl(${team.primaryHue}, 100%, 50%);
    }
    .text-primary-400 {
      color: hsl(${team.primaryHue}, 100%, 58.75%);
    }
    .text-primary-300 {
      color: hsl(${team.primaryHue}, 100%, 67.5%);
    }
    .text-primary-200 {
      color: hsl(${team.primaryHue}, 100%, 76.25%);
    }
    .text-primary-100 {
      color: hsl(${team.primaryHue}, 100%, 85%);
    }

    .hover\\:text-primary-900:hover {
      color: hsl(${team.primaryHue}, 100%, 15%);
    }
    .hover\\:text-primary-800:hover {
      color: hsl(${team.primaryHue}, 100%, 23.75%);
    }
    .hover\\:text-primary-700:hover {
      color: hsl(${team.primaryHue}, 100%, 32.5%);
    }
    .hover\\:text-primary-600:hover {
      color: hsl(${team.primaryHue}, 100%, 41.25%);
    }
    .hover\\:text-primary-500:hover {
      color: hsl(${team.primaryHue}, 100%, 50%);
    }
    .hover\\:text-primary-400:hover {
      color: hsl(${team.primaryHue}, 100%, 58.75%);
    }
    .hover\\:text-primary-300:hover {
      color: hsl(${team.primaryHue}, 100%, 67.5%);
    }
    .hover\\:text-primary-200:hover {
      color: hsl(${team.primaryHue}, 100%, 76.25%);
    }
    .hover\\:text-primary-100:hover {
      color: hsl(${team.primaryHue}, 100%, 85%);
    }

    .focus\\:text-primary-900:focus {
      color: hsl(${team.primaryHue}, 100%, 15%);
    }
    .focus\\:text-primary-800:focus {
      color: hsl(${team.primaryHue}, 100%, 23.75%);
    }
    .focus\\:text-primary-700:focus {
      color: hsl(${team.primaryHue}, 100%, 32.5%);
    }
    .focus\\:text-primary-600:focus {
      color: hsl(${team.primaryHue}, 100%, 41.25%);
    }
    .focus\\:text-primary-500:focus {
      color: hsl(${team.primaryHue}, 100%, 50%);
    }
    .focus\\:text-primary-400:focus {
      color: hsl(${team.primaryHue}, 100%, 58.75%);
    }
    .focus\\:text-primary-300:focus {
      color: hsl(${team.primaryHue}, 100%, 67.5%);
    }
    .focus\\:text-primary-200:focus {
      color: hsl(${team.primaryHue}, 100%, 76.25%);
    }
    .focus\\:text-primary-100:focus {
      color: hsl(${team.primaryHue}, 100%, 85%);
    }

    .border-primary-900 {
      border-color: hsl(${team.primaryHue}, 100%, 15%);
    }
    .border-primary-800 {
      border-color: hsl(${team.primaryHue}, 100%, 23.75%);
    }
    .border-primary-700 {
      border-color: hsl(${team.primaryHue}, 100%, 32.5%);
    }
    .border-primary-600 {
      border-color: hsl(${team.primaryHue}, 100%, 41.25%);
    }
    .border-primary-500 {
      border-color: hsl(${team.primaryHue}, 100%, 50%);
    }
    .border-primary-400 {
      border-color: hsl(${team.primaryHue}, 100%, 58.75%);
    }
    .border-primary-300 {
      border-color: hsl(${team.primaryHue}, 100%, 67.5%);
    }
    .border-primary-200 {
      border-color: hsl(${team.primaryHue}, 100%, 76.25%);
    }
    .border-primary-100 {
      border-color: hsl(${team.primaryHue}, 100%, 85%);
    }

    .focus\\:border-primary-900:focus {
      border-color: hsl(${team.primaryHue}, 100%, 15%);
    }
    .focus\\:border-primary-800:focus {
      border-color: hsl(${team.primaryHue}, 100%, 23.75%);
    }
    .focus\\:border-primary-700:focus {
      border-color: hsl(${team.primaryHue}, 100%, 32.5%);
    }
    .focus\\:border-primary-600:focus {
      border-color: hsl(${team.primaryHue}, 100%, 41.25%);
    }
    .focus\\:border-primary-500:focus {
      border-color: hsl(${team.primaryHue}, 100%, 50%);
    }
    .focus\\:border-primary-400:focus {
      border-color: hsl(${team.primaryHue}, 100%, 58.75%);
    }
    .focus\\:border-primary-300:focus {
      border-color: hsl(${team.primaryHue}, 100%, 67.5%);
    }
    .focus\\:border-primary-200:focus {
      border-color: hsl(${team.primaryHue}, 100%, 76.25%);
    }
    .focus\\:border-primary-100:focus {
      border-color: hsl(${team.primaryHue}, 100%, 85%);
    }
    
    .bg-secondary-900 {
      background-color: hsl(${team.secondaryHue}, 100%, 15%);
    }
    .bg-secondary-800 {
      background-color: hsl(${team.secondaryHue}, 100%, 23.75%);
    }
    .bg-secondary-700 {
      background-color: hsl(${team.secondaryHue}, 100%, 32.5%);
    }
    .bg-secondary-600 {
      background-color: hsl(${team.secondaryHue}, 100%, 41.25%);
    }
    .bg-secondary-500 {
      background-color: hsl(${team.secondaryHue}, 100%, 50%);
    }
    .bg-secondary-400 {
      background-color: hsl(${team.secondaryHue}, 100%, 58.75%);
    }
    .bg-secondary-300 {
      background-color: hsl(${team.secondaryHue}, 100%, 67.5%);
    }
    .bg-secondary-200 {
      background-color: hsl(${team.secondaryHue}, 100%, 76.25%);
    }
    .bg-secondary-100 {
      background-color: hsl(${team.secondaryHue}, 100%, 85%);
    }
    .hover\\:bg-secondary-900:hover {
      background-color: hsl(${team.secondaryHue}, 100%, 15%);
    }
    .hover\\:bg-secondary-800:hover {
      background-color: hsl(${team.secondaryHue}, 100%, 23.75%);
    }
    .hover\\:bg-secondary-700:hover {
      background-color: hsl(${team.secondaryHue}, 100%, 32.5%);
    }
    .hover\\:bg-secondary-600:hover {
      background-color: hsl(${team.secondaryHue}, 100%, 41.25%);
    }
    .hover\\:bg-secondary-500:hover {
      background-color: hsl(${team.secondaryHue}, 100%, 50%);
    }
    .hover\\:bg-secondary-400:hover {
      background-color: hsl(${team.secondaryHue}, 100%, 58.75%);
    }
    .hover\\:bg-secondary-300:hover {
      background-color: hsl(${team.secondaryHue}, 100%, 67.5%);
    }
    .hover\\:bg-secondary-200:hover {
      background-color: hsl(${team.secondaryHue}, 100%, 76.25%);
    }
    .hover\\:bg-secondary-100:hover {
      background-color: hsl(${team.secondaryHue}, 100%, 85%);
    }
    .text-secondary-900 {
      color: hsl(${team.secondaryHue}, 100%, 15%);
    }
    .text-secondary-800 {
      color: hsl(${team.secondaryHue}, 100%, 23.75%);
    }
    .text-secondary-700 {
      color: hsl(${team.secondaryHue}, 100%, 32.5%);
    }
    .text-secondary-600 {
      color: hsl(${team.secondaryHue}, 100%, 41.25%);
    }
    .text-secondary-500 {
      color: hsl(${team.secondaryHue}, 100%, 50%);
    }
    .text-secondary-400 {
      color: hsl(${team.secondaryHue}, 100%, 58.75%);
    }
    .text-secondary-300 {
      color: hsl(${team.secondaryHue}, 100%, 67.5%);
    }
    .text-secondary-200 {
      color: hsl(${team.secondaryHue}, 100%, 76.25%);
    }
    .text-secondary-100 {
      color: hsl(${team.secondaryHue}, 100%, 85%);
    }

    .border-secondary-900 {
      color: hsl(${team.secondaryHue}, 100%, 15%);
    }
    .border-secondary-800 {
      color: hsl(${team.secondaryHue}, 100%, 23.75%);
    }
    .border-secondary-700 {
      color: hsl(${team.secondaryHue}, 100%, 32.5%);
    }
    .border-secondary-600 {
      color: hsl(${team.secondaryHue}, 100%, 41.25%);
    }
    .border-secondary-500 {
      color: hsl(${team.secondaryHue}, 100%, 50%);
    }
    .border-secondary-400 {
      color: hsl(${team.secondaryHue}, 100%, 58.75%);
    }
    .border-secondary-300 {
      color: hsl(${team.secondaryHue}, 100%, 67.5%);
    }
    .border-secondary-200 {
      color: hsl(${team.secondaryHue}, 100%, 76.25%);
    }
    .border-secondary-100 {
      color: hsl(${team.secondaryHue}, 100%, 85%);
    }
    
    .bg-accent-900 {
      background-color: hsl(${team.accentHue}, 100%, 15%);
    }
    .bg-accent-800 {
      background-color: hsl(${team.accentHue}, 100%, 23.75%);
    }
    .bg-accent-700 {
      background-color: hsl(${team.accentHue}, 100%, 32.5%);
    }
    .bg-accent-600 {
      background-color: hsl(${team.accentHue}, 100%, 41.25%);
    }
    .bg-accent-500 {
      background-color: hsl(${team.accentHue}, 100%, 50%);
    }
    .bg-accent-400 {
      background-color: hsl(${team.accentHue}, 100%, 58.75%);
    }
    .bg-accent-300 {
      background-color: hsl(${team.accentHue}, 100%, 67.5%);
    }
    .bg-accent-200 {
      background-color: hsl(${team.accentHue}, 100%, 76.25%);
    }
    .bg-accent-100 {
      background-color: hsl(${team.accentHue}, 100%, 85%);
    }
    .text-accent-900 {
      color: hsl(${team.accentHue}, 100%, 15%);
    }
    .text-accent-800 {
      color: hsl(${team.accentHue}, 100%, 23.75%);
    }
    .text-accent-700 {
      color: hsl(${team.accentHue}, 100%, 32.5%);
    }
    .text-accent-600 {
      color: hsl(${team.accentHue}, 100%, 41.25%);
    }
    .text-accent-500 {
      color: hsl(${team.accentHue}, 100%, 50%);
    }
    .text-accent-400 {
      color: hsl(${team.accentHue}, 100%, 58.75%);
    }
    .text-accent-300 {
      color: hsl(${team.accentHue}, 100%, 67.5%);
    }
    .text-accent-200 {
      color: hsl(${team.accentHue}, 100%, 76.25%);
    }
    .text-accent-100 {
      color: hsl(${team.accentHue}, 100%, 85%);
    }

    .border-accent-900 {
      color: hsl(${team.accentHue}, 100%, 15%);
    }
    .border-accent-800 {
      color: hsl(${team.accentHue}, 100%, 23.75%);
    }
    .border-accent-700 {
      color: hsl(${team.accentHue}, 100%, 32.5%);
    }
    .border-accent-600 {
      color: hsl(${team.accentHue}, 100%, 41.25%);
    }
    .border-accent-500 {
      color: hsl(${team.accentHue}, 100%, 50%);
    }
    .border-accent-400 {
      color: hsl(${team.accentHue}, 100%, 58.75%);
    }
    .border-accent-300 {
      color: hsl(${team.accentHue}, 100%, 67.5%);
    }
    .border-accent-200 {
      color: hsl(${team.accentHue}, 100%, 76.25%);
    }
    .border-accent-100 {
      color: hsl(${team.accentHue}, 100%, 85%);
    }

    `}
  </style>);
}