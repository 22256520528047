import React, { useEffect, useState } from 'react';
import { Block } from '../components/block';
import { ErrorBox } from '../components/error';
import { Heading } from '../components/heading';
import { Shell } from '../components/shell';
import firebase from "firebase/app";
import "firebase/auth";
import { LoggedOut } from './logged-out';
import {dataService} from "../config";
import {AvatarUpload} from "../components/avatar-upload";
import {FirebaseToken} from "../components/firebase-token";
import {Profile, profileStorage} from "../hooks/profile-storage";

export function UserSettingsPage() {
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [password, setPassword] = useState<string>('');
  const [first, setFirst] = useState<string>('');
  const [last, setLast] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [photoURI, setPhotoURI] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [position, setPosition] = useState<string>('');
  const [jwt, setJwt] = useState<string>('');
  const [submissionState, setSubmissionState] = useState<'' | 'savingEmail' | 'savingPassword' | 'savingProfile' | 'done'>('');
  const [profile, setProfile] = useState(Profile.default());

  useEffect(() => {
    setFirst(profile.firstName);
    setLast(profile.lastName);
    setLocation(profile.location);
    setPosition(profile.position);
    setPhotoURI(profile.avatar);
  }, [profile]);

  useEffect(() => {
    if (jwt) {
      profileStorage.authenticate(jwt);
      profileStorage.update('network', 'replace');
      // TODO: disable the form if update fails.
    }
  }, [jwt]);

  useEffect(() => {
    const l = [profileStorage.subscribe(profiles => setProfile(profiles.get('') as Profile))];
    const u = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setEmail(user.email || '');
      }
    });
    return () => {
      l.forEach(s => s?.unsubscribe());
      u();
    };
  }, []);

  useEffect(() => {
    const firebaseUser = firebase.auth().currentUser;
    if (firebaseUser) {
      setLoading(true);
      if (submissionState === 'savingEmail') {
        firebaseUser.updateEmail(email)
          .finally(() => setSubmissionState('savingPassword'));
      } else if (submissionState === 'savingPassword') {
        firebaseUser.updatePassword(password)
          .finally(() => setSubmissionState('savingProfile'));
      } else if (submissionState === 'savingProfile') {
        profileStorage.save('', new Profile(
          photoURI,
          first,
          last,
          email,
          location,
          position,
        ))
          .then(() => {
            setSubmissionState('done');
            setLoading(false);
          });
      }
    } else {
      setError('Not logged in');
    }
  }, [submissionState]);

  if (!jwt) {
    return <>
      <FirebaseToken onChange={setJwt} />
      <LoggedOut />
    </>;
  }

  return (<Shell>
    <Heading>User Settings</Heading>
    {error ? <ErrorBox>{error}</ErrorBox> : (loading ? <SettingsLoading /> : <SettingsLoaded />)}
    <Block>
      {submissionState}
      <form className="max-w-3xl m-auto text-left">
        <div className="p-4">
          <div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="username" className="block text-sm font-medium leading-5 text-gray-400">
                  Email/Username
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input type="text" id="username" className="form-input rounded-md block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-400">
                  Password
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input type="password" id="password" className="form-input rounded-md block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700" value={password} onChange={e => setPassword(e.currentTarget.value)} />
                </div>
              </div>
              <AvatarUpload name="Photo" onChange={setPhotoURI} value={photoURI} />
            </div>
          </div>
          <div className="mt-8 border-t border-gray-900 pt-8">
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="first_name" className="block text-sm font-medium leading-5 text-gray-400">
                  First name
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input type="text" id="first_name" className="form-input rounded-md block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700" value={first} onChange={(e) => setFirst(e.target.value)} />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="last_name" className="block text-sm font-medium leading-5 text-gray-400">
                  Last name
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input type="text" id="last_name" className="form-input rounded-md block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700" value={last} onChange={(e) => setLast(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="first_name" className="block text-sm font-medium leading-5 text-gray-400">
                  Location
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input type="text" id="first_name" className="form-input rounded-md block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700" value={location} onChange={(e) => setLocation(e.target.value)} />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="position" className="block text-sm font-medium leading-5 text-gray-400">
                  Position
                  <span className="text-gray-500 ml-1">(i.e. Offensive Coordinator)</span>
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <select id="position" className="form-select rounded-md block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700 cursor-pointer" value={position} onChange={e => setPosition(e.currentTarget.value)} >
                    <option value='headCoach'>Head Coach</option>
                    <option value='asstCoach'>Assistant Coach</option>
                    <option value='offCoordinator'>Offensive Coordinator</option>
                    <option value='defCoordinator'>Defensive Coordinator</option>
                    <option value='posCoach'>Position Coach</option>
                    <option value='special'>Special Teams Coach</option>
                    <option value='other'>Other</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-900 pt-5">
          <div className="flex justify-end">
            <span className="inline-flex rounded-md shadow-sm">
              <button type="button" className="py-2 px-4 border border-gray-700 rounded-md text-sm leading-5 font-medium text-gray-400 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                Cancel
              </button>
            </span>
            <span className="ml-3 inline-flex rounded-md shadow-sm">
              <button type="button" className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary active:bg-primary-700 transition duration-150 ease-in-out" onClick={() => setSubmissionState('savingEmail')}>
                Save
              </button>
            </span>
          </div>
        </div>
      </form>
    </Block>

  </Shell>);
}

function SettingsLoading() {
  return (<Block>Loading</Block>);
}

function SettingsLoaded() {
  return (<Block>Loaded</Block>);
}
