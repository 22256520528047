import React, {useState} from "react";

interface ColorPickerProps {
  value: number;
  onChange: (value: number) => void;
}
export function ColorPicker(props: ColorPickerProps) {
  // RK: This color picker is "good enough" for where this project is now. It can make colors that pretty closely match
  //     any team and we can always add text input later if a team wants something very exact. I'm calling this good enough.
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const colors: React.ReactNode[] = [];
  if (isOpen) {
    for (let i = 180; i < 540; i += 5) {
      colors.push(<div key={i} onClick={() => props.onChange(i)} className="w-1/12 p-1 h-6" style={{ backgroundColor: `hsl(${i % 360}, 100%, 50%)` }}>{'\x0a'}</div>)
    }
  }
  return (<div className="rounded-md cursor-pointer mt-2 h-6 relative" onClick={() => setIsOpen(!isOpen)} style={{ backgroundColor: `hsl(${props.value % 360}, 100%, 50%)` }}>
    <div className="p-1">{'\x0a'}</div>
    {isOpen
      ? (<div className="absolute top-6 left-0 z-50 flex flex-wrap mb-4">
        {colors}
      </div>)
      : null}
  </div>);
}