import React, { createRef, useState } from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import { ErrorBox } from '../components/error';
import { useHistory, Link } from 'react-router-dom';
import {SocialLinks} from "../components/social-links";

export function Landing() {

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [working, setWorking] = useState<boolean>(false);
  const history = useHistory();
  const passwordRef = createRef<HTMLInputElement>();

  function keyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      if (e.currentTarget === passwordRef.current) {
        logIn();
      } else {
        if (passwordRef.current) {
          passwordRef.current.focus();
        }
      }
    }
  }

  function logIn() {
    setWorking(true);
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(() => {
        history.push('/schedule');
      })
      .catch((error) => {
        if (error.code === 'auth/wrong-password') {
          setError('Wrong password');
        } else {
          setError(error.message);
        }
      })
      .finally(() => setWorking(false));
  }

  return (<div>

    {working
      ? <div className="fixed inset-2/4 bg-white bg-opacity-0 z-50 loader font-bold text-center">IQ</div>
      : null}

    {error
      ? <ErrorBox>{error}</ErrorBox>
      : null}

    <div className="min-h-screen bg-gray-900 flex text-left">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-12 w-auto" src="https://storage.googleapis.com/sportsiq/static/sports-iq-logo-white-color.png" alt="Workflow" />
            <h2 className="mt-6 text-3xl font-extrabold text-white">
              Sign in to your account
            </h2>
            <p className="mt-2 text-sm text-gray-400 max-w">
              Or
              <Link to="/registration" className="font-medium text-primary-600 hover:text-primary-500 ml-1">
                request a Sports IQ account
              </Link>
            </p>
          </div>

          <div className="mt-8">
            <div>
              <SocialLinks show={false} />

              <div className="mt-6 relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-800" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-gray-900 text-gray-400">
                    Sign-in here
              </span>
                </div>
              </div>
            </div>

            <div className="mt-6">
              <form action="#" method="POST" className="space-y-6">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-400">
                    Email address
              </label>
                  <div className="mt-1">
                    <input id="email" name="email" type="email" required className="appearance-none block w-full px-3 py-2 border border-gray-700 bg-gray-900 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm" value={email} onChange={e => setEmail(e.currentTarget.value)} onKeyUp={keyUp} />
                  </div>
                </div>

                <div className="space-y-1">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-400">
                    Password
              </label>
                  <div className="mt-1">
                    <input id="password" name="password" type="password" required className="appearance-none block w-full px-3 py-2 border border-gray-700 bg-gray-900 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm" value={password} onChange={e => setPassword(e.currentTarget.value)} onKeyUp={keyUp} ref={passwordRef} />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input id="remember_me" name="remember_me" type="checkbox" className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded" />
                    <label htmlFor="remember_me" className="ml-2 block text-sm text-white">
                      Remember me
                    </label>
                  </div>

                  <div className="text-sm">
                    <Link to="/reset-password" className="font-medium text-primary-600 hover:text-primary-500">
                      Forgot your password?
                    </Link>
                  </div>
                </div>

                <div>
                  <button type="button" onClick={logIn} className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img className="absolute inset-0 h-full w-full object-cover" src="https://storage.googleapis.com/sportsiq/static/landing-bg.jpg" alt="" />
      </div>
    </div>

  </div >);
}