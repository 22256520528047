import React, { useState } from 'react';
import { Block } from '../components/block';
import { Heading } from '../components/heading';
import { ErrorBox } from '../components/error';
import firebase from "firebase/app";
import "firebase/auth";
import { useHistory } from 'react-router-dom';
import {dataService} from "../config";

export function RegistrationPage() {

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [teamName, setTeamName] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errors, setErrors] = useState<string[]>([]);
  const [working, setWorking] = useState<number>(0);
  const history = useHistory();

  function register() {
    if (firstName.length === 0) {
      alert("Please fill in your first name.");
      return;
    }
    if (lastName.length === 0) {
      alert("Please fill in your last name.");
      return;
    }
    if (teamName.length === 0) {
      alert("Please fill in your team name.");
      return;
    }
    const emailValid = 
    emailAddress.length > 0 
    && emailAddress.indexOf('@') > 0 
    && emailAddress.substring(emailAddress.indexOf('@')).indexOf('.') > 0
    if (!emailValid) {
      alert("Please enter a valid email address");
      return;
    }
    if (password.length < 6) {
      alert("Your password must be at least 6 characters.");
      return;
    }
    let workingSem = 0;
    const token = Math.floor(Math.random() * 899999999 + 1000000000);
    setWorking(++workingSem);
    fetch(`${dataService}/create-account`, {
      method: 'POST',
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email_address: emailAddress,
        team_name: teamName,
        token,
      }),
    })
      .then(res => res.json())
      .then(data => {
        if (typeof data === 'object' && 'errors' in data && data.errors.length > 0) {
          setErrors(data.errors);
        } else if (typeof data !== 'object' || !data.success) {
          setErrors(['An unexpected error occurred']);
        } else {
          // Success
          setWorking(++workingSem);
          firebase
            .auth()
            .createUserWithEmailAndPassword(emailAddress, password)
            .then(user => {
              fetch(`${dataService}/create-account/link`, {
                method: 'POST',
                body: JSON.stringify({
                  token,
                  auth_id: user.user?.uid,
                })
              })
                .then(res => res.json())
                .then(data => {
                  if (typeof data === 'object' && 'errors' in data && data.errors.length > 0) {
                    setErrors(data.errors);
                  } else if (typeof data !== 'object' || !data.success) {
                    setErrors(['An unexpected error occurred']);
                  } else {
                    // Success
                    history.push('/request-submitted');
                  }
                })
                .catch(e => setErrors([e.message]))
                .finally(() => setWorking(--workingSem));
            })
            .catch(e => setErrors([e.toString()]))
            .finally(() => setWorking(--workingSem));
        }
      })
      .catch(e => setErrors([e.toString()]))
      .finally(() => setWorking(--workingSem));
  }

  return (<div className="p-4">
    <Heading>Sign-up for Sports IQ</Heading>
    {working
      ? 'Working'
      : null}
    {errors
      ? <ErrorBox>
        <ul>
          {errors.map(e => <li key={e}>{e}</li>)}
        </ul>
      </ErrorBox>
      : null}
    <Block>
      <form className="max-w-3xl m-auto text-left">
        <div className="p-4">
          <div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label htmlFor="username" className="block text-sm font-medium leading-5 text-gray-400">
                  Email/Username
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input type="text" id="username" className="form-input rounded-md block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700" value={emailAddress} onChange={e => setEmailAddress(e.currentTarget.value)} />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label htmlFor="username" className="block text-sm font-medium leading-5 text-gray-400">
                  Password
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input type="text" id="username" className="form-input rounded-md block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700" value={password} onChange={e => setPassword(e.currentTarget.value)} />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label htmlFor="username" className="block text-sm font-medium leading-5 text-gray-400">
                  Your School/Team Name
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input type="text" id="username" className="form-input rounded-md block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700" value={teamName} onChange={e => setTeamName(e.currentTarget.value)} />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 border-t border-gray-900 pt-8">
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="first_name" className="block text-sm font-medium leading-5 text-gray-400">
                  Your First Name
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input type="text" id="first_name" className="form-input rounded-md block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700" value={firstName} onChange={e => setFirstName(e.currentTarget.value)} />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="last_name" className="block text-sm font-medium leading-5 text-gray-400">
                  Your Last Name
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input type="text" id="last_name" className="form-input rounded-md block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700" value={lastName} onChange={e => setLastName(e.currentTarget.value)} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-900 pt-5">
          <div className="flex justify-end">
            <span className="inline-flex rounded-md shadow-sm">
              <a href="/">
                <button type="button" className="py-2 px-4 border border-gray-700 rounded-md text-sm leading-5 font-medium text-gray-400 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                  Cancel
              </button>
              </a>
            </span>
            <span className="ml-3 inline-flex rounded-md shadow-sm">
              <button type="button" className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary active:bg-primary-700 transition duration-150 ease-in-out" onClick={register}>
                Submit for Approval
              </button>
            </span>
          </div>
        </div>
      </form>
    </Block>

  </div>);
}