import React from 'react';
import { Nav } from './nav';
import { TeamName } from "./team-name";

interface SideBarProps {
  teamId?: string;
}

export function MobileSideBar(props: SideBarProps) {
  return (<div className="md:flex md:flex-shrink-0">
    <div className="flex flex-col">
      <div className="flex flex-col flex-grow overflow-y-auto">
        <div className="dark-bg shadow fixed w-screen z-30 text-md font-bold text-left px-6 py-1 flex justify-between w-full bg-gray-900">
          <div className="text-left flex">
            <img className="h-10 w-10 mr-1" src="https://storage.googleapis.com/sportsiq/static/football-helmet-icon.png" alt="Workflow" />
            <div className="leading-10 truncate"><TeamName teamId={props.teamId} /></div>
          </div>
          <div className="text-right flex">
            <h4 className="leading-10">Scouting Dashboard</h4>
          </div>
        </div>
        <div className="mt-14 flex-1 flex flex-col pt-2 pb-4 w-52 fixed z-20">
          <Nav mobile={false} />
        </div>
      </div>
    </div>
  </div>);
}

export function DesktopSideBar(props: SideBarProps) {
  return (<div className="hidden md:flex md:flex-shrink-0">
    <div className="flex flex-col">
      <div className="flex flex-col flex-grow overflow-y-auto">
        <div className="dark-bg shadow fixed w-screen z-30 text-md font-bold text-left px-6 py-1 flex justify-between border-b border-gray-800 w-full">
          <div className="text-left flex">
            <img className="h-10 w-10 mr-1" src="https://storage.googleapis.com/sportsiq/static/football-helmet-icon.png" alt="Workflow" />
            <div className="leading-10 truncate"><TeamName teamId={props.teamId} /></div>
          </div>
          <div className="text-right flex">
            <h4 className="leading-10">Scouting Dashboard</h4>
          </div>
        </div>
        <div className="mt-14 flex-1 flex flex-col pt-2 pb-4 w-52 fixed z-20">
          <Nav mobile={false} />
        </div>
      </div>
    </div>
  </div>);
}
