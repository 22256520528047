import React, { useState } from 'react';
import firebase from "firebase/app";
import "firebase/auth";

export function ResetPasswordPage() {

  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [working, setWorking] = useState<boolean>(false);

  function sendPasswordReset() {
    setWorking(true);
    firebase.auth().sendPasswordResetEmail(email).then(function () {
      alert('Thank you! Your password reset email has been sent');
    })
      .catch(function (error) {
        setError(error.message);
      })
      .finally(() => setWorking(false));
  }

  return <div className="m-12">
    <div className="bg-gray-900 border border-gray-800 shadow text-left sm:rounded-lg min-w-min max-w-3xl m-auto">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-white">
          Password Reset Request
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-400">
          <p>
            Enter the email address associated with your account into the text field below and we will send you an email to reset your password.
          </p>
        </div>
        <div className="mt-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-400">
            Email Address
          </label>
          <div className="mt-1">
            <input id="email" name="email" type="email" required className="appearance-none block w-full px-3 py-2 border border-gray-700 bg-gray-900 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm" value={email} onChange={e => setEmail(e.currentTarget.value)} />
          </div>
        </div>
        <div className="mt-8 border-t border-gray-900 pt-5">
          <div className="flex justify-end">
            <span className="inline-flex rounded-md shadow-sm">
              <a href="/">
                <button type="button" className="py-2 px-4 border border-gray-700 rounded-md text-sm leading-5 font-medium text-gray-400 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                  Cancel
                </button>
              </a>
            </span>
            <span className="ml-3 inline-flex rounded-md shadow-sm">
              <button type="button" className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary active:bg-primary-700 transition duration-150 ease-in-out" onClick={sendPasswordReset}>
                Reset my Password
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>;
}