import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

interface Props<K> {
  choices: Map<K, string>,
  display: 'inline' | 'block' | 'block-open',
  label: string,
  onChange: (_: K[]) => void,
  selected: K[],
}
export function MapSelect<K>(props: Props<K>) {
  const [open, setOpen] = useState<boolean>(false);
  const { display, choices, selected, onChange } = props;
  const isBlock = display === 'block';
  const isInline = display === 'inline';

  // Close menu on body click
  useEffect(() => {
    const handler = (e: MouseEvent) => {
      const target = e.target as Element;
      if (!target.closest('.ux-drop-down')) {
        setOpen(false);
      }
    };
    if (isBlock && open) {
      document.body.addEventListener('click', handler);
    }
    return function cleanup() {
      document.body.removeEventListener('click', handler);
    }
  });

  function toggle(option: K) {
    onChange(
      selected.indexOf(option) >= 0
        ? selected.filter(k => k !== option)
        : selected.concat([option])
    );
  }

  const options = Array.from(choices.entries()).map(entry => <MapSelectOption
    display={isInline ? 'inline' : 'block'}
    checkedIcon={['fas', 'check-circle']}
    uncheckedIcon={null}
    onClick={() => toggle(entry[0])}
    label={entry[1]}
    isSelected={selected.indexOf(entry[0]) >= 0}
  />);

  const selectedText = selected.length === 0
    ? 'None'
    : (selected.length === choices.size
      ? 'All'
      : selected.map(a => choices.get(a)).join(', ')
    );

  const position = isBlock ? 'absolute bg-gray-900' : '';
  const flex = isInline ? 'flex justify-evenly' : '';

  return (<div className="space-y-1 w-100 mt-4">
    <label id="listbox-label" className="block text-sm leading-5 font-medium text-gray-400">
      {props.label}
    </label>
    <div className="relative">
      {!isBlock ? null : <span className="inline-block w-full rounded-md shadow-sm" onClick={() => setOpen(!open)}>
        <button type="button" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" className="cursor-pointer relative w-full rounded-md border border-gray-800 pl-3 pr-10 py-3 text-left focus:outline-none focus:shadow-outline-blue focus:border-primary-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
          <span className="block truncate">
            {selectedText}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none" stroke="currentColor">
              <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>
        </button>
      </span>}

      {(!open && isBlock) ? null : <div className={`ux-drop-down ${position} mt-1 w-full rounded-md border border-gray-800 shadow-lg z-10`}>
        <ul tabIndex={-1} role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" className={`${flex} max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5`}>
          {options}
        </ul>
      </div>}
    </div>
  </div>);
}

interface OptionProps {
  checkedIcon: IconProp,
  display: 'inline' | 'block',
  isSelected: boolean,
  label: string,
  onClick: () => void,
  uncheckedIcon: IconProp | null,
}
function MapSelectOption(props: OptionProps) {
  const label = <span className="font-normal block truncate">{props.label}</span>;
  return (<li onClick={props.onClick} key={Math.random()} role="option" className={props.display === 'block'
    ? "cursor-pointer text-gray-100 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-gray-800"
    : "rounded-md flex cursor-pointer text-gray-100 cursor-default select-none relative py-2 px-4 hover:bg-gray-800"}>
    {props.display === 'block' ? label : null}
    <span className={props.display === 'block'
      ? "text-primary-400 absolute inset-y-0 right-0 flex items-center pr-4"
      : "flex items-center pr-2 text-primary-400"}>
      {props.isSelected
        ? <FontAwesomeIcon icon={props.checkedIcon} />
        : (props.uncheckedIcon
          ? <FontAwesomeIcon icon={props.uncheckedIcon} />
          : null)}
    </span>
    {props.display === 'inline' ? label : null}
  </li >);
}
