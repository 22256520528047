import React from 'react';
import { Block } from './block';

interface Props {
  children: React.ReactNode;
}

export function Heading(props: Props) {
  return (<Block>
    <h1 className="text-2xl font-semibold text-gray-100 p-4">{props.children}</h1>
  </Block>);
}
