import * as CRC32 from 'crc-32';

export type Game = { date: Date, status: 'W' | 'L' | 'T' | '?' };
export interface ApiGame {
  date: string;
  status: 'W' | 'L' | 'T' | '?';
}

export interface ApiTeam {
  id: string;
  account_id: string;
  name: string;
  mascot: string;
  games: ApiGame[];
  city: string;
  state: string;
  logo_uri: string;
}

export class Team {
  constructor(
    public id: number,
    public name: string,
    public mascot: string,
    public logo: string,
    public city: string,
    public state: string,
    public primaryHue: number,
    public secondaryHue: number,
    public accentHue: number,
    public games: Game[],
  ) { }

  hash(): number {
    return CRC32.str(this.name) + 2147483648;
  }

  static copy(team: Team): Team {
    return new Team(
      team.id,
      team.name,
      team.mascot,
      team.logo,
      team.city,
      team.state,
      team.primaryHue,
      team.secondaryHue,
      team.accentHue,
      Array.from(team.games),
    );
  }

  static default(): Team {
    return new Team(
      0,
      'Your Team Name',
      '',
      '/img/football-helmet-icon.png',
      '',
      '',
      120,
      210,
      265,
      [],
    );
  }
}