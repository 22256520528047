import React, { useEffect, useState } from 'react';
import { Modal } from './modal';

export function MobileMessage(): JSX.Element {
  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
  }, [])

  useEffect(() => {
    if (width < 768) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [width]);

  if (open) {
    return (
      <Modal title="Warning" instruction="" icon={['fas', 'desktop']} primaryButton="Continue" onPrimaryClick={() => setOpen(false)} onSecondaryClick={() => setOpen(false)}>
        <p>
          This application is best best viewed on desktop or tablet screens.
      </p>
      </Modal>
    )
  } else {
    return (<div />)
  }
}