import React from 'react';

interface BlockProps {
    children: React.ReactNode;
    paddingY?: number;
    paddingX?: number;
}
export function Block(props: BlockProps) {
    const padding = `${props.paddingY === undefined ? '' : `py-${props.paddingY}`} ${props.paddingX === undefined ? 'px-4 sm:px-6 md:px-8' : `px-${props.paddingX}`}`;
    return (<div className={`max-w-7xl mx-auto ${padding}`}>{props.children}</div>);
}
