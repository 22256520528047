import { BehaviorSubject } from "rxjs";
import * as LZString from 'lz-string';

const pipes = new Map<string, BehaviorSubject<any>>();

type CachePolicy = 'no-cache' | 'network';
type MergePolicy = 'merge' | 'replace';

class OnlineOperation {
  // TODO
}

/**
 * Storage2 is an improvement to the storage system. The major change is that it only uses maps from
 * string => T, to allow for updating a single item in a collection (ie one team) easier.
 */
export class Storage2<T> {
  private jwt: string | undefined = undefined;
  constructor(
    public retrieve: (jwt?: string, key?: string) => Promise<Map<string, T>>,
    public store: (map: Map<string, T>, jwt?: string, key?: string) => Promise<any>,
    public upgrade: (value: T, oldVersion: string) => T,
    public copy: (raw: T) => T,
    public localStorageKey: string,
    public defaultKey: string,
    public defaultValue: T,
    public compressed: boolean,
    public cacheLifetime: number,
    public version: string,
  ) {
    if (!pipes.has(this.localStorageKey)) {
      // Create default map
      const defaultMap = new Map<string, T>();
      defaultMap.set(this.defaultKey, this.defaultValue);
      pipes.set(this.localStorageKey, new BehaviorSubject<Map<string, T>>(defaultMap));
      // Try to read localStorage
      this.readLocalStorage();
      // Check if the item is expired
      if (this.localCopyIsExpired) {
        this.update('network', 'replace');
      }
    }
  }

  private deserializeMap(map: string) {
    const mapAsArray = JSON.parse(this.compressed
      ? JSON.parse(LZString.decompress(map) || 'null')
      : JSON.parse(map));
    if (mapAsArray) {
      return new Map<string, T>(mapAsArray.map((e: [string, T]) => [e[0], this.copy(e[1])]));
    }
    return undefined;
  }

  private readLocalStorage() {
    try {
      const item = localStorage.getItem(this.valueKey);
      if (item) {
        const parsed = this.deserializeMap(item);
        if (!parsed) {
          throw new Error('could not parse');
        }
        // TODO: call upgrade() if necessary
        const pipe = pipes.get(this.localStorageKey);
        if (pipe) {
          pipe.next(parsed);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  private serializeMap(map: Map<string, T>) {
    return JSON.stringify(Array.from(map.entries()));
  }

  private writeLocalStorage(value: string, updateLifetime: boolean) {
    try {
      localStorage.setItem(
        this.valueKey,
        this.compressed
          ? LZString.compress(JSON.stringify(value))
          : JSON.stringify(value));
      localStorage.setItem(this.versionKey, this.version);
      if (updateLifetime) {
        localStorage.setItem(this.expireKey, `${new Date().getTime() + this.cacheLifetime}`);
      }
    } catch (error) {
      console.log(error);
    }
  }

  authenticate(jwt: string) {
    if (jwt !== this.jwt) {
      this.jwt = jwt;
      if (this.localCopyIsExpired) {
        this.update('network', 'replace');
      }
    }
  }

  update(cachePolicy: CachePolicy, mergePolicy: MergePolicy) {
    return new Promise((resolve, reject) => {
      const pipe = pipes.get(this.localStorageKey);
      if (pipe) {
        this.retrieve(this.jwt)
          .then(map => {
            if (mergePolicy === 'replace') {
              pipe.next(map);
              this.writeLocalStorage(this.serializeMap(map), true);
            }
            resolve();
          })
          .catch(error => reject(error));
      }
    });
  }

  save(key: string, value: T): Promise<any> {
    const pipe = pipes.get(this.localStorageKey);
    if (pipe) {
      const map = pipe.getValue() as Map<string, T>;
      map.set(key, value);
      pipe.next(map);
      this.writeLocalStorage(this.serializeMap(map), false);
      return this.store(map, this.jwt, key);
    }
    return new Promise((resolve, reject) => {
      reject(new Error('Could not locate pipe'));
    });
  }

  subscribe(setState: (a: Map<string, T>) => void) {
    const pipe = pipes.get(this.localStorageKey);
    if (pipe) {
      return pipe.asObservable().subscribe(setState);
    }
  }

  private get localCopyIsExpired() {
    const timestamp = localStorage.getItem(this.expireKey);
    if (timestamp) {
      return new Date() > new Date(timestamp);
    }
    return true;
  }

  get valueKey() {
    return `s2.${this.localStorageKey}.value`;
  }

  get expireKey() {
    return `s2.${this.localStorageKey}.expires`;
  }

  get versionKey() {
    return `s2.${this.localStorageKey}.version`;
  }
}