import React, {useEffect} from 'react';
import firebase from "firebase";

interface FirebaseTokenProps {
  onChange: (token: string) => void;
}

export function FirebaseToken(props: FirebaseTokenProps) {

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user.getIdToken().then(props.onChange);
      }
    });
  }, []);

  return <></>;
}