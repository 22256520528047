import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Splash } from './splash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function LoggedOut() {

  const [delayed, setDelayed] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => setDelayed(true), 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (!delayed) {
    return <Splash />;
  }

  return (<div className="m-12">
    <div className="bg-gray-900 border border-gray-800 shadow text-left sm:rounded-lg min-w-min max-w-3xl m-auto">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-white">
          You are currently logged out
    </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-400">
          <p>
            You can log back in by clicking below, or simply close the window if you are done using the app.
      </p>
        </div>
        <Link to="/">
          <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-base mt-5 leading-5 rounded-md text-white bg-primary-600 transform hover:scale-105 focus:outline-none focus:border-primary-600 focus:ring-blue focus:ring-opacity-50 active:bg-primary-600 transition ease-in-out duration-150">
            Go to Login
            <FontAwesomeIcon icon={['fas', 'arrow-right']} className="ml-2 h-5 w-5" />
          </button>
        </Link>
      </div>
    </div>
  </div >);
}