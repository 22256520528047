import React, { useEffect, useState } from 'react';
import "firebase/auth";
import { FirebaseToken } from "./firebase-token";
import { Block } from "./block";
import { dataService } from "../config";
import { Modal } from "./modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvatarUpload } from "./avatar-upload";
import { LabeledInput, LabeledSelect } from "./labeled-input";
import firebase from "firebase";

interface Coach {
  id: string;
  avatar: string;
  first_name: string;
  last_name: string;
  email_address: string;
  location: string;
  position: string;
}

export function Coaches() {
  const [coaches, setCoaches] = useState<Coach[]>([]);
  const [editingCoach, setEditingCoach] = useState<Coach | undefined>(undefined);
  const [jwt, setJwt] = useState('');
  const [loading, setLoading] = useState(true);
  const [inits, setInits] = useState(0);

  useEffect(() => {
    if (jwt) {
      fetch(`${dataService}/coaches`, {
        headers: {
          authorization: `Bearer ${jwt}`
        },
      })
        .then(res => res.json())
        .then(data => setCoaches(data))
        .finally(() => setLoading(false));
    }
  }, [jwt, inits]);

  function newCoach() {
    setEditingCoach({
      id: '',
      first_name: '',
      last_name: '',
      email_address: '',
      avatar: '',
      location: coaches.length > 0 ? coaches[0].location : '',
      position: 'headCoach',
    });
  }

  function closeModal() {
    setInits(inits + 1);
    setEditingCoach(undefined);
  }

  const positions = new Map([
    ['headCoach', 'Head Coach'],
    ['asstCoach', 'Assistant Coach'],
    ['offCoordinator', 'Offensive Coordinator'],
    ['defCoordinator', 'Defensive Coordinator'],
    ['posCoach', 'Position Coach'],
    ['special', 'Special Teams Coach'],
    ['other', 'Other']
  ])

  return <>
    <FirebaseToken onChange={setJwt} />
    <Block>
      <CoachForm isOpen={!!editingCoach} positions={positions} setIsOpen={closeModal} />
      <div className="flex flex-col mt-6 text-left">
        <div className="pb-5 space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
          <h3 className="text-lg leading-6 font-medium text-gray-100">
            Coach Users
          </h3>
          <div>
            <span className="shadow-sm rounded-md">
              <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:shadow-outline-blue focus:border-primary-700 active:bg-primary-700 transition duration-150 ease-in-out" onClick={newCoach}>
                <FontAwesomeIcon icon={['fas', 'plus']} className="mr-2" />
                Coach
              </button>
            </span>
          </div>
        </div>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border border-gray-800 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-900 table-fixed w-100">
                <thead className="border-b border-gray-800">
                  <tr>
                    <th className="py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-50">
                      <div className="px-6">Name</div>
                    </th>
                    <th className="py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-35">
                      <div className="px-6">Pos./Access Level</div>
                    </th>
                    <th className="py-3 bg-gray-50 w-15">
                      <div className="px-6" />
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-800">
                  {loading
                    ? <tr className="max-w-full"><td>Loading</td></tr>
                    : null}
                  {coaches.map(coach => <UserRow key={coach.id} avatar={coach.avatar} name={`${coach.first_name} ${coach.last_name}`} email={coach.email_address} position={positions.get(coach.position) || "Other"} access="Administrator" />)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Block>
  </>;
}

interface UserRowProps {
  avatar: string;
  name: string;
  email: string;
  position: string;
  access: string;
}

function UserRow(props: UserRowProps) {
  return (<tr className="max-w-full">
    <td className="px-4 py-4 truncate">
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <img className="h-10 w-10 rounded-full" src={props.avatar} alt={props.name} />
        </div>
        <div className="ml-4">
          <div className="text-sm leading-5 font-medium text-gray-100 truncate">
            {props.name}
          </div>
          <div className="text-sm leading-5 text-gray-500 truncate">
            {props.email}
          </div>
        </div>
      </div>
    </td>
    <td className="px-4 py-4 truncate">
      <div>
        <div className="text-sm leading-5 font-medium text-gray-100 truncate">
          {props.position}
        </div>
        <div className="text-sm leading-5 text-gray-500 truncate">
          <i className="fa fa-eye mr-2" />
          {props.access}
        </div>
      </div>
    </td>
    <td className="px-4 py-4 text-right text-sm leading-5 font-medium truncate">
      {/*<a href="#" className="block text-primary-400 hover:text-primary-600"><FontAwesomeIcon icon={['fas', 'pencil-alt']} /></a>*/}
      {/*<a href="#" className="block mt-2 text-primary-400 hover:text-primary-600"><FontAwesomeIcon icon={['fas', 'trash']} /></a>*/}
    </td>
  </tr >);
}

interface CoachFormProps {
  isOpen: boolean;
  positions: Map<string, string>;
  setIsOpen: (_: boolean) => void;
}
function CoachForm(props: CoachFormProps) {

  const [jwt, setJwt] = useState('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [position, setPosition] = useState<string>('headCoach');
  const [accessLevel, setAccessLevel] = useState<string>('administrator');
  const [photoURI, setPhotoURI] = useState<string>('');

  function save() {
    firebase
      .app('tenant')
      .auth()
      .createUserWithEmailAndPassword(emailAddress, `${Math.random()}`)
      .then(user => {
        fetch(`${dataService}/coaches`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${jwt}`,
          },
          body: JSON.stringify({
            auth_id: user.user?.uid,
            first_name: firstName,
            last_name: lastName,
            email_address: emailAddress,
            location,
            position,
            access_level: accessLevel,
          }),
        })
          .then(() => {
            props.setIsOpen(false);
          });
      });


  }

  const accessLevels = new Map<string, string>();
  accessLevels.set('administrator', 'Administrator');
  // accessLevels.set('viewOnly', 'View Only');

  if (!props.isOpen) {
    return null;
  }

  return (<Modal
    title="Add Coach User"
    icon={['fas', 'user']}
    instruction='When you add a new coach they will receive an email to reset their password and access their account.'
    primaryButton="Save Changes"
    secondaryButton="Cancel"
    onPrimaryClick={save}
    onSecondaryClick={() => props.setIsOpen(false)}>
    <form className="w-full">
      <FirebaseToken onChange={setJwt} />
      <div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <AvatarUpload name="Photo" value={photoURI} onChange={setPhotoURI} />
          <div className="sm:col-span-3">
            <LabeledInput label="First Name" value={firstName} onChange={setFirstName} />
          </div>
          <div className="sm:col-span-3">
            <LabeledInput label="Last Name" value={lastName} onChange={setLastName} />
          </div>
          <LabeledInput label="E-mail Address" value={emailAddress} onChange={setEmailAddress} />
          <LabeledInput label="Location" value={location} onChange={setLocation} />
          <div className="sm:col-span-3">
            <LabeledSelect label="Position" value={position} options={props.positions} onChange={setPosition} />
          </div>
          <div className="sm:col-span-3">
            <LabeledSelect label="Access Level" value={accessLevel} options={accessLevels} onChange={setAccessLevel} />
          </div>
        </div>
      </div>
    </form>
  </Modal>);
}