import { Hash, Play, PlayType, Store } from "./data-v2";

export class FieldZoneChart {

  // Totals
  totalPlays: number;
  averageGain: number;
  conversionRate: number;

  // Formation Tables
  allFormations: [string, number][];
  boundaryFormations: [string, number][];
  fieldFormations: [string, number][];
  leftFormations: [string, number][];
  middleFormations: [string, number][];
  rightFormations: [string, number][];
  strongFormations: [string, number][];
  weakFormations: [string, number][];

  // Play tables
  allPlays: [string, number][];
  boundaryPlays: [string, number][];
  fieldPlays: [string, number][];
  leftPlays: [string, number][];
  middlePlays: [string, number][];
  rightPlays: [string, number][];
  strongPlays: [string, number][];
  weakPlays: [string, number][];

  // Other Tables
  playerTable: [string, number, number][];
  playTypeTable: [string, number][];
  possessionGameTable: [string, number][];

  constructor(store: Store, allPlays: Play[], filteredPlays: Play[]) {
    this.totalPlays = 0;

    let conversions = 0;
    let gainPlays = 0;
    let totalGain = 0;

    const allFormationMap = new Map<string, [string, number]>();
    const fieldFormationMap = new Map<string, [string, number]>();
    const boundaryFormationMap = new Map<string, [string, number]>();
    const leftFormationMap = new Map<string, [string, number]>();
    const middleFormationMap = new Map<string, [string, number]>();
    const rightFormationMap = new Map<string, [string, number]>();
    const strongFormationMap = new Map<string, [string, number]>();
    const weakFormationMap = new Map<string, [string, number]>();

    const allPlayMap = new Map<string, [string, number]>();
    const fieldPlayMap = new Map<string, [string, number]>();
    const boundaryPlayMap = new Map<string, [string, number]>();
    const leftPlayMap = new Map<string, [string, number]>();
    const middlePlayMap = new Map<string, [string, number]>();
    const rightPlayMap = new Map<string, [string, number]>();
    const strongPlayMap = new Map<string, [string, number]>();
    const weakPlayMap = new Map<string, [string, number]>();

    const playerMap = new Map<number, [string, number, number]>();
    const playTypeMap = new Map<PlayType, [string, number]>();
    const possessionGameMap = new Map<string, [string, number]>();

    playTypeMap.set(PlayType.Run, ['Run', 0]);
    playTypeMap.set(PlayType.Pass, ['Pass', 0]);

    // Loop through all plays for data that should appear even when filtered out
    for (const play of allPlays) {
      // Formation maps
      if (play.offenseFormation.length > 0) {
        const offenseFormation = play.offenseFormation;
        if (!allFormationMap.has(offenseFormation)) {
          allFormationMap.set(offenseFormation, [offenseFormation, 0]);
        }
        if (play.offenseStrength.isField) {
          fieldFormationMap.set(offenseFormation, [offenseFormation, 0]);
        } else if (play.offenseStrength.isBoundary) {
          boundaryFormationMap.set(offenseFormation, [offenseFormation, 0]);
        }
        if (play.hash === Hash.Left) {
          leftFormationMap.set(offenseFormation, [offenseFormation, 0]);
        } else if (play.hash === Hash.Right) {
          rightFormationMap.set(offenseFormation, [offenseFormation, 0]);
        } else if (play.hash === Hash.Middle) {
          middleFormationMap.set(offenseFormation, [offenseFormation, 0]);
        }
        if (play.offenseStrength.isStrong) {
          strongFormationMap.set(offenseFormation, [offenseFormation, 0]);
        } else if (play.offenseStrength.isWeak) {
          weakFormationMap.set(offenseFormation, [offenseFormation, 0]);
        }
      }

      // Play maps
      if (play.offensePlay.length > 0) {
        const offensePlay = play.offensePlay;
        if (!allPlayMap.has(offensePlay)) {
          allPlayMap.set(offensePlay, [offensePlay, 0]);
        }
        if (play.offenseStrength.isField) {
          fieldPlayMap.set(offensePlay, [offensePlay, 0]);
        } else if (play.offenseStrength.isBoundary) {
          boundaryPlayMap.set(offensePlay, [offensePlay, 0]);
        }
        if (play.hash === Hash.Left) {
          leftPlayMap.set(offensePlay, [offensePlay, 0]);
        } else if (play.hash === Hash.Right) {
          rightPlayMap.set(offensePlay, [offensePlay, 0]);
        } else if (play.hash === Hash.Middle) {
          middlePlayMap.set(offensePlay, [offensePlay, 0]);
        }
        if (play.offenseStrength.isStrong) {
          strongPlayMap.set(offensePlay, [offensePlay, 0]);
        } else if (play.offenseStrength.isWeak) {
          weakPlayMap.set(offensePlay, [offensePlay, 0]);
        }
      }

      // Play/player maps
      if (!isNaN(play.ballCarrier) && !playerMap.has(play.ballCarrier)) {
        playerMap.set(play.ballCarrier, [`${play.ballCarrier}`, 0, 0]);
      }
      if (!isNaN(play.receiverTargeted) && !playerMap.has(play.receiverTargeted)) {
        playerMap.set(play.receiverTargeted, [`${play.receiverTargeted}`, 0, 0]);
      }

      const mappedPlayType = playTypeMap.get(play.type);
      if (mappedPlayType) {
        mappedPlayType[1]++;
      }

      const possValue = store.possessionGame(play);
      if (possValue !== undefined) {
        const possessionGame = possValue === 0
          ? '0' : (possValue > 0
            ? `+${possValue}`
            : `-${possValue}`);
        const mappedPossessionGame = possessionGameMap.get(possessionGame);
        if (!mappedPossessionGame) {
          possessionGameMap.set(possessionGame, [possessionGame, 0]);
        }
      }
    }

    // Loop through filtered plays to calculate data that only applies to filters
    for (const play of filteredPlays) {
      this.totalPlays++;
      if (!isNaN(play.gain)) {
        gainPlays++;
        totalGain += play.gain;
      }
      if (play.gain >= play.distance) {
        conversions++;
      }

      const offenseFormation = play.offenseFormation.length > 0
        ? play.offenseFormation
        : 'None';

      const mappedAllFormations = allFormationMap.get(offenseFormation);
      if (mappedAllFormations) {
        mappedAllFormations[1]++;
      }
      if (play.offenseStrength.isField) {
        const mappedFormation = fieldFormationMap.get(offenseFormation);
        if (mappedFormation) {
          mappedFormation[1]++;
        }
      } else if (play.offenseStrength.isBoundary) {
        const mappedFormation = boundaryFormationMap.get(offenseFormation);
        if (mappedFormation) {
          mappedFormation[1]++;
        }
      }
      if (play.hash === Hash.Left) {
        const mappedFormation = leftFormationMap.get(offenseFormation);
        if (mappedFormation) {
          mappedFormation[1]++;
        }
      } else if (play.hash === Hash.Right) {
        const mappedFormation = rightFormationMap.get(offenseFormation);
        if (mappedFormation) {
          mappedFormation[1]++;
        }
      } else if (play.hash === Hash.Middle) {
        const mappedFormation = middleFormationMap.get(offenseFormation);
        if (mappedFormation) {
          mappedFormation[1]++;
        }
      }
      if (play.offenseStrength.isStrong) {
        const mappedFormation = strongFormationMap.get(offenseFormation);
        if (mappedFormation) {
          mappedFormation[1]++;
        }
      } else if (play.offenseStrength.isWeak) {
        const mappedFormation = weakFormationMap.get(offenseFormation);
        if (mappedFormation) {
          mappedFormation[1]++;
        }
      }

      const offensePlay = play.offensePlay.length > 0
        ? play.offensePlay
        : 'None';

      const mappedPlay = allPlayMap.get(offensePlay);
      if (mappedPlay) {
        mappedPlay[1]++;
      }
      if (play.offenseStrength.isField) {
        const mappedPlay = fieldPlayMap.get(offensePlay);
        if (mappedPlay) {
          mappedPlay[1]++;
        }
      } else if (play.offenseStrength.isBoundary) {
        const mappedPlay = boundaryPlayMap.get(offensePlay);
        if (mappedPlay) {
          mappedPlay[1]++;
        }
      }
      if (play.hash === Hash.Left) {
        const mappedPlay = leftPlayMap.get(offensePlay);
        if (mappedPlay) {
          mappedPlay[1]++;
        }
      } else if (play.hash === Hash.Right) {
        const mappedPlay = rightPlayMap.get(offensePlay);
        if (mappedPlay) {
          mappedPlay[1]++;
        }
      } else if (play.hash === Hash.Middle) {
        const mappedPlay = middlePlayMap.get(offensePlay);
        if (mappedPlay) {
          mappedPlay[1]++;
        }
      }
      if (play.offenseStrength.isStrong) {
        const mappedPlay = strongPlayMap.get(offensePlay);
        if (mappedPlay) {
          mappedPlay[1]++;
        }
      } else if (play.offenseStrength.isWeak) {
        const mappedPlay = weakPlayMap.get(offensePlay);
        if (mappedPlay) {
          mappedPlay[1]++;
        }
      }

      const mappedBallCarrier = playerMap.get(play.ballCarrier);
      if (mappedBallCarrier) {
        mappedBallCarrier[1]++;
      }
      const mappedReceiver = playerMap.get(play.receiverTargeted);
      if (mappedReceiver) {
        mappedReceiver[2]++;
      }

      const possValue = store.possessionGame(play);
      if (possValue !== undefined) {
        const possessionGame = possValue === 0
          ? '0' : (possValue > 0
            ? `+${possValue}`
            : `-${possValue}`);
        const mappedPossessionGame = possessionGameMap.get(possessionGame);
        if (mappedPossessionGame) {
          mappedPossessionGame[1]++;
        }
      }
    }

    // Totals
    this.averageGain = totalGain / gainPlays;
    this.conversionRate = conversions / this.totalPlays;

    // Convert formation maps into tables
    this.allFormations = Array.from(allFormationMap.values());
    this.fieldFormations = Array.from(fieldFormationMap.values());
    this.boundaryFormations = Array.from(boundaryFormationMap.values());
    this.leftFormations = Array.from(leftFormationMap.values());
    this.middleFormations = Array.from(middleFormationMap.values());
    this.rightFormations = Array.from(rightFormationMap.values());
    this.strongFormations = Array.from(strongFormationMap.values());
    this.weakFormations = Array.from(weakFormationMap.values());

    // Convert play maps into tables
    this.allPlays = Array.from(allPlayMap.values());
    this.fieldPlays = Array.from(fieldPlayMap.values());
    this.boundaryPlays = Array.from(boundaryPlayMap.values());
    this.leftPlays = Array.from(leftPlayMap.values());
    this.middlePlays = Array.from(middlePlayMap.values());
    this.rightPlays = Array.from(rightPlayMap.values());
    this.strongPlays = Array.from(strongPlayMap.values());
    this.weakPlays = Array.from(weakPlayMap.values());

    // Convert player/play maps into tables
    this.playerTable = Array.from(playerMap.values());
    this.playTypeTable = Array.from(playTypeMap.values());
    this.possessionGameTable = Array.from(possessionGameMap.values());
  }
}