export type Pair = [number, number];
export type NamedPair = [string, Pair];
// These types are for UI integration of the pairs, becuase string maps to text input.
export type EditablePair = [string, string];
export type EditableNamedPair = [string, EditablePair];

export class CustomFilters {
  public distances: Pair[];
  public fieldZones: NamedPair[];
  public gainLosses: NamedPair[];
  public formations: EditablePair[];
  public receiverSets: EditablePair[];

  constructor() {
    this.distances = [
      [1, 3],
      [4, 7],
      [8, 100],
    ];
    this.fieldZones = [
      ['Backed Up', [-19, 0]],
      ['Field', [-49, -20]],
      ['Midfield', [41, 50]],
      ['High Redzone', [21, 40]],
      ['Redzone', [10, 20]],
      ['Scoring', [1, 9]],
    ];
    this.gainLosses = [
      ['Explosive', [15, 100]],
      ['Big', [9, 14]],
      ['Medium', [4, 8]],
      ['Short', [1, 3]],
    ];
    this.formations = [];
    this.receiverSets = [];

    // These are sensible defaults for demo mode
    if (process.env.REACT_APP_DEMOMODE) {
      this.formations = [
        ['Trips', '10'],
        ['Pro', '12'],
        ['Heavy', '23'],
        ['Deuce', '10'],
        ['Spread', '11'],
      ];
    }
  }

  get valid() {
    // must have at least 1 distance
    if (this.distances.length < 1) {
      return false;
    }
    // check if it starts at 1 and ends at 100
    if (this.distances[0][0] !== 1 || this.distances[this.distances.length - 1][1] !== 100) {
      return false;
    }
    // check if values are contiguous
    for (let i = 0; i < this.distances.length - 1; i++) {
      if ((this.distances[i][1] + 1) !== this.distances[i + 1][0]) {
        return false;
      }
    }
    // do not allow duplicates in this.formations[n][0]
    const offForms = new Set<string>();
    for (const offForm of this.formations) {
      offForms.add(offForm[0]);
    }
    if (offForms.size < this.formations.length) {
      return false;
    }
    // don't allow duplicates
    const recSets = new Set<string>();
    for (const recSet of this.receiverSets) {
      recSets.add(recSet[0]);
    }

    return recSets.size >= this.receiverSets.length;
  }

  withDistances(distances: Pair[]) {
    const x = CustomFilters.copy(this);
    x.distances = distances;
    return x;
  }

  withFieldZones(fieldZones: NamedPair[]) {
    const x = CustomFilters.copy(this);
    x.fieldZones = fieldZones;
    return x;
  }

  withGainLosses(gainLosses: NamedPair[]) {
    const x = CustomFilters.copy(this);
    x.gainLosses = gainLosses;
    return x;
  }

  withFormations(formations: EditablePair[]) {
    const x = CustomFilters.copy(this);
    x.formations = formations;
    return x;
  }

  withReceiverSets(receiverSets: EditablePair[]) {
    const x = CustomFilters.copy(this);
    x.receiverSets = receiverSets;
    return x;
  }

  static copy(from: CustomFilters): CustomFilters {
    const x = CustomFilters.default();
    x.distances = from.distances || x.distances;
    x.fieldZones = from.fieldZones || x.fieldZones;
    x.gainLosses = from.gainLosses || x.gainLosses;
    x.formations = from.formations || x.formations;
    x.receiverSets = from.receiverSets || x.receiverSets;
    return x;
  }

  static default(): CustomFilters {
    return new CustomFilters();
  }
}