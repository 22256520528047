import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

export type TableValue = string | number;

interface TableProps<T> {
  data: Map<T, TableValue[]>,
  onChange: (_: T[]) => void,
  validate?: (_: T[]) => boolean,
  selected: T[],
  initialSort: [number, 'asc' | 'desc'],
  titles: string[],
  priority?: T[],
}
export function Table<T>(props: TableProps<T>) {
  const { data, onChange, selected, titles, validate } = props;
  const [sort, setSort] = useState<[number, 'asc' | 'desc']>(props.initialSort);

  function change(values: T[]) {
    if (validate === undefined || validate(values)) {
      onChange(values);
    }
  }

  function toggle(value: T) {
    return [value];
  }

  function select(value: T) {
    change(toggle(value));
  }

  function sortBy(index: number) {
    if (index === sort[0]) {
      setSort([sort[0], sort[1] === 'desc' ? 'asc' : 'desc']);
    } else {
      setSort([index, 'desc']);
    }
  }

  const allValid = validate === undefined || validate(Array.from(data.keys()));
  const validChanges = Array.from(data.entries()).map(entry => validate === undefined || validate(toggle(entry[0])));

  const firstRow = Array.from(data.values())[0];
  const alignments = firstRow
    ? firstRow.map(e => typeof e === 'number' ? 'text-right' : 'text-left')
    : titles.map(() => 'text-left');

  const entries: [T, TableValue[]][] = Array.from(data.entries())
    .sort((rowA, rowB) => {
      const flipper = sort[1] === 'desc' ? -1 : 1;
      const a = rowA[1][sort[0]];
      const b = rowB[1][sort[0]];
      if (props.priority) {
        if (props.priority.indexOf(rowA[0]) >= 0 && props.priority.indexOf(rowB[0]) == -1) {
          return (-1);
        }
        if (props.priority.indexOf(rowB[0]) >= 0 && props.priority.indexOf(rowA[0]) == -1)
          return (1);
      }
      if (typeof a === 'number' && typeof b === 'number') {
        return (a - b) * flipper;
      }
      return `${a}`.toLowerCase().localeCompare(`${b}`.toLowerCase()) * flipper;
    });

  function value(value: TableValue) {
    if (typeof value === 'number') {
      if (value === 0 || isNaN(value)) {
        return '--';
      }
      if (value > 0 && value < 1) {
        return `${(value * 100).toFixed(0)}%`;
      }
    }
    return value;
  }

  return (<div className="align-middle inline-block min-w-full overflow-hidden">
    <div className="overflow-y-auto sm:rounded-lg max-h-full p-3">
      <table className="min-w-full divide-y divide-gray-900 text-xs">
        <thead>
          <tr>
            <th className={`px-1 py-2 text-left leading-4 font-medium text-gray-500 uppercase tracking-wider ${allValid ? 'cursor-pointer hover:text-primary-400' : ''}`}>
              {selected.length >= data.size
                ? <FontAwesomeIcon onClick={() => change(Array.from(data.keys()))} icon={["fas", "check-circle"]} />
                : <FontAwesomeIcon onClick={() => change(Array.from(data.keys()))} icon={["far", "circle"]} />}
            </th>
            {titles.map((title, i) =>
              <th key={i} className={`px-1 py-2 leading-4 font-medium text-gray-500 uppercase cursor-pointer tracking-wider ${alignments[i]}`} onClick={() => sortBy(i)}>
                {title}
                {sort[0] === i
                  ? (sort[1] === 'asc' ? <FontAwesomeIcon icon={['fas', 'sort-up']} /> : <FontAwesomeIcon icon={['fas', 'sort-down']} />)
                  : null}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {entries.map((entry, i) =>
            <tr key={i} className={`${validChanges[i] ? 'cursor-pointer hover:bg-gray-900' : ''}`} onClick={() => select(entry[0])}>
              <td className={`px-1 py-1 whitespace-no-wrap text-left leading-5 font-medium ${validChanges[i] ? 'text-primary-400' : 'text-gray-400'}`}>
                {selected.includes(entry[0])
                  ? <FontAwesomeIcon icon={["fas", "check-circle"]} />
                  : <FontAwesomeIcon icon={["far", "circle"]} />}
              </td>
              {entry[1].map((col, i) =>
                <td key={i} className={`px-1 py-1 whitespace-no-wrap ${typeof col === 'string' ? 'text-left' : 'text-right'} leading-5 font-medium text-gray-100`}>
                  {value(col)}
                </td>
              )}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>);
}