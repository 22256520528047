import { Play, RunGap, Store, Tendency } from './data-v2';

function insert(key: string, table: TableDef) {
  const mappedRow = table.get(key);
  if (!mappedRow) {
    table.set(key, [key, 0, 0]);
  }
}

function increment(key: string, table: TableDef): void;
function increment(key: string, table: Map<string, number>): void;
function increment(key: any, table: any) {
  const mappedRow = table.get(key);
  if (mappedRow !== undefined) {
    if (typeof mappedRow === 'number') {
      table.set(key, mappedRow + 1);
    } else {
      table.set(key, [mappedRow[0], mappedRow[1] + 1, mappedRow[2]]);
    }
  }
}

function average(table: TableDef) {
  const sum = Math.max(1, Array.from(table.values()).reduce((a, e) => a + e[1], 0));
  for (const key of table.keys()) {
    const mappedRow = table.get(key);
    if (mappedRow) {
      table.set(key, [mappedRow[0], mappedRow[1], Math.max(0, mappedRow[1] / sum - 0.001)])
    }
  }
}

type TableDef = Map<string, [string, number, number]>;

export class RunZoneChart {
  // Totals
  plays: number;
  yardsPerCarry: number;

  // Gap Map
  runGaps: Map<RunGap, number>;

  // Tables
  backfieldTable: TableDef;
  ballCarrierTable: TableDef;
  strongWeakTable: Map<string, number>;
  rightLeftTable: Map<string, number>;
  receiverSetTable: TableDef;
  possessionGameTable: TableDef;
  playTable: TableDef;
  formationTable: TableDef;

  constructor(store: Store, allPlays: Play[], filteredPlays: Play[]) {
    this.plays = 0;
    let yards = 0;
    this.runGaps = new Map<RunGap, number>();
    this.backfieldTable = new Map<string, [string, number, number]>();
    this.ballCarrierTable = new Map<string, [string, number, number]>();
    this.strongWeakTable = new Map<string, number>();
    this.rightLeftTable = new Map<string, number>();
    this.receiverSetTable = new Map<string, [string, number, number]>();
    this.possessionGameTable = new Map<string, [string, number, number]>();
    this.playTable = new Map<string, [string, number, number]>();
    this.formationTable = new Map<string, [string, number, number]>();

    this.strongWeakTable.set('Strong', 0);
    this.strongWeakTable.set('Weak', 0);

    this.rightLeftTable.set('Right', 0);
    this.rightLeftTable.set('Balanced', 0);
    this.rightLeftTable.set('Left', 0);

    for (const play of allPlays) {
      if (play.backfield.length > 0) {
        insert(play.backfield, this.backfieldTable);
      }
      if (!isNaN(play.ballCarrier)) {
        insert(`${play.ballCarrier}`, this.ballCarrierTable);
      }
      insert(play.receiverSet, this.receiverSetTable);
      if (play.offensePlay.length > 0) {
        insert(play.offensePlay, this.playTable);
      }
      insert(play.offenseFormation, this.formationTable);

      // Possession game
      const possValue = store.possessionGame(play);
      if (possValue !== undefined) {
        const possessionGame = possValue === 0
          ? '0' : (possValue > 0
            ? `+${possValue}`
            : `-${possValue}`);
        insert(possessionGame, this.possessionGameTable);
      }
    }

    for (const play of filteredPlays) {
      this.plays++;
      yards += play.gain;

      const runGap = this.runGaps.get(play.runGapTarget);
      if (runGap) {
        this.runGaps.set(play.runGapTarget, runGap + 1);
      } else {
        this.runGaps.set(play.runGapTarget, 1);
      }

      increment(play.backfield, this.backfieldTable);
      increment(`${play.ballCarrier}`, this.ballCarrierTable);
      increment(play.receiverSet, this.receiverSetTable);
      increment(play.offensePlay, this.playTable);
      increment(play.offenseFormation, this.formationTable);

      // Possession game
      const possValue = store.possessionGame(play);
      if (possValue !== undefined) {
        const possessionGame = possValue === 0
          ? '0' : (possValue > 0
            ? `+${possValue}`
            : `-${possValue}`);
        increment(possessionGame, this.possessionGameTable);
      }

      if (play.offenseStrength.isStrong) {
        increment('Strong', this.strongWeakTable);
      } else if (play.offenseStrength.isWeak) {
        increment('Weak', this.strongWeakTable);
      }

      if (play.offenseTendency === Tendency.Left) {
        increment('Left', this.rightLeftTable);
      } else if (play.offenseTendency === Tendency.Right) {
        increment('Right', this.rightLeftTable);
      } else if (play.offenseTendency === Tendency.Balanced) {
        increment('Balanced', this.rightLeftTable);
      }

      // Add gain to playTable
      const mappedRow = this.playTable.get(play.offensePlay);
      if (mappedRow) {
        this.playTable.set(play.offensePlay, [mappedRow[0], mappedRow[1], mappedRow[2] + play.gain]);
      }
    }

    this.yardsPerCarry = yards / Math.max(1, this.plays);
    average(this.backfieldTable);
    average(this.ballCarrierTable);
    average(this.formationTable);
    average(this.receiverSetTable);
    average(this.possessionGameTable);

    // Avg gain in playTable
    for (const key of this.playTable.keys()) {
      const mappedRow = this.playTable.get(key);
      if (mappedRow) {
        const avgGain = Math.max(0, mappedRow[2] / mappedRow[1]);
        this.playTable.set(key, [mappedRow[0], mappedRow[1], Math.floor(avgGain * 10) / 10])
      }
    }
  }
}
