import React from 'react';

interface TimePickerProps {
  date: Date,
  onChange: (_: Date) => void,
}

function minuteToTime(minute: number): string {
  const theHour = Math.floor(minute / 12) % 12 > 0
    ? Math.floor(minute / 12) % 12
    : 12;
  const theMinute = minute * 5 % 60 < 10
    ? `0${minute * 5 % 60}`
    : `${minute * 5 % 60}`;
  const theM = minute < 144 ? ' AM' : ' PM';
  return `${theHour}:${theMinute}${theM}`;
}

export function TimePicker(props: TimePickerProps) {

  const minutes = Array.from(Array(288).keys());
  const selected = props.date.getHours() * 12 + Math.floor(props.date.getMinutes() / 5)

  function select(minute: number) {
    const newDate = new Date(props.date.getTime());
    newDate.setHours(Math.floor(minute / 12));
    newDate.setMinutes(minute * 5 % 60);
    props.onChange(newDate);
  }

  return <select value={selected} onChange={e => select(parseInt(e.currentTarget.value, 10))} className="form-select block rounded-md w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700 cursor-pointer">
    {minutes.map(minute => <option key={minute} value={`${minute}`}>
      {minuteToTime(minute)}
    </option>)}
  </select>;
}