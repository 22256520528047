import React from 'react';
import { HeaderBar } from './header-bar';
import { MobileSideBar, DesktopSideBar } from './side-bar';

interface ShellProps {
  children: React.ReactNode;
  teamId?: string;
}

interface SidebarShellProps {
  children: React.ReactNode;
  teamId?: string;
}

const wrapperClass = 'min-h-screen flex bg-gray-900 text-white pt-16';
const pageClass = 'flex flex-col w-0 flex-1 pt-6';
const pageWithSubHeaderClass = 'flex flex-col w-0 flex-1 pt-12';
const mainClass = 'flex-1 relative focus:outline-none';
const paddingWrapperClass = 'pb-6';
const tabIndex = 0;

export function ShellWithSideBar(props: SidebarShellProps) {
  return (<div>
    <HeaderBar />
    <div className={wrapperClass}>
      <MobileSideBar />
      <DesktopSideBar {...props} />
      <div className={pageWithSubHeaderClass}>
        <main className={mainClass} tabIndex={tabIndex}>
          <div className={paddingWrapperClass}>
            {props.children}
          </div>
        </main>
      </div>
    </div>
  </div>);
}

export function Shell(props: ShellProps) {
  return (<div>
    <HeaderBar />
    <div className={wrapperClass}>
      <div className={pageClass}>
        <main className={mainClass} tabIndex={tabIndex}>
          <div className={paddingWrapperClass}>
            {props.children}
          </div>
        </main>
      </div>
    </div>
  </div>);
}
