import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Block } from '../components/block';
import { FootballField } from '../components/football-field';
import { GridCell, GridCellSplit, GridCellSplit3 } from '../components/grid-cell';
import { ShellWithSideBar } from '../components/shell';
import { PassZoneChart } from '../logic/pass-zone-chart';
import { customFiltersStorage } from '../hooks/custom-filters';
import { CustomFilters, Pair } from '../logic/custom-filters';
import { MapSelect } from '../components/map-select-v2';
import { defaultTeam, Team, teamStorage } from '../hooks/my-team-hook';
import { useHistory } from 'react-router-dom';
import { dataStorage, Hash, ODK, PassHitZone, Play, PlayResult, PlayType, Store } from '../logic/data-v2';
import { Table, TableValue } from '../components/table';
import firebase from "firebase/app";
import "firebase/auth";
import { LoggedOut } from './logged-out';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// RK: This is necessary so that pointer problems don't happen during re-render
const noGain: Pair = [0, 0];
const loss: Pair = [-100, -1];

function baseFilter(play: Play) {
  return play.odk === ODK.Offense
    && play.type === PlayType.Pass
    && play.result !== PlayResult.Penalty;
}

// This function turns a setState function into a MapSelect shouldChange function
function mapSet<T>(setState: (_: T[]) => void) {
  return (values: T[]) => {
    setState(values);
    return true;
  };
}

// This one was fun. Turns [[1,3], [5, 9]] => [1,2,3,5,6,7,8,9]
function pairVectorToRange(input: Pair[]) {
  return input.reduce<number[]>((a, pair) => a.concat(Array.from(Array(pair[1] - pair[0] + 1).keys()).map(x => x + pair[0])), []);
}

function flatTableToMap(table: [string, number][]) {
  const map = new Map<string, TableValue[]>();
  const total = table.reduce((a, e) => a + e[1], 0);
  table.forEach(play => map.set(play[0], [
    play[0],
    play[1],
    Math.max((play[1] / Math.max(total, 1) - 0.001), 0),
  ]));
  return map;
}

export function PassZoneChartPage() {

  // Set up state/hooks
  const [user, setUser] = useState<boolean>(false);
  const [customFilterData, setCustomFilterData] = useState<CustomFilters>(CustomFilters.default());
  const [dataStoreData, setDataStoreData] = useState<Store>(Store.default());
  const [team, setTeam] = useState<Team>((defaultTeam()));
  const [filteredDistances, setFilteredDistances] = useState<Pair[]>([]);
  const [filteredDowns, setFilteredDowns] = useState<number[]>([1, 2, 3, 4]);
  const [filteredHashes, setFilteredHashes] = useState<Hash[]>([Hash.Left, Hash.Middle, Hash.Right]);
  const [filteredFieldZones, setFilteredFieldZones] = useState<Pair[]>([]);
  const [filteredPreviousGains, setFilteredPreviousGains] = useState<Pair[]>([]);
  const [filteredPlayNames, setFilteredPlayNames] = useState<string[]>([]);
  const [filteredFormations, setFilteredFormations] = useState<string[]>([]);
  const [filteredTeamNames, setFilteredTeamNames] = useState<string[]>([]);
  const [filteredPossessionGames, setFilteredPossessionGames] = useState<string[]>([]);
  const [filteredFieldBoundary, setFilteredFieldBoundary] = useState<string[]>(['Field', 'Middle', 'Boundary']);
  const [filteredStrongWeak, setFilteredStrongWeak] = useState<string[]>(['Strong', 'Middle', 'Weak']);
  const [filteredBackfields, setFilteredBackfields] = useState<string[]>([]);
  const [filteredReceiverTargets, setFilteredReceiverTargets] = useState<string[]>([]);
  const [filteredReceiverSets, setFilteredReceiverSets] = useState<string[]>([]);
  const [filteredPersonnelSets, setFilteredPersonnelSets] = useState<string[]>([]);
  const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(false);
  const [field3D, setField3D] = useState<boolean>(false);
  const [targetedTeamId, setTargetedTeamId] = useState<string | undefined>(undefined);
  const history = useHistory();

  useLayoutEffect(() => {
    const s = [
      customFiltersStorage.subscribe(setCustomFilterData),
      dataStorage.subscribe(setDataStoreData),
      teamStorage.subscribe(setTeam),
    ];
    return () => s.forEach(s => s?.unsubscribe());
  }, []);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user !== null);
      const queryString = history.location.search.substr(1);
      const matches = queryString.match(/team=(\d+)/);
      if (matches) {
        setTargetedTeamId(matches[1]);
      }
    });
  }, []);

  const rangeStr = (min: number, max: number) => min === max ? `${min}` : (max < 100 ? `${min}-${max}` : `${min}+`);
  const rangeStrV = (v: [number, number]) => rangeStr(v[0], v[1]);

  function filter(play: Play) {
    const personnelFormations: string[] = [];
    filteredPersonnelSets.forEach(personnelNumber => {
      for (const row of customFilters.formations) {
        if (row[1] === personnelNumber) {
          personnelFormations.push(row[0].toLowerCase());
        }
      }
    });
    const recSetNoAlias: string[] = Array.from(filteredReceiverSets.map((s) => s.toLowerCase()));
    const found: string[] = [];
    for (const alias of customFilters.receiverSets) {
      let index = recSetNoAlias.indexOf(alias[1].toLowerCase());
      if (index >= 0) {
        recSetNoAlias.splice(index, 1, alias[0].toLowerCase());
        found.push(alias[1]);
      } else {
        if (found.indexOf(alias[1]) >= 0) { //only push if this alias is in the filter
          recSetNoAlias.push(alias[0].toLowerCase());
        }
      }
    }
    const formationsWithoutPersonnel = filteredFormations.map(a => a.toLowerCase()).filter(a => !customFilters.formations.map(x => x[0].toLowerCase()).includes(a));
    const offensePlay = play.offensePlay.length > 0 ? play.offensePlay : 'None';
    const offenseFormation = play.offenseFormation.length > 0 ? play.offenseFormation : 'None';
    const noPlayer = isNaN(play.receiverTargeted);
    const possessionValue = dataStore.possessionGame(play);
    const possessionGame = possessionValue === 0
      ? '0' : ((possessionValue || 0) > 0
        ? `+${possessionValue}`
        : `-${possessionValue}`);

    return baseFilter(play)
      && pairVectorToRange(filteredDistances).includes(play.distance)
      && filteredDowns.includes(play.down)
      && pairVectorToRange(filteredFieldZones).includes(play.yardLine)
      && pairVectorToRange(filteredPreviousGains).includes(dataStore.previousPlay(play)?.distance || 0)
      && (play.offensePlay.length < 1 || filteredPlayNames.includes(offensePlay))
      && (noPlayer || filteredReceiverTargets.includes(`${play.receiverTargeted}`))
      && (recSetNoAlias.includes(`${play.receiverSet.toLowerCase()}`))
      && filteredFormations.includes(offenseFormation)
      && (personnelFormations.includes(offenseFormation.toLowerCase())
        || formationsWithoutPersonnel.includes(offenseFormation.toLowerCase()))
      && filteredTeamNames.includes(play.opponent)
      && filteredHashes.includes(play.hash)
      && (!play.backfield || filteredBackfields.includes(play.backfield))
      && ((filteredStrongWeak.includes('Strong') && play.offenseStrength.isStrong)
        || (filteredStrongWeak.includes('Middle') && play.offenseStrength.isMiddleStrength)
        || (filteredStrongWeak.includes('Weak') && play.offenseStrength.isWeak))
      && ((filteredFieldBoundary.includes('Field') && play.offenseStrength.isField)
        || (filteredFieldBoundary.includes('Middle') && play.offenseStrength.isMiddleFB)
        || (filteredFieldBoundary.includes('Boundary') && play.offenseStrength.isBoundary))
      && (possessionGame === undefined || filteredPossessionGames.includes(possessionGame));
  }

  const customFilters = CustomFilters.copy(customFilterData);
  const dataStore = Store.copy(dataStoreData);
  const teamId = targetedTeamId || '0';
  const allPlays = dataStore.plays(teamId, baseFilter);
  const filteredPlays = dataStore.plays(teamId, filter);
  const chart = new PassZoneChart(dataStore, allPlays, filteredPlays);

  const playTable = flatTableToMap(chart.playTable);
  const targetedReceiverTable = flatTableToMap(chart.targetedReceiverTable);
  const backfieldTable = flatTableToMap(chart.backfieldTable);
  const formationTable = flatTableToMap(chart.formationTable);

  const possessionGameTable = new Map<string, TableValue[]>();
  const possessionGameTotal = chart.possessionGameTable.reduce((a, e) => a + e[1], 0);
  chart.possessionGameTable.forEach(possessionGame => possessionGameTable.set(possessionGame[0], [
    possessionGame[0],
    possessionGame[1],
    Math.max((possessionGame[1] / Math.max(possessionGameTotal, 1) - 0.001), 0),
  ]));

  // pie charts
  const fieldBoundaryTable = new Map<string, number>();
  chart.fieldBoundaryTable.forEach(row => fieldBoundaryTable.set(row[0], row[1]));

  const strongWeakTable = new Map<string, number>();
  chart.strongWeakTable.forEach(row => strongWeakTable.set(row[0], row[1]));

  // personnel table is just a different way to look at the formationTable
  function personnelTable(chart: PassZoneChart) {
    const personnelTable = new Map<string, [string, number, number]>();
    chart.formationTable.forEach(row => {
      for (const personnelMapping of customFilters.formations) {
        if (personnelMapping[0].toLowerCase() === row[0].toLowerCase()) {
          const mapping = personnelTable.get(row[0]);
          if (mapping !== undefined) {
            personnelTable.set(personnelMapping[1], [personnelMapping[1], mapping[1] + row[1], 0]);
          } else {
            personnelTable.set(personnelMapping[1], [personnelMapping[1], row[1], 0]);
          }
        }
      }
    });
    const total = Array.from(personnelTable.values()).reduce((a, e) => a + e[1], 0);
    for (const key of personnelTable.keys()) {
      const mapping = personnelTable.get(key);
      if (mapping) {
        personnelTable.set(key, [mapping[0], mapping[1], Math.max(0, mapping[1] / total - 0.001)]);
      }
    }
    return personnelTable;
  }

  function PlayerCircle() {
    return (<li className="relative">
      <div className="absolute bottom-0 w-full">
        <div className="absolute bottom-0 rounded-full bg-gray-800 bg-opacity-75 h-8 w-8 mb-4 mx-2" />
      </div>
    </li >);
  }
  function PlayerCircleRB() {
    return (<li className="relative">
      <div className="rounded-full bg-gray-800 bg-opacity-75 h-8 w-8 mb-4 mx-2" />
    </li>);
  }
  function PlayerSquare() {
    return (<li className="relative">
      <div className="absolute bottom-0 w-full">
        <div className="bg-gray-800 bg-opacity-75 h-8 w-8 mb-4 mx-2" />
      </div>
    </li >);
  }

  function createReceiverSetTable(chart: PassZoneChart) {
    const recSetTable = new Map<string, [string, number, number]>();
    chart.receiverSetTable.forEach(row => {
      let found: boolean = false;
      for (const recSetMapping of customFilters.receiverSets) {
        if (recSetMapping[0].toLowerCase() === row[0].toLowerCase()) {
          const mapping = recSetTable.get(recSetMapping[1]);
          found = true;
          if (mapping !== undefined) {
            recSetTable.set(recSetMapping[1], [recSetMapping[1], mapping[1] + row[1], 0]);
          } else {
            recSetTable.set(recSetMapping[1], [recSetMapping[1], row[1], 0]);
          }
        }
      }
      if (!found) { // this formation strength hasn't been mapped, still needs to be added
        recSetTable.set(row[0], [...row, 0]);
      }
    });
    const total = Array.from(recSetTable.values()).reduce((a, e) => a + e[1], 0);
    for (const key of recSetTable.keys()) {
      const mapping = recSetTable.get(key);
      if (mapping) {
        recSetTable.set(key, [mapping[0], mapping[1], Math.max(0, mapping[1] / total - .001)])
      }
    }
    return recSetTable;
  }

  // Choices
  const distanceChoices = new Map<Pair, string>();
  for (const distance of customFilters.distances) {
    distanceChoices.set(distance, rangeStrV(distance));
  }

  const downChoices = new Map<number, string>();
  downChoices.set(1, '1');
  downChoices.set(2, '2');
  downChoices.set(3, '3');
  downChoices.set(4, '4');

  const hashChoices = new Map<Hash, string>();
  hashChoices.set(Hash.Left, 'L');
  hashChoices.set(Hash.Middle, 'MD');
  hashChoices.set(Hash.Right, 'R');

  const fieldZoneChoices = new Map<Pair, string>();
  for (let fieldZone of customFilters.fieldZones) {
    fieldZoneChoices.set(fieldZone[1], fieldZone[0]);
  }

  const previousGainChoices = new Map<Pair, string>();
  for (const gainChoice of customFilters.gainLosses) {
    previousGainChoices.set(gainChoice[1], gainChoice[0]);
  }
  previousGainChoices.set(noGain, 'No Gain');
  previousGainChoices.set(loss, 'Loss');

  const opponentChoices = new Map<string, string>();
  dataStore.opponentsOf(teamId).forEach(opponent => opponentChoices.set(opponent, opponent));

  // Revert state - allows clearing all filters
  interface RevertState {
    filteredDistances: Pair[],
    filteredDowns: number[],
    filteredFieldZones: Pair[],
    filteredPreviousGains: Pair[],
    filteredPlayNames: string[],
    filteredFormations: string[],
    filteredTeamNames: string[],
    filteredPossessionGames: string[],
    filteredHashes: Hash[],
    filteredFieldBoundary: string[],
    filteredStrongWeak: string[],
    filteredBackfields: string[],
    filteredReceiverTargets: string[],
    filteredReceiverSets: string[],
    filteredPersonnelSets: string[],
  }
  const [revertState, setRevertState] = useState<RevertState>({
    filteredDistances: [],
    filteredDowns: [],
    filteredFieldZones: [],
    filteredPreviousGains: [],
    filteredPlayNames: [],
    filteredFormations: [],
    filteredTeamNames: [],
    filteredPossessionGames: [],
    filteredHashes: [],
    filteredFieldBoundary: [],
    filteredStrongWeak: [],
    filteredBackfields: [],
    filteredReceiverTargets: [],
    filteredReceiverSets: [],
    filteredPersonnelSets: [],
  });
  function revert() {
    setFilteredDistances(revertState.filteredDistances);
    setFilteredDowns(revertState.filteredDowns);
    setFilteredFieldZones(revertState.filteredFieldZones);
    setFilteredPreviousGains(revertState.filteredPreviousGains);
    setFilteredPlayNames(revertState.filteredPlayNames);
    setFilteredFormations(revertState.filteredFormations);
    setFilteredTeamNames(revertState.filteredTeamNames);
    setFilteredPossessionGames(revertState.filteredPossessionGames);
    setFilteredHashes(revertState.filteredHashes);
    setFilteredFieldBoundary(revertState.filteredFieldBoundary);
    setFilteredStrongWeak(revertState.filteredStrongWeak);
    setFilteredBackfields(revertState.filteredBackfields);
    setFilteredReceiverTargets(revertState.filteredReceiverTargets);
    setFilteredReceiverSets(revertState.filteredReceiverSets);
    setFilteredPersonnelSets(revertState.filteredPersonnelSets);
  }

  // Update all filters when CF's & play data changes.
  useEffect(() => {
    const store = Store.copy(dataStoreData);
    const chart = (() => {
      const plays = store.plays(teamId, baseFilter);
      return new PassZoneChart(store, plays, plays);
    })();

    setFilteredTeamNames(store.opponentsOf(teamId));
    setFilteredPlayNames(chart.playTable.map(a => a[0]));
    setFilteredFormations(chart.formationTable.map(a => a[0]));
    setFilteredPossessionGames(chart.possessionGameTable.map(a => a[0]));
    setFilteredBackfields(chart.backfieldTable.map(a => a[0]));
    setFilteredReceiverTargets(chart.targetedReceiverTable.map(a => a[0]));
    setFilteredReceiverSets(Array.from(createReceiverSetTable(chart).keys()));
    setFilteredPersonnelSets(Array.from(personnelTable(chart).keys()));
    setFilteredDistances(customFilters.distances);
    setFilteredFieldZones(customFilters.fieldZones.map(a => a[1]));
    setFilteredPreviousGains(customFilters.gainLosses.map(a => a[1]).concat([noGain, loss]));
    setRevertState({
      filteredDistances: customFilters.distances,
      filteredDowns,
      filteredFieldZones: customFilters.fieldZones.map(a => a[1]),
      filteredPreviousGains: customFilters.gainLosses.map(a => a[1]).concat([noGain, loss]),
      filteredPlayNames: chart.playTable.map(a => a[0]),
      filteredFormations: chart.formationTable.map(a => a[0]),
      filteredTeamNames: store.opponentsOf(teamId),
      filteredPossessionGames: chart.possessionGameTable.map(a => a[0]),
      filteredHashes,
      filteredFieldBoundary,
      filteredStrongWeak,
      filteredBackfields: chart.backfieldTable.map(a => a[0]),
      filteredReceiverTargets: chart.targetedReceiverTable.map(a => a[0]),
      filteredReceiverSets: Array.from(createReceiverSetTable(chart).keys()),
      filteredPersonnelSets: Array.from(personnelTable(chart).keys())
    });
  }, [dataStoreData, customFilterData, team, targetedTeamId]);

  interface HitZoneCellProps {
    label: string,
    plays?: [PassHitZone, number],
    completions?: [PassHitZone, number],
  }
  function HitZoneCell(props: HitZoneCellProps) {
    return <GridCell color={`hsla(${team.primaryHue}, 100%, ${100 - (props.completions ? props.completions[1] * 50 : 100)}%, ${0.6})`} padding={3} height={24} className="bg-opacity-75 hover:bg-opacity-100">
      <div className="text-md text-center uppercase leading-6 font-bold text-white">{props.label}</div>
      <dd className="mt-1 flex justify-center items-baseline md:block lg:flex">
        {props.completions
          ? <div className="flex items-baseline text-3xl leading-8 font-semibold text-white">
            {`${(props.completions[1] * 100).toFixed(0)}%`}
          </div>
          : null}
        <div className="ml-2 text-sm leading-5 font-medium text-white">
          Tgt: {props.plays === undefined ? null : props.plays[1]}
        </div>
      </dd>
    </GridCell>;
  }

  if (!user) {
    return <LoggedOut />;
  }

  return (<ShellWithSideBar teamId={teamId}>
    {!sideMenuOpen &&
      <button onClick={() => setSideMenuOpen(true)} className="filter-button z-20 px-4 mx-2 md:mx-4 lg:mx-6 py-3 rounded-full bg-primary-600">
        <FontAwesomeIcon icon={['fas', 'cog']} className="text-xl" />
        <span className="filter-field-text ml-2">Filter Scouting Data</span>
      </button>
    }
    <button onClick={revert} className="revert-button z-20 px-4 mx-2 md:mx-4 lg:mx-6 py-3 rounded-full bg-red-600">
      <FontAwesomeIcon icon={['fas', 'sync-alt']} className="text-xl" />
      <span className="revert-text ml-2">Revert Filters</span>
    </button>
    {!sideMenuOpen &&
      <button onClick={() => setField3D(!field3D)} className="perspective-button z-20 px-4 mx-2 md:mx-4 lg:mx-6 py-3 rounded-full bg-secondary-600">
        <FontAwesomeIcon icon={['fas', 'cube']} className="text-xl" />
        <span className="perspective-text ml-2">{field3D ? '3D' : '2D'} Field</span>
      </button>
    }
    <div className="w-screen">
      <ul className="dark-bg shadow-inner grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 px-2 gap-2 md:px-6 md:gap-6 text-left mb-6">
        <li className="col-span-5 flex mt-5">
          <FootballField threeD={field3D}>
            <Block>
              <div className="grid gap-3 grid-cols-3 mt-8">
                <HitZoneCell label="DEEP 1/3" plays={chart.zonePlays.get(PassHitZone.DeepThirdLeft)} completions={chart.zoneCompletionRates.get(PassHitZone.DeepThirdLeft)} />
                <HitZoneCell label="DEEP 1/3" plays={chart.zonePlays.get(PassHitZone.DeepThirdMiddle)} completions={chart.zoneCompletionRates.get(PassHitZone.DeepThirdMiddle)} />
                <HitZoneCell label="DEEP 1/3" plays={chart.zonePlays.get(PassHitZone.DeepThirdRight)} completions={chart.zoneCompletionRates.get(PassHitZone.DeepThirdRight)} />
              </div>
            </Block>
            <Block paddingY={5}>
              <div className="grid gap-3 grid-cols-5">
                <HitZoneCell label="Curl/Flat" plays={chart.zonePlays.get(PassHitZone.CurlFlatLeft)} completions={chart.zoneCompletionRates.get(PassHitZone.CurlFlatLeft)} />
                <HitZoneCell label="Hook/Curl" plays={chart.zonePlays.get(PassHitZone.HookCurlLeft)} completions={chart.zoneCompletionRates.get(PassHitZone.HookCurlLeft)} />
                <HitZoneCell label="Mid/Hook" plays={chart.zonePlays.get(PassHitZone.MidHook)} completions={chart.zoneCompletionRates.get(PassHitZone.MidHook)} />
                <HitZoneCell label="Hook/Curl" plays={chart.zonePlays.get(PassHitZone.HookCurlRight)} completions={chart.zoneCompletionRates.get(PassHitZone.HookCurlRight)} />
                <HitZoneCell label="Curl/Flat" plays={chart.zonePlays.get(PassHitZone.CurlFlatRight)} completions={chart.zoneCompletionRates.get(PassHitZone.CurlFlatRight)} />
              </div>
            </Block>
            <Block>
              <div className="grid gap-3 grid-cols-4">
                <HitZoneCell label="Flat/Swing" plays={chart.zonePlays.get(PassHitZone.FlatSwingLeft)} completions={chart.zoneCompletionRates.get(PassHitZone.FlatSwingLeft)} />
                <li className="col-span-2 mt-12 mx-auto">
                  <ul className="grid grid-cols-7 justify-around mt-4">
                    <PlayerCircle />
                    <PlayerCircle />
                    <PlayerCircle />
                    <PlayerSquare />
                    <PlayerCircle />
                    <PlayerCircle />
                    <PlayerCircle />
                    <li className="col-span-3" />
                    <PlayerCircleRB />
                    <li className="col-span-3" />
                  </ul>
                </li>
                <HitZoneCell label="Flat/Swing" plays={chart.zonePlays.get(PassHitZone.FlatSwingRight)} completions={chart.zoneCompletionRates.get(PassHitZone.FlatSwingRight)} />
              </div>
            </Block>
          </FootballField>
        </li>
      </ul>
      <ul className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 px-6 gap-6 max-w-screen-xl mx-auto">
        <li>
          <GridCellSplit label="Total Pass Plays" value={`${chart.plays || '--'}`} label2="Comp. Rate" value2={`${(chart.completionRate * 100).toFixed(0)}%`} />
        </li>
        <li>
          <GridCellSplit label="Sacks" value={`${chart.sacks || '--'}`} label2="Scrambles" value2={`${chart.scrambles || '--'}`} />
        </li>
        <li>
          <GridCellSplit3
            values={['Field', 'Middle', 'Boundary']}
            selected={filteredFieldBoundary}
            onChange={setFilteredFieldBoundary}
            label="Field" value={fieldBoundaryTable.get('Field') || 0} label2="Middle"
            value2={fieldBoundaryTable.get('Middle') || 0} label3="Boundary"
            value3={fieldBoundaryTable.get('Boundary') || 0}
            total={Array.from(fieldBoundaryTable.values()).reduce((sum, n) => sum + n)} />
        </li>
        <li>
          <GridCellSplit3
            values={['Strong', 'Middle', 'Weak']}
            selected={filteredStrongWeak}
            onChange={setFilteredStrongWeak}
            label="Strong" value={strongWeakTable.get('Strong') || 0}
            label2="Middle" value2={strongWeakTable.get('Middle') || 0}
            label3="Weak" value3={strongWeakTable.get('Weak') || 0}
            total={Array.from(strongWeakTable.values()).reduce((sum, n) => sum + n)} />
        </li>
        <GridCell padding={0} height={56}>
          <Table
            initialSort={[1, 'desc']}
            data={playTable}
            titles={['Play', 'Att', 'Call %']}
            onChange={mapSet(setFilteredPlayNames)}
            selected={filteredPlayNames}
          />
        </GridCell>
        <GridCell height={56} padding={0}>
          <Table
            initialSort={[1, 'desc']}
            data={targetedReceiverTable}
            titles={['Receiver Tgt', 'Att', 'Call %']}
            onChange={mapSet(setFilteredReceiverTargets)}
            selected={filteredReceiverTargets}
          />
        </GridCell>
        <GridCell height={56} padding={0}>
          <Table
            initialSort={[1, 'desc']}
            data={createReceiverSetTable(chart)}
            titles={['Receiver Set', 'Att', 'Call %']}
            onChange={mapSet(setFilteredReceiverSets)}
            selected={filteredReceiverSets}
            priority={Array.from(customFilters.receiverSets.map((set) => set[1]))}
          />
        </GridCell>
        <GridCell height={56} padding={0}>
          <Table
            initialSort={[1, 'desc']}
            data={backfieldTable}
            titles={['Backfield', 'Att', 'Call %']}
            onChange={mapSet(setFilteredBackfields)}
            selected={filteredBackfields}
          />
        </GridCell>
        <GridCell height={56} padding={0}>
          <Table
            initialSort={[1, 'desc']}
            data={formationTable}
            titles={['Formation', 'Att', 'Call %']}
            onChange={mapSet(setFilteredFormations)}
            selected={filteredFormations}
          />
        </GridCell>
        <GridCell padding={0} height={56}>
          <Table data={possessionGameTable} initialSort={[1, 'desc']} selected={filteredPossessionGames.map(x => `${x}`)} onChange={mapSet(setFilteredPossessionGames)} titles={['Poss. Game', 'ATT', '%']} />
        </GridCell>
        <GridCell height={56} padding={0} primaryText="Customize" primaryIcon={['fas', 'wrench']} primaryClick={() => history.push('/custom-filters/formations')}>
          <Table
            initialSort={[1, 'desc']}
            data={personnelTable(chart)}
            titles={['Personnel', 'Att', 'Call %']}
            onChange={mapSet(setFilteredPersonnelSets)}
            selected={filteredPersonnelSets}
          />
        </GridCell>
      </ul>
      {sideMenuOpen &&
        <div className="filter-slideout bg-gray-900 border-l border-gray-800 z-20 text-left shadow-xl w-4/5 sm:w-1/2 md:w-1/4">
          <div className="overflow-y-auto px-6 max-h-screen pb-56 pt-12">
            <button onClick={() => setSideMenuOpen(false)} className="float-right m-2">
              <FontAwesomeIcon icon={['fas', 'times']} className="text-2xl" />
            </button>
            <button type="button" onClick={revert} className="items-center px-6 py-3 text-sm leading-5 font-sm rounded-md text-white bg-red-600 rounded-full hover:text-white hover:bg-red-700 focus:outline-none focus:shadow-outline-blue focus:border-secondary-600 active:bg-secondary-600 active:text-white transition ease-in-out duration-150">
              <FontAwesomeIcon icon={['fas', 'sync']} className="h-3 w-3" />
              <span className="ml-2">Revert Filters</span>
            </button>
            <MapSelect display="block" label="Opponent" choices={opponentChoices} selected={filteredTeamNames} onChange={mapSet(setFilteredTeamNames)} />
            <MapSelect display="inline" label="Down" choices={downChoices} selected={filteredDowns} onChange={mapSet(setFilteredDowns)} />
            <MapSelect display="inline" label="Hash" choices={hashChoices} selected={filteredHashes} onChange={mapSet(setFilteredHashes)} />
            <MapSelect display="inline" label="Distance (Yds)" choices={distanceChoices} selected={filteredDistances} onChange={mapSet(setFilteredDistances)} />
            <MapSelect display="block-open" label="Field Zone" choices={fieldZoneChoices} selected={filteredFieldZones} onChange={mapSet(setFilteredFieldZones)} />
            <MapSelect display="block-open" label="Previous Play" choices={previousGainChoices} selected={filteredPreviousGains} onChange={mapSet(setFilteredPreviousGains)} />
          </div>
        </div>
      }
    </div>
  </ShellWithSideBar >);
}
