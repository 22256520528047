import React, { useState } from 'react';
import { logoService } from "../config";
import { DropHandler } from "./drop-handler";
import { FirebaseToken } from "./firebase-token";

interface AvatarUploadProps {
  name: string;
  value?: string;
  onChange: (uri: string) => void;
}

export function AvatarUpload(props: AvatarUploadProps) {

  const [jwt, setJwt] = useState<string>('');

  function imagePicker() {
    const file = document.createElement('input');
    file.type = 'file';
    file.click();
    file.addEventListener('change', inputEvent => {
      if (inputEvent.target) {
        const input = inputEvent.target as HTMLInputElement;
        if (input.files) {
          receiveFiles(input.files);
        }
      }
    });
  }

  function receiveFiles(files: FileList) {
    for (const file of files) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        receiveImage(reader.result as string);
      }
    }
  }

  function receiveImage(dataURL: string) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      const image = new Image();
      image.onload = () => {
        canvas.height = 150;
        canvas.width = 150;
        if (image.width === image.height) {
          context.drawImage(image, 0, 0, 150, 150);
        } else if (image.width > image.height) {
          context.drawImage(image, (image.width - image.height) / 2, 0, image.height, image.height, 0, 0, 150, 150);
        } else {
          context.drawImage(image, 0, (image.height - image.width) / 2, image.width, image.width, 0, 0, 150, 150);
        }
        uploadImage(canvas.toDataURL('image/png', 1.0));
      }
      image.src = dataURL;
    }
  }

  function uploadImage(dataURL: string) {
    fetch(logoService, {
      headers: {
        'Authorization': `Bearer ${jwt}`
      },
      method: 'POST',
      body: dataURL.substr(22),
    })
      .then(res => res.json())
      .then(data => {
        props.onChange(data.objectUrl);
      })
  }

  return <div className="sm:col-span-6">
    <FirebaseToken onChange={setJwt} />
    <label htmlFor="cover_photo" className="block text-sm leading-5 font-medium text-gray-400">
      {props.name}
    </label>
    <p className="mt-1 text-sm text-gray-500">
      Upload square or circle images for best display.</p>
    <DropHandler border={false} receiveFiles={receiveFiles}>
      <div className="mt-2 flex items-center">
        <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-800">
          {(!props.value)
            ? <svg className="h-full w-full text-gray-700" fill="currentColor" viewBox="0 0 24 24">
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
            : <img src={props.value} alt="Upload Preview" />}
        </span>
        <span className="ml-5 rounded-md shadow-sm">
          <button type="button" onClick={imagePicker} className="py-2 px-3 border border-gray-700 rounded-md text-sm leading-4 font-medium text-gray-400 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
            Change
          </button>
        </span>
      </div>
    </DropHandler>
  </div>;
}