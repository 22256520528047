import React, { useEffect, useState } from 'react';
import { teamStorage, Team } from '../hooks/my-team-hook';
import { opponentsStorage } from '../hooks/my-opponents-hook';
import { accountStorage, Account } from '../hooks/my-account-hook';
import { dataStorage, Store } from './data-v2';
import firebase from "firebase/app";
import "firebase/auth";
import { ApiTeam } from "./team";
import { dataService } from '../config';

export function DataDownload() {
  const [fetching, setFetching] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [gotAccount, setGotAccount] = useState(false);
  const [gotTeams, setGotTeams] = useState(false);
  const [gotPlays, setGotPlays] = useState(false);
  const [jwt, setJwt] = useState<string>('');
  const [progress, setProgress] = useState(0);

  function getAccount(bearer: string) {
    fetch(dataService + '/account', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${bearer}`
      }
    })
      .then(res => res.json())
      .then(data => {
        if (typeof data === 'object' && 'errors' in data && data.errors.length > 0) {
          setErrors([...errors, ...data.errors]);
        } else if (typeof data !== 'object') {
          setErrors([...errors, 'An unexpected error occurred']);
        } else {
          const myTeam = new Team(
            data.id,
            data.team_name,
            data.mascot,
            data.logo_uri,
            data.city,
            data.state,
            data.colors.pri,
            data.colors.sdy,
            data.colors.acc,
            []
          );
          const myAccount = new Account(
            data.firstName || '',
            data.lastName || '',
            data.email || '',
            data.photoURI || '',
            data.location || '',
            data.position || '',
          )
          teamStorage.save(myTeam);
          accountStorage.save(myAccount);
          // TODO: save data.custom_filters
          setGotAccount(true);
        }
      })
      .catch(err => {
        if (err.toString() === 'TypeError: Failed to fetch') {
          setErrors([...errors, 'Unable to contact server. Are you connected to the internet?']);
        } else if (err.toString() === 'TypeError: NetworkError when attempting to fetch resource.') {
          setErrors([...errors, 'Unable to contact server. Are you connected to the internet?']);
        } else {
          setErrors([...errors, err.toString()]);
        }
        setGotPlays(true);
      });
  }

  function getTeams(bearer: string) {
    fetch(dataService + '/teams', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${bearer}`
      }
    })
      .then(res => res.json())
      .then(data => {
        if (typeof data === 'object' && 'errors' in data && data.errors.length > 0) {
          setErrors([...errors, ...data.errors]);
        } else if (typeof data !== 'object') {
          setErrors([...errors, 'An unexpected error occurred']);
        } else {
          const opponents = data as ApiTeam[];
          opponentsStorage.save(opponents.map(apiTeam => new Team(
            parseInt(apiTeam.id, 10),
            apiTeam.name,
            apiTeam.mascot,
            apiTeam.logo_uri,
            apiTeam.city,
            apiTeam.state,
            120,
            210,
            265,
            apiTeam.games.map(apiGame => ({ date: new Date(apiGame.date), status: apiGame.status })),
          )));
        }
      }).catch(err => setErrors([...errors, err.toString()]))
      .finally(() => setGotTeams(true));
  }

  function getPlays(bearer: string) {
    fetch(dataService + '/plays', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${bearer}`
      }
    })
      .then(res => res.json())
      .then(data => {
        if (typeof data === 'object' && 'errors' in data && data.errors.length > 0) {
          setErrors([...errors, ...data.errors]);
        } else if (typeof data !== 'object') {
          setErrors([...errors, 'An unexpected error occurred']);
        } else {
          localStorage.setItem('newestData', Date.now().toString());
          const store = Store.default();
          store.addApiResponse(data);
          dataStorage.save(store);
        }
      })
      .catch(err => setErrors([...errors, err.toString()]))
      .finally(() => setGotPlays(true));
  }

  useEffect(() => {
    if (gotAccount) {
      getTeams(jwt);
      getPlays(jwt);
    }

    // TODO: get plays called from getAccount if not up to date
  }, [gotAccount]);

  useEffect(() => {
    if (jwt) {
      setFetching(true);
      getAccount(jwt);
    }
  }, [jwt]);

  // First loads
  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user.getIdToken().then(setJwt);
      }
    });
  }, []);

  useEffect(() => {
    setProgress((gotAccount ? 33 : 0) + (gotTeams ? 33 : 0) + (gotPlays ? 34 : 0));
  }, [gotAccount, gotTeams, gotPlays]);

  useEffect(() => {
    if (progress >= 100) {
      setFetching(false);
    }
  }, [progress]);

  return <>
    {(!fetching) || errors.length > 0 ? null : <div className='fixed bottom-28 right-20 w-56 bg-gray-900 bg-opacity-95 border border-gray-600 shadow-lg rounded-lg flex flex-col items-center px-6 py-3 z-50'>
      <h2 className='text-white font-normal text-sm flex items-center' style={{ textAlign: 'center' }}>
        <div className="bg-white bg-opacity-0 loader font-bold text-center">IQ</div>
        <div className="ml-2">Loading Data...</div>
      </h2>
      <div className='border border-gray-800 rounded-md h-1/2 w-full mt-2'>
        <div className='bg-primary-500 p-2 rounded-md' style={{ height: '100%', width: `${progress}%` }} />
      </div>
    </div>}
    {errors.length < 1 ? null : <div className='fixed bottom-10 right-20 w-48 bg-red-100 border border-red-300 rounded-lg flex flex-col items-center py-2 px-6 w-72 text-sm z-50'>
      <h2 className='text-red-600 font-normal' style={{ textAlign: 'center' }}>
        {errors[0]}
      </h2>
    </div>}
  </>;
}