import {Storage2} from "./storage2";
import {dataService} from "../config";

export class Profile {
  constructor(
    public avatar: string,
    public firstName: string,
    public lastName: string,
    public emailAddress: string,
    public location: string,
    public position: string,
  ) {}

  static copy(inProfile: Profile) {
    return new Profile(
      inProfile.avatar,
      inProfile.firstName,
      inProfile.lastName,
      inProfile.emailAddress,
      inProfile.location,
      inProfile.position,
    );
  }

  static default() {
    return new Profile(
      'https://storage.googleapis.com/sportsiq/static/football-helmet-icon.png',
      '',
      '',
      '',
      '',
      '',
    );
  }
}

async function retrieve(jwt?: string, key?: string): Promise<Map<string, Profile>> {
  if (! jwt) {
    throw new Error('Must be authenticated to retrieve profile.');
  }
  const res = await fetch(`${dataService}/profile`, {
    headers: {
      authorization: `Bearer ${jwt}`,
    }
  });
  const data = await res.json();
  const map = new Map<string, Profile>();
  map.set('', new Profile(
    data.avatar,
    data.first_name,
    data.last_name,
    data.email_address,
    data.location,
    data.position,
  ));
  return map;
}

async function store(map: Map<string, Profile>, jwt?: string, key?: string) {
  if (! jwt) {
    throw new Error('Must be authenticated to save profile.');
  }
  const profile = map.get(key as string) as Profile;
  return fetch(`${dataService}/profile`, {
    method: 'POST',
    body: JSON.stringify({
      first_name: profile.firstName,
      last_name: profile.lastName,
      email: profile.emailAddress,
      avatar: profile.avatar,
      location: profile.location,
      position: profile.position,
    }),
    headers: {
      'Authorization': `Bearer ${jwt}`
    }
  });
}

function upgrade(profile: Profile, oldVersion: string) {
  return Profile.copy(profile);
}

export const profileStorage = new Storage2<Profile>(
  retrieve,
  store,
  upgrade,
  Profile.copy,
  'profile',
  '',
  Profile.default(),
  false,
  86400000,
  '1',
);
