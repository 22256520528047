import React, { useLayoutEffect, useState } from 'react';
import { Heading } from '../components/heading';
import { Shell } from '../components/shell';
import { DataCell, GridCell } from '../components/grid-cell';
import { IconGridCell } from '../components/grid-cell';
import { Block } from '../components/block';
import { Modal } from '../components/modal';
import { Team, teamStorage } from '../hooks/my-team-hook';
import { useHistory } from 'react-router-dom';
import { opponentsStorage } from '../hooks/my-opponents-hook';
import { dataStorage, ODK, PlayType, Store } from '../logic/data-v2';
import firebase from "firebase/app";
import "firebase/auth";
import { LoggedOut } from './logged-out';
import { dataService } from "../config";
import { Coaches } from "../components/coaches";

export function TeamPage() {
  const [jwt, setJwt] = useState<string>('');
  const [user, setUser] = useState<boolean>(false);
  const [team, setTeam] = useState<Team>(Team.default());
  const [opponents, setOpponents] = useState<Team[]>();
  const [data, setData] = useState<Store>();

  useLayoutEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      setUser(user !== null);
      if (user) {
        user.getIdToken().then(setJwt);
      }
      const s = [teamStorage.subscribe(setTeam), opponentsStorage.subscribe(setOpponents), dataStorage.subscribe(setData)];
      return () => s.forEach(s => s?.unsubscribe());
    });
  });

  interface RowProps {
    name: string;
    email: string;
    position: string;
    access: string;
    children: React.ReactNode;
  }

  function UserRow(props: RowProps) {
    return (<tr className="max-w-full">
      <td className="px-4 py-4 truncate">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            {props.children}
          </div>
          <div className="ml-4">
            <div className="text-sm leading-5 font-medium text-gray-100 truncate">
              {props.name}
            </div>
            <div className="text-sm leading-5 text-gray-500 truncate">
              {props.email}
            </div>
          </div>
        </div>
      </td>
      <td className="px-4 py-4 truncate">
        <div>
          <div className="text-sm leading-5 font-medium text-gray-100 truncate">
            {props.position}
          </div>
          <div className="text-sm leading-5 text-gray-500 truncate">
            <i className="fa fa-eye mr-2" />
            {props.access}
          </div>
        </div>
      </td>
      <td className="px-4 py-4 text-right text-sm leading-5 font-medium truncate">
        <a href="#" className="block"><i className="fas fa-pencil-alt text-primary-400 hover:text-primary-600" /></a>
        <a href="#" className="block mt-2"><i className="fas fa-trash text-primary-400 hover:text-primary-600" /></a>
      </td>
    </tr >);
  }

  const [openModal, setOpenModal] = useState<string>('');
  const history = useHistory();

  let wins = 0, losses = 0, ties = 0;
  if (opponents) {
    for (const opponent of opponents) {
      for (const game of opponent.games) {
        if (game.status === 'W') ++wins;
        if (game.status === 'L') ++losses;
        if (game.status === 'T') ++ties;
      }
    }
  }

  const yardStats = { run: 0, runCount: 0, pass: 0, passCount: 0 };
  if (data) {
    data.games.forEach(game => {
      if (game.teamId === team.name || game.opponentName === team.name) {
        game.plays.forEach(play => {
          if ((play.odk === ODK.Offense) == (game.teamId === team.name)) { // if this play has the team on offense
            if (play.type === PlayType.Pass) {
              yardStats.passCount++;
              yardStats.pass += play.gain;
            } else if (play.type === PlayType.Run) {
              yardStats.runCount++;
              yardStats.run += play.gain;
            }
          }
        });
      }
    });
  }

  if (!user) {
    return <LoggedOut />;
  }

  return (<Shell>
    <Heading>My Team Profile</Heading>
    <div className="max-w-6xl m-auto p-4">
      <Block>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
          <div className="col-span-2 flex flex-col">
            <GridCell
              primaryIcon={['fas', 'clipboard']}
              primaryText="Self Scout"
              primaryClick={() => history.push(`/pass-zone-chart?team=${team.name}`)}
              secondaryIcon={['fas', 'pencil-alt']}
              secondaryText="Team Info"
              secondaryClick={() => history.push('/team-info')}
            >
              <div className="w-32 h-32 bg-gray-800 flex-shrink-0 mx-auto rounded-full p-6">
                <img className="h-20 inline-block align-middle mx-auto bg-gray-800 rounded-full" src={team.logo} alt={`${team.name} Logo`} />
              </div>
              <div className="flex justify-center mt-3">
                <div className="rounded-full h-4 w-4 flex items-center justify-center" style={{ backgroundColor: `hsl(${team.primaryHue}, 100%, 50%)` }} />&nbsp;
                <div className="rounded-full h-4 w-4 flex items-center justify-center" style={{ backgroundColor: `hsl(${team.secondaryHue}, 100%, 50%)` }} />&nbsp;
                <div className="rounded-full h-4 w-4 flex items-center justify-center" style={{ backgroundColor: `hsl(${team.accentHue}, 100%, 50%)` }} />
              </div>
              <h3 className="mt-6 text-gray-100 text-lg leading-5 font-bold">{team.name || 'Team Name'}</h3>
              <dl className="mt-1 flex-grow flex flex-col justify-between">
                <dt className="sr-only">{team.name || 'Team Name'}</dt>
                <dd className="text-gray-500 text-sm leading-5">{team.city || 'Anywhere'}, {team.state || 'USA'}</dd>
                <dt className="sr-only">{team.city || 'Anywhere'}, {team.state || 'USA'}</dt>
              </dl>
            </GridCell>
          </div>
          <div className="col-span-2 text-left">
            <div className="grid grid-cols-2 gap-6">
              <DataCell label="Team Record" value={wins + '-' + losses + (ties > 0 ? '-' + ties : '')} />
              <DataCell label="Total Yards" value={`${yardStats.pass + yardStats.run}`} />
              <IconGridCell icon={['fas', 'football-ball']} label="Total Pass Yards" total={`${yardStats.pass}`} average={`${yardStats.passCount === 0 ? '- -' : (yardStats.pass / yardStats.passCount).toFixed(1)}`} chartLink="Pass Zone Chart" chartUrl={`/pass-zone-chart?team=${encodeURI(team.name)}`} />
              <IconGridCell icon={['fas', 'running']} label="Total Rush Yards" total={`${yardStats.run}`} average={`${yardStats.runCount === 0 ? '- -' : (yardStats.run / yardStats.runCount).toFixed(1)}`} chartLink="Run Zone Chart" chartUrl={`/run-zone-chart?team=${encodeURI(team.name)}`} />
            </div>
          </div>
        </div>
      </Block>
      <Block>
        <div className="border-t w-full border-gray-800 my-12" />
      </Block>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div>
          <Coaches />
        </div>
        <div>
          <Block>
            <div className="flex flex-col mt-6 text-left      cursor-not-allowed opacity-25" onClick={() => alert('Not finished yet!')}>
              <div className="pb-5 space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
                <h3 className="text-lg leading-6 font-medium text-gray-100">
                  Player Users
          </h3>
                <div>
                  <span className="shadow-sm rounded-md">
                    <button onClick={() => { return; setOpenModal('player') }} type="button" className="cursor-not-allowed inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-600 focus:outline-none focus:shadow-outline-blue focus:border-primary-700 active:bg-primary-700 transition duration-150 ease-in-out">
                      <i className="fa fa-plus mr-2" />
                  Player
                </button>
                  </span>
                </div>
              </div>
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border border-gray-800 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-900 table-fixed w-100">
                      <thead className="border-b border-gray-800">
                        <tr>
                          <th className="py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-50">
                            <div className="px-6">Name</div>
                          </th>
                          <th className="py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-35">
                            <div className="px-6">Pos./Access Level</div>
                          </th>
                          <th className="py-3 bg-gray-50 w-15">
                            <div className="px-6" />
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-800">
                        <UserRow name="Kevin Smith" email="ksmith@gmailwithsuperlongcrazyname.com" position="WR" access="View Only">
                          <img className="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=256&amp;h=256&amp;q=60" alt="" />
                        </UserRow>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

          </Block>
        </div>

      </div>

      {openModal === 'coach' ? <CoachForm jwt={jwt} close={() => setOpenModal('')} /> : null}
      {openModal === 'player' ? <PlayerForm close={() => setOpenModal('')} /> : null}
    </div >
  </Shell >);
}

interface CloseProvider {
  close: () => void;
}

interface CoachForm {
  close: () => void;
  jwt: string;
}

function CoachForm(props: CoachForm) {

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [position, setPosition] = useState<string>('');
  const [accessLevel, setAccessLevel] = useState<string>('');

  function save() {
    const authId = '';
    fetch(`${dataService}/coaches`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${props.jwt}`,
      },
      body: JSON.stringify({
        auth_id: authId,
        first_name: firstName,
        last_name: lastName,
        email_address: emailAddress,
        location,
        position,
        access_level: accessLevel,
      }),
    })
      .then(() => {
        // reload page!
      });
  }

  return (<Modal
    title="Add Coach User"
    instruction="Add your other coach users. They will receive an email to reset their password and access their account."
    icon={['fas', 'user']}
    primaryButton="Save Changes"
    secondaryButton="Cancel"
    onPrimaryClick={props.close}
    onSecondaryClick={props.close}>
    <form className="w-full">
      <div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <label htmlFor="photo" className="block text-sm leading-5 font-medium text-gray-400">
              Photo
          </label>
            <div className="mt-2 flex items-center">
              <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
              </span>
              <span className="ml-5 rounded-md shadow-sm">
                <button type="button" className="py-2 px-3 border border-gray-700 rounded-md text-sm leading-4 font-medium text-gray-400 hover:text-gray-500 focus:outline-none focus:border-primary-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                  Change
              </button>
              </span>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="teamname" className="block text-sm font-medium leading-5 text-gray-400">
              First Name
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <input type="text" id="teamname" className="flex-1 form-input rounded-md block w-full min-w-0 transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700" value={firstName} onChange={e => setFirstName(e.currentTarget.value)} />
            </div>
          </div>
          <div className="sm:col-span-6">
            <label htmlFor="teamname" className="block text-sm font-medium leading-5 text-gray-400">
              Last Name
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <input type="text" id="teamname" className="flex-1 form-input rounded-md block w-full min-w-0 transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700" value={lastName} onChange={e => setLastName(e.currentTarget.value)} />
            </div>
          </div>
          <div className="sm:col-span-6">
            <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-400">
              Email
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <input type="text" id="email" className="flex-1 rounded-md form-input block w-full min-w-0 transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700" value={emailAddress} onChange={e => setEmailAddress(e.currentTarget.value)} />
            </div>
          </div>
          <div className="sm:col-span-6">
            <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-400">
              Location
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <input type="text" id="email" className="flex-1 rounded-md form-input block w-full min-w-0 transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700" value={location} onChange={e => setLocation(e.currentTarget.value)} />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="position" className="block text-sm font-medium leading-5 text-gray-400 bg-gray-900 border-gray-700">
              Position
          </label>
            <div className="mt-1 rounded-md shadow-sm">
              <select id="position" className="form-select rounded-md block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700 cursor-pointer" value={position} onChange={e => setPosition(e.currentTarget.value)} >
                <option value=''>Head Coach</option>
                <option value=''>Assistant Coach</option>
                <option value=''>Offensive Coordinator</option>
                <option value=''>Defensive Coordinator</option>
                <option value=''>Position Coach</option>
                <option value=''>Special Teams Coach</option>
                <option value=''>Other</option>
              </select>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="access" className="block text-sm font-medium leading-5 text-gray-400 bg-gray-900 border-gray-700">
              Access Level
          </label>
            <div className="mt-1 rounded-md shadow-sm">
              <select id="access" className="form-select rounded-md block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700 cursor-pointer" value={accessLevel} onChange={e => setAccessLevel(e.currentTarget.value)} >
                <option value=''>Administrator</option>
                <option value=''>View Only</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </form>
  </Modal>);
}

function PlayerForm(props: CloseProvider) {
  return (<Modal
    title="Add Player User"
    icon={['fas', 'user']}
    instruction=""
    primaryButton="Save Changes"
    secondaryButton="Cancel"
    onPrimaryClick={props.close}
    onSecondaryClick={props.close}>
    <form className="w-full">
      <div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <label htmlFor="photo" className="block text-sm leading-5 font-medium text-gray-400">
              Photo
          </label>
            <div className="mt-2 flex items-center">
              <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
              </span>
              <span className="ml-5 rounded-md shadow-sm">
                <button type="button" className="py-2 px-3 border border-gray-700 rounded-md text-sm leading-4 font-medium text-gray-400 hover:text-gray-500 focus:outline-none focus:border-primary-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                  Change
              </button>
              </span>
            </div>
          </div>
          <div className="sm:col-span-6">
            <label htmlFor="teamname" className="block text-sm font-medium leading-5 text-gray-400">
              Name
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <input type="text" id="teamname" className="flex-1 form-input rounded-md block w-full min-w-0 transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700" />
            </div>
          </div>
          <div className="sm:col-span-6">
            <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-400">
              Email
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <input type="text" id="email" className="flex-1 rounded-md form-input block w-full min-w-0 transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700" />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="position" className="block text-sm font-medium leading-5 text-gray-400 bg-gray-900 border-gray-700">
              Position
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <select id="position" value="" className="form-select rounded-md block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700 cursor-pointer">
                <option value=''>Select Player Position...</option>
                <option value=''>QB</option>
                <option value=''>RB</option>
                <option value=''>WR</option>
                <option value=''>TE</option>
                <option value=''>OL</option>
                <option value=''>K</option>
                <option value=''>P</option>
                <option value=''>DL</option>
                <option value=''>LB</option>
                <option value=''>DB</option>
              </select>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="access" className="block text-sm font-medium leading-5 text-gray-700 bg-gray-900 border-gray-700">
              Access Level
          </label>
            <div className="mt-1 rounded-md shadow-sm">
              <select id="access" value="" className="form-select text-gray-700 rounded-md block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-800" disabled>
                <option value=''>View Only</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </form>
  </Modal>);
}


