import React, { useState } from 'react';

interface Props {
  onChange: (_: string) => void,
}

export function SearchBar(props: Props) {
  const [value, setValue] = useState<string>('');

  function change(theValue: string) {
    setValue(theValue);
    props.onChange(theValue);
  }

  return (<div className="relative flex-grow focus-within:z-10 shadow-sm">
    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
      <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
      </svg>
    </div>
    <input id="email" type="search" className="form-input block w-full rounded-md pl-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5 bg-gray-900 focus:ring-blue-500 focus:ring-opacity-50 border-gray-700" placeholder="Search Games" value={value} onChange={e => change(e.target.value)} />
  </div>);
}
