import React, { useEffect, useLayoutEffect, useState } from 'react';
import { FirebaseToken } from "../components/firebase-token";
import { LoggedOut } from "./logged-out";
import { Shell } from "../components/shell";
import { useHistory, useParams, Link } from "react-router-dom";
import { Block } from "../components/block";
import { ApiGame, ApiTeam, Game, Team } from "../logic/team";
import { Labeled, LabeledInput, LabeledSelect } from "../components/labeled-input";
import { AvatarUpload } from "../components/avatar-upload";
import { DatePicker } from "../components/date-picker";
import { TimePicker } from "../components/time-picker";
import { dataService } from "../config";
import { ColorPicker } from "../components/color-picker";
import { opponentsStorage } from "../hooks/my-opponents-hook";
import { teamStorage } from "../hooks/my-team-hook";
import { Account, accountStorage } from "../hooks/my-account-hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropHandler } from "../components/drop-handler";
import XLSX from "xlsx";
import { dataStorage, Play, Store } from "../logic/data-v2";
import { statesStorage } from '../hooks/states';
import { Modal } from '../components/modal';

interface RouteParams {
  id?: string;
}

export function OpponentPage() {
  const [jwt, setJwt] = useState('');
  const [name, setName] = useState('');
  const [mascot, setMascot] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [logoURI, setLogoURI] = useState('');
  const [showGames, setShowGames] = useState(true);
  const [games, setGames] = useState<Game[]>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [stateOptions, setStateOptions] = useState<String[]>([]);
  // colors
  const [showColors, setShowColors] = useState(false);
  const [pri, setPri] = useState(120);
  const [sdy, setSdy] = useState(210);
  const [acc, setAcc] = useState(265);
  const [playData, setPlayData] = useState<string[]>([]);
  const { id } = useParams<RouteParams>();
  const history = useHistory();

  useEffect(() => {
    if (jwt && id !== 'new') {
      if (id === undefined) {
        setShowColors(true);
        setShowGames(false);
        fetch(`${dataService}/account`, {
          headers: {
            authorization: `Bearer ${jwt}`,
          }
        })
          .then(res => res.json())
          .then(data => {
            setName(data.team_name);
            setMascot(data.mascot);
            setCity(data.city);
            setState(data.state);
            setPri(data.colors.pri);
            setSdy(data.colors.sdy);
            setAcc(data.colors.acc);
            setLogoURI(data.logo_uri);
          });
      } else {
        fetch(`${dataService}/teams?id=${id}`, {
          headers: {
            authorization: `Bearer ${jwt}`,
          }
        })
          .then(res => res.json())
          .then(data => {
            if (data.length === 1) {
              setName(data[0].name);
              setMascot(data[0].mascot);
              setGames(data[0].games.map((game: ApiGame) => ({ date: new Date(game.date), status: game.status })));
              setCity(data[0].city);
              setState(data[0].state);
              setLogoURI(data[0].logo_uri);
            }
          })
      }
    }
  }, [jwt]);

  useLayoutEffect(() => {
    const l = [statesStorage.subscribe(setStateOptions)];
    return () => l.forEach(l => l?.unsubscribe());
  }, []);

  if (!jwt) {
    return <>
      <FirebaseToken onChange={setJwt} />
      <LoggedOut />
    </>;
  }

  function save() {
    if (name.length === 0) {
      alert("Opponent name can't be blank.");
      return;
    }
    if (id === undefined) {
      fetch(dataService + '/account', {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
        method: 'POST',
        body: JSON.stringify({
          name,
          mascot,
          city,
          state,
          logo: logoURI,
          colors: { pri, sdy, acc },
        })
      })
        .then(res => res.json())
        .then(data => {
          teamStorage.save(new Team(
            data.id,
            data.team_name,
            data.mascot,
            data.logo_uri,
            data.city,
            data.state,
            data.colors.pri,
            data.colors.sdy,
            data.colors.acc,
            []
          ));
          accountStorage.save(new Account(
            data.firstName || '',
            data.lastName || '',
            data.email || '',
            data.photoURI || '',
            data.location || '',
            data.position || '',
          ));
          fetch(dataService + '/plays', {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
            method: 'POST',
            body: JSON.stringify({
              team_id: 0,
              play_data: playData.map(line => Play.fromLine(line)),
            }),
          })
            .then(() => {
              fetch(dataService + '/plays', {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${jwt}`
                }
              })
                .then(res => res.json())
                .then(data => {
                  localStorage.setItem('newestData', Date.now().toString());
                  const store = Store.default();
                  store.addApiResponse(data);
                  dataStorage.save(store);
                });
            })
          history.push('/team');
        });
    } else {
      fetch(dataService + '/teams', {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
        method: 'POST',
        body: JSON.stringify({
          id: id !== 'new' ? id : '',
          name,
          mascot,
          games,
          city,
          state,
          logo: logoURI,
        })
      })
        .then(res => res.json())
        .then((saveData: ApiTeam) => {
          const teamId = saveData.id;
          // save play data
          fetch(dataService + '/plays', {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
            method: 'POST',
            body: JSON.stringify({
              team_id: teamId,
              play_data: playData.map(line => Play.fromLine(line)),
            }),
          })
            .then(() => {
              fetch(dataService + '/plays', {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${jwt}`
                }
              })
                .then(res => res.json())
                .then(data => {
                  localStorage.setItem('newestData', Date.now().toString());
                  const store = Store.default();
                  store.addApiResponse(data);
                  dataStorage.save(store);
                });
            })
          // refresh teams
          fetch(dataService + '/teams', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${jwt}`
            }
          })
            .then(res => res.json())
            .then((data: ApiTeam[]) => {
              opponentsStorage.save(data.map(datum => new Team(
                parseInt(datum.id, 10),
                datum.name,
                datum.mascot,
                datum.logo_uri,
                datum.city,
                datum.state,
                120,
                210,
                265,
                datum.games.map(apiGame => ({ date: new Date(apiGame.date), status: apiGame.status })),
              )));
              history.push('/schedule');
            });
        });
    }
  }

  function deleteTeam() {
    if (id === 'new') {
      history.push('/schedule');
    }
    fetch(dataService + '/teams', {
      headers: {
        authorization: `Bearer ${jwt}`,
      },
      method: 'DELETE',
      body: JSON.stringify({
        id: id !== 'new' ? id : '',
      })
    })
      .then(res => res.json())
      .then((saveData: ApiTeam) => {
        // refresh teams
        fetch(dataService + '/teams', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${jwt}`
          }
        })
          .then(res => res.json())
          .then((data: ApiTeam[]) => {
            opponentsStorage.save(data.map(datum => new Team(
              parseInt(datum.id, 10),
              datum.name,
              datum.mascot,
              datum.logo_uri,
              datum.city,
              datum.state,
              120,
              210,
              265,
              datum.games.map(apiGame => ({ date: new Date(apiGame.date), status: apiGame.status })),
            )));
            history.push('/schedule');
          });
      });
  }

  function filePicker() {
    const file = document.createElement('input');
    file.type = 'file';
    file.click();
    file.addEventListener('change', inputEvent => {
      if (inputEvent.target) {
        const input = inputEvent.target as HTMLInputElement;
        if (input.files) {
          receiveFiles(input.files);
        }
      }
    });
  }

  function receiveFiles(files: FileList) {
    for (const file of files) {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () => {
        const workbook = XLSX.read(reader.result, { type: 'binary' });
        const csvFiles = workbook.SheetNames.map(sheetName => XLSX.utils.sheet_to_csv(workbook.Sheets[sheetName], { FS: '|' }));
        setPlayData(csvFiles.reduce<string[]>((lines, file) => lines.concat(file.split("\n")), []));
      }
    }
  }

  function updateDate(date: Date, i: number) {
    const g = Array.from(games);
    g[i].date = new Date(date);
    setGames(g);
  }

  return <Shell>
    <Block>
      <div className="space-y-8 divide-y divide-gray-200 text-left">
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="m-auto w-full max-w-3xl">
            <div>
              <h4 className="text-xl pt-4">{id === 'new' ? 'Create Opponent' : `Edit ${id === undefined ? 'My Team' : 'Opponent'}`}</h4>

              <p className="mt-1 text-sm text-gray-500">
                Add your opponents information that will be displayed on the schedule.
              </p>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <LabeledInput label={id === undefined ? 'Team Name' : 'Opponent Name'} value={name} onChange={setName} />
              </div>
              <div className="sm:col-span-4">
                <LabeledInput label="Mascot" value={mascot} onChange={setMascot} />
              </div>
              <div className="sm:col-span-6">
                <AvatarUpload name="Logo" value={logoURI} onChange={setLogoURI} />
              </div>
              <div className="sm:col-span-3">
                <LabeledInput label="City" value={city} onChange={setCity} />
              </div>
              <div className="sm:col-span-3">
                <LabeledSelect label="State" value={state} options={new Map(Array.from(stateOptions.map((s) => [s, s] as [string, string])))} onChange={setState} />
              </div>
            </div>
            <div className="grid grid-cols-6 w-full mt-6">
              <Labeled label={id === undefined ? 'Self-Scouting Data' : 'Opponent Scouting Data'}>
                <DropHandler border={true} receiveFiles={receiveFiles}>
                  <div className="text-center w-full p-2">
                    <FontAwesomeIcon className="text-5xl cursor-pointer text-gray-600 mt-4 mb-2" icon={['fas', 'file-upload']} />
                    <p className="mt-1 text-sm text-gray-600">
                      <button type="button" onClick={filePicker} className="font-medium text-primary-600 hover:text-primary-500 focus:outline-none focus:underline transition duration-150 ease-in-out mr-1">
                        Upload a file
                      </button>
                      or drag and drop
                    </p>
                    <p className="mt-1 text-xs text-gray-500">
                      XLSX or CSV up to 10MB
                    </p>
                    <span className="mt-2 text-primary-500 text-sm">
                      {playData.length > 0 ? <span><FontAwesomeIcon className="mr-1" icon={['fas', 'check-circle']}></FontAwesomeIcon>File uploaded successfully</span> : null}
                    </span>
                  </div>
                </DropHandler>
              </Labeled>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              {(!showGames)
                ? null
                : <div className="sm:col-span-6 grid-cols-6">
                  <h4 className="text-lg leading-6 font-medium text-gray-100 mb-2">
                    Game Times
                  </h4>
                  <p className="mt-1 text-sm text-gray-500">
                    Add the game times for your opponent. You can add multiple games and your schedule will popluate accordingly.
                  </p>
                  <Labeled label="">
                    <div className="grid grid-cols-6 w-full mt-6">
                      <>
                        {games.map((game, i) => [
                          <div className="sm:col-span-3 mr-2">
                            <label htmlFor="state" className="block text-sm font-medium leading-5 text-gray-400">
                              Date
                            </label>
                            <div className="mt-1 rounded-md shadow-sm">
                              <DatePicker date={game.date} onChange={date => updateDate(date, i)} />
                            </div>
                          </div>,
                          <div className="sm:col-span-2 mr-2">
                            <label htmlFor="state" className="block text-sm font-medium leading-5 text-gray-400">
                              Time
                            </label>
                            <div className="mt-1 rounded-md shadow-sm">
                              <TimePicker date={game.date} onChange={date => updateDate(date, i)} />
                            </div>
                          </div>,
                          <div className='sm:col-span-1'>
                            <div className="mt-7">
                              <button onClick={() => setGames(games.filter((g, j) => j !== i))} type="button" className="inline-flex items-center px-2 py-0 text-sm leading-5 font-medium rounded-full text-gray-400 hover:text-red-600 hover:bg-red-100 focus:outline-none focus:ring-blue focus:ring-opacity-50 focus:border-red-300 active:bg-red-100 active:text-red-400 transition ease-in-out duration-150">
                                <i className="fas fa-times-circle h-7 w-7 " />
                              </button>
                            </div>
                          </div>
                          ,
                        ])}
                        <div className="col-span-6 mt-2">
                          <button type="button" className="text-primary-500 font-semibold" onClick={() => setGames(games.concat({ date: new Date(), status: '?' }))}>+ Add Game</button>
                        </div>
                      </>
                    </div>
                  </Labeled>
                </div>}
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

              {(!showColors)
                ? null
                : <div className="sm:col-span-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-100">
                    Team Colors
                  </h3>
                  <div className="mt-2 grid grid-cols-3 gap-y-2 gap-x-1">
                    <div className="mt-1 text-sm leading-5 text-gray-500">
                      Primary Color
                      <ColorPicker onChange={setPri} value={pri} />
                    </div>
                    <div className="mt-1 text-sm leading-5 text-gray-500">
                      Secondary Color
                      <ColorPicker onChange={setSdy} value={sdy} />
                    </div>
                    <div className="mt-1 text-sm leading-5 text-gray-500">
                      Accent Color
                      <ColorPicker onChange={setAcc} value={acc} />
                    </div>
                  </div>
                </div>}
            </div>
            <div className="pt-5 pb-48 border-t border-gray-800 mt-12">
              <div className="grid grid-cols-6">
                <div className="col-span-6 sm:col-span-3">
                  {(!showGames)
                    ? null
                    :
                    <button type="button" onClick={() => setDeleteModal(true)} className="w-full mb-2 wm:mb-auto sm:w-auto inline-flex justify-center items-center rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                      <FontAwesomeIcon icon={['fas', 'times-circle']} />
                      <span className="ml-1">Delete this Opponent</span>
                    </button>
                  }
                </div>
                <div className="col-span-6 sm:col-span-3 text-right">
                  <Link to="/schedule">
                    <button type="button" className="inline-flex justify-center rounded-md border border-gray-700 px-4 py-2 bg-gray-800 text-base leading-6 font-medium text-gray-400 shadow-sm hover:text-gray-500 focus:outline-none focus:border-primary-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                      Cancel
                    </button>
                  </Link>
                  <button type="button" onClick={save} className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Block>
    {deleteModal &&
      <Modal title="Are you sure?" instruction="" icon={['fas', 'trash-alt']} primaryButton="Delete" onPrimaryClick={deleteTeam} secondaryButton="Cancel" onSecondaryClick={() => setDeleteModal(false)}>
        <p>This will permanently delete this team!</p>
      </Modal>
    }
  </Shell >;
}