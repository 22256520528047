import React from 'react';

interface Props {
  threeD: boolean,
  children: React.ReactNode;
}

export function FootballField(props: Props) {
  return (<div className={`football-field ${props.threeD && 'field-2d'} field w-full`}>
    <div className="bg-scroll">
      {props.children}
    </div>
  </div>);
}
