import React, { useLayoutEffect } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


interface Props {
  children: React.ReactNode;
  title: string;
  instruction: string;
  icon: IconProp;
  primaryButton?: string;
  secondaryButton?: string;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
}

export function Modal(props: Props) {
  const { children, title, instruction, primaryButton, secondaryButton, onPrimaryClick, onSecondaryClick } = props;
  return (
    <div className="fixed z-40 inset-0 overflow-y-auto">
      <div className="flex items-center sm:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-800 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
      <div className="inline-block align-middle bg-gray-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <button className="float-right text-gray-400 hover:text-white" onClick={() => { if (onSecondaryClick) onSecondaryClick() }}>
            <FontAwesomeIcon icon={['fas', 'times']} />
          </button>
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
              <div className="h-6 w-6 text-primary-600 text-center">
                <FontAwesomeIcon icon={props.icon} />
              </div>
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
              <h3 className="text-lg leading-6 font-medium text-gray-100" id="modal-headline">
                {title}
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                {instruction}
              </p>
              {children}
            </div>
          </div>
          <div className="pt-4">
            <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex justify-end border-t border-gray-900 pt-6">
              {secondaryButton
                ? (<span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                  <button onClick={() => { if (onSecondaryClick) onSecondaryClick() }} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-700 px-4 py-2 bg-gray-800 text-base leading-6 font-medium text-gray-400 shadow-sm hover:text-gray-500 focus:outline-none focus:border-primary-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    {secondaryButton}
                  </button>
                </span>)
                : null}
              {primaryButton
                ? (<span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                  <button onClick={() => { if (onPrimaryClick) onPrimaryClick() }} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-primary-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:border-primary-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    {props.primaryButton}
                  </button>
                </span>)
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>);
}

interface SlimModalProps {
  title: string;
  instructions?: string;
  children?: React.ReactNode;
  close: () => void;
  save: () => void;
}
export function SlimModal(props: SlimModalProps) {
  useLayoutEffect(() => {
    const handler = (e: MouseEvent) => {
      const target = e.target as Element;
      if (!target.closest('[role=dialog]')) {
        props.close();
      }
    };

    document.body.addEventListener('click', handler);
    return function cleanup() {
      document.body.removeEventListener('click', handler);
    }
  });
  return (<div className="fixed z-10 inset-0 overflow-y-auto">
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-800 opacity-75"></div>
      </div>
      <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
      <div className="inline-block align-bottom bg-gray-900 rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-56 sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-primary-100 sm:h-10 sm:w-10">
          <div className="h-6 w-6 text-primary-600 text-center">
            <FontAwesomeIcon icon={['fas', 'edit']} />
          </div>
        </div>
        <div className="mt-6 mb-6">
          <h3 className="text-lg text-center leading-6 font-medium text-gray-100" id="modal-headline">
            {props.title}
          </h3>
          {props.instructions ? <div className="text-sm text-left mb-2">
            {props.instructions}
          </div> : null}
        </div>
        {props.children ? props.children : null}
        <button className="block bg-primary-600 rounded-lg w-full px-4 py-2" onClick={props.save}>
          Save
        </button>
        <button className="mt-1 block bg-gray-600 rounded-lg w-full px-4 py-2" onClick={props.close}>
          Cancel
        </button>
      </div>
    </div>
  </div>);
}