import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './tw.css';  // Import Tailwind
import './App.css'; // Import App styles
import '@fortawesome/fontawesome-free/js/all.js';
import { PassZoneChartPage } from './pages/pass-zone-page';
import { FieldZoneChartPage } from './pages/field-zone-page';
import { SchedulePage } from './pages/schedule';
import { NotFound } from './pages/not-found';
import { Landing } from './pages/landing';
import { LoggedOut } from './pages/logged-out';
import { Splash } from './pages/splash';
import { CustomFiltersPage } from './pages/custom-filters';
import { RunZoneChartPage } from './pages/run-zone-chart';
import { OpponentPage } from './pages/opponent-page';
import { UserSettingsPage } from './pages/user-settings';
import { TeamPage } from './pages/team-page';
import { InjectStyles } from './components/inject-styles';
import { DataDownload } from './logic/data-downloader';
import { MobileMessage } from './components/mobile-message';
import firebase from "firebase/app";
import "firebase/auth";
import { RegistrationPage } from './pages/registration-page';
import { RequestSubmitted } from './pages/request-submitted';
import { ResetPasswordPage } from './pages/reset-password-page';

const firebaseConfig = {
  apiKey: "AIzaSyDY0B3Oc_R30alpOSzT4tqLUaBfKSV1gK0",
  authDomain: "sports-iq-291314.firebaseapp.com",
  projectId: "sports-iq-291314",
  storageBucket: "sports-iq-291314.appspot.com",
  messagingSenderId: "308566711358",
  appId: "1:308566711358:web:528df0770e63b78657856f"
};

firebase.initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig, "tenant");

function App() {
  return (
    <div className="App">
      <InjectStyles />
      <DataDownload />
      <Router>
        <Switch>
          <Route path="/pass-zone-chart">
            <PassZoneChartPage />
          </Route>
          <Route path="/run-zone-chart">
            <RunZoneChartPage />
          </Route>
          <Route path="/field-zone-chart">
            <FieldZoneChartPage />
          </Route>
          <Route path="/opponent/:id">
            <OpponentPage />
          </Route>
          <Route path="/team-info">
            <OpponentPage />
          </Route>
          <Route path="/schedule">
            <SchedulePage />
            <MobileMessage />
          </Route>
          <Route path="/custom-filters/:filterName">
            <CustomFiltersPage />
          </Route>
          <Route path="/team">
            <TeamPage />
          </Route>
          <Route path="/profile">
            <UserSettingsPage />
          </Route>
          <Route path="/oauth-callback">
            {/* TODO: handle the response from oauth */}
            <Splash />
          </Route>
          <Route path="/logout">
            <LoggedOut />
          </Route>
          <Route path="/registration">
            <RegistrationPage />
          </Route>
          <Route path="/request-submitted">
            <RequestSubmitted />
          </Route>
          <Route path="/reset-password">
            <ResetPasswordPage />
          </Route>
          <Route path="/" exact={true}>
            <Landing />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
