import React, {useLayoutEffect, useState} from 'react';
import {defaultTeam, Team, Team as Opponent, teamStorage} from "../hooks/my-team-hook";
import {opponentsStorage} from "../hooks/my-opponents-hook";

interface TeamNameProps {
  teamId?: string;
}
export function TeamName(props: TeamNameProps) {
  const [team, setTeam] = useState<Team>((defaultTeam()));
  const [opponents, setOpponents] = useState<Opponent[]>([]);

  useLayoutEffect(() => {
    const s = [
      opponentsStorage.subscribe(setOpponents),
      teamStorage.subscribe(setTeam),
    ];
    return () => s.forEach(s => s?.unsubscribe());
  }, []);

  if ((!props.teamId) || props.teamId === '0') {
    return <>{team.name}</>;
  }
  const found = opponents.find(opp => `${opp.id}` === props.teamId);
  if (found) {
    return <>{found.name}</>;
  }

  return <>Unknown</>;
}