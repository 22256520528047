import { Game } from './data-v2';
import { Team } from './team'
import { CustomFilters } from './custom-filters';
import {dataService} from "../config";

type RGB = { r: number, g: number, b: number };

export type DBAccount = {
  teamName: string,
  mascot: string,
  city: string,
  state: string,
  colors: { primary: RGB, secondary: RGB, accent: RGB }
  customFilters: object,
  logoURI: string,
  coaches: string[]
};

export type DBTeam = {
  name: string,
  mascot: string,
  games: string[],
  city: string,
  state: string,
  logoURI: string,
  dataURI: string
}

export type DBPlays = {
  opponentName: string,
  data: { count: number, plays: object }
}

export async function updateAccount(bearer: string, data: Team) {
  const response = await fetch(dataService + '/account', {
    method: 'POST',
    headers: {
      'authorization': 'Bearer ' + bearer,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
  });
  data = await response.json();
  return data;
}

export async function updateCustomFilters(bearer: string, data: CustomFilters) {
  const response = await fetch(dataService + '/account', {
    method: 'POST',
    headers: {
      'authorization': 'Bearer ' + bearer,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ customFilters: data })
  });
  data = await response.json();
  return data;
}

export async function getTeams(bearer: string) {
  const response = await fetch(dataService + '/teams', {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + bearer
    }
  });
  const data = await response.json();
  return data as Team[];
}

export async function changeTeamName(bearer: string, currentName: string, newData: Team) {
  const response = await fetch(dataService + '/account', {
    method: 'POST',
    headers: {
      'authorization': 'Bearer ' + bearer,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ oldName: currentName, newData: newData })
  });
  return await response.json();
}

// function example() {
//   setSavingConfig(true);
//   fetch(saveUrl)
//     .then(res => res.json())
//     .then(data => {
//       if (typeof data === 'object' && 'errors' in data && data.errors.length > 0) {
//         setConfigErrors(data.errors);
//       } else if (typeof data !== 'object' || !data.success) {
//         setConfigErrors(['An unexpected error occurred']);
//       } else {
//         setInits(inits + 1);
//       }
//     })
//     .catch(err => setConfigErrors([err.toString()]))
//     .finally(() => setSavingConfig(false));
// }

// function example2() {
//   function getData() {
//     const account = localStorage.getItem('cachedAccount');
//     if (account) {
//       const age = new Date(localStorage.getItem('age')) || new Date(1990, 1, 1);
//       if (new Date() - age < MAX_AGE) {
//         return JSON.parse(age);
//       }
//     }
//     fetch('something')
//       .then(res => {
//         localStorage.setItem('cachedAccount', res);
//         localStorage.setItem('Age', new Date());
//         return res.json();
//       })
//       .then(data => ...);
//   }
// }


export async function updateOpponent(bearer: string, data: Team) {
  const response = await fetch(dataService + '/teams', {
    method: 'POST',
    headers: {
      'authorization': 'Bearer ' + bearer,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  data = await response.json();
  return data;
}

export function updateOpponentSync(bearer: string, data: Team) {
  return fetch(dataService + '/teams', {
    method: 'POST',
    headers: {
      'authorization': 'Bearer ' + bearer,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
}

export async function getPlays(bearer: string, teamName: string) {
  const response = await fetch(dataService + '/plays?team-name=' + teamName, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + bearer,
    },
  });
  return await response.json();
}

export async function createPlays(bearer: string, data: Game) {
  const sendData = JSON.stringify({ plays: data });
  const response = await fetch(dataService + '/plays', {
    method: 'POST',
    headers: {
      'authorization': 'Bearer ' + bearer,
      'Content-Type': 'application/json'
    },
    body: sendData
  });
  data = await response.json();
  return data;
}



