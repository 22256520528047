import { PassHitZone, Play, Store } from './data-v2';

export class PassZoneChart {
  public plays: number;
  public completionRate: number;
  public sacks: number;
  public scrambles: number;

  public zonePlays: Map<PassHitZone, [PassHitZone, number]>;
  public zoneCompletionRates: Map<PassHitZone, [PassHitZone, number]>;

  public playTable: [string, number][];
  public targetedReceiverTable: [string, number][];
  public receiverSetTable: [string, number][];
  public fieldBoundaryTable: [string, number][];
  public strongWeakTable: [string, number][];
  public backfieldTable: [string, number][];
  public formationTable: [string, number][];
  public possessionGameTable: [string, number][];

  constructor(store: Store, allPlays: Play[], filteredPlays: Play[]) {
    this.plays = 0;
    this.sacks = 0;
    this.scrambles = 0;
    let completions = 0;

    this.zonePlays = new Map<PassHitZone, [PassHitZone, number]>();
    this.zonePlays.set(PassHitZone.DeepThirdLeft, [PassHitZone.DeepThirdLeft, 0]);
    this.zonePlays.set(PassHitZone.DeepThirdMiddle, [PassHitZone.DeepThirdMiddle, 0]);
    this.zonePlays.set(PassHitZone.DeepThirdRight, [PassHitZone.DeepThirdRight, 0]);
    this.zonePlays.set(PassHitZone.CurlFlatLeft, [PassHitZone.CurlFlatLeft, 0]);
    this.zonePlays.set(PassHitZone.HookCurlLeft, [PassHitZone.HookCurlLeft, 0]);
    this.zonePlays.set(PassHitZone.MidHook, [PassHitZone.MidHook, 0]);
    this.zonePlays.set(PassHitZone.HookCurlRight, [PassHitZone.HookCurlRight, 0]);
    this.zonePlays.set(PassHitZone.CurlFlatRight, [PassHitZone.CurlFlatRight, 0]);
    this.zonePlays.set(PassHitZone.FlatSwingLeft, [PassHitZone.FlatSwingLeft, 0]);
    this.zonePlays.set(PassHitZone.FlatSwingRight, [PassHitZone.FlatSwingRight, 0]);

    const zoneCompletions = new Map<PassHitZone, [PassHitZone, number]>();
    zoneCompletions.set(PassHitZone.DeepThirdLeft, [PassHitZone.DeepThirdLeft, 0]);
    zoneCompletions.set(PassHitZone.DeepThirdMiddle, [PassHitZone.DeepThirdMiddle, 0]);
    zoneCompletions.set(PassHitZone.DeepThirdRight, [PassHitZone.DeepThirdRight, 0]);
    zoneCompletions.set(PassHitZone.CurlFlatLeft, [PassHitZone.CurlFlatLeft, 0]);
    zoneCompletions.set(PassHitZone.HookCurlLeft, [PassHitZone.HookCurlLeft, 0]);
    zoneCompletions.set(PassHitZone.MidHook, [PassHitZone.MidHook, 0]);
    zoneCompletions.set(PassHitZone.HookCurlRight, [PassHitZone.HookCurlRight, 0]);
    zoneCompletions.set(PassHitZone.CurlFlatRight, [PassHitZone.CurlFlatRight, 0]);
    zoneCompletions.set(PassHitZone.FlatSwingLeft, [PassHitZone.FlatSwingLeft, 0]);
    zoneCompletions.set(PassHitZone.FlatSwingRight, [PassHitZone.FlatSwingRight, 0]);

    const playMap = new Map<string, [string, number]>();
    const targetedReceiverMap = new Map<string, [string, number]>();
    const receiverSetMap = new Map<string, [string, number]>();
    const backfieldMap = new Map<string, [string, number]>();
    const formationMap = new Map<string, [string, number]>();
    const possessionGameMap = new Map<string, [string, number]>();

    let fbFieldPlays = 0,
      fbMiddlePlays = 0,
      fbBoundaryPlays = 0,
      swStrongPlays = 0,
      swMiddlePlays = 0,
      swWeakPlays = 0;

    const fieldBoundaryMap = new Map<string, [string, number]>();
    const strongWeakMap = new Map<string, [string, number]>();

    fieldBoundaryMap.set('Field', ['Field', 0]);
    fieldBoundaryMap.set('Middle', ['Middle', 0]);
    fieldBoundaryMap.set('Boundary', ['Boundary', 0]);
    strongWeakMap.set('Strong', ['Strong', 0]);
    strongWeakMap.set('Middle', ['Middle', 0]);
    strongWeakMap.set('Weak', ['Weak', 0]);

    for (const play of allPlays) {
      const offensePlay = play.offensePlay.length > 0 ? play.offensePlay : 'None';
      if (!playMap.has(offensePlay) && offensePlay.length > 0) {
        playMap.set(offensePlay, [offensePlay, 0]);
      }
      if (!isNaN(play.receiverTargeted)) {
        const targetedReceiver = `${play.receiverTargeted}`;
        if (!targetedReceiverMap.has(targetedReceiver)) {
          targetedReceiverMap.set(targetedReceiver, [targetedReceiver, 0]);
        }
      }
      if (!receiverSetMap.has(play.receiverSet)) {
        receiverSetMap.set(play.receiverSet, [play.receiverSet, 0]);
      }
      if (!backfieldMap.has(play.backfield) && play.backfield.length > 0) {
        backfieldMap.set(play.backfield, [play.backfield, 0]);
      }
      if (!formationMap.has(play.offenseFormation)) {
        formationMap.set(play.offenseFormation, [play.offenseFormation, 0]);
      }

      const possValue = store.possessionGame(play);
      if (possValue !== undefined) {
        const possessionGame = possValue === 0
          ? '0' : (possValue > 0
            ? `+${possValue}`
            : `-${possValue}`);
        const mappedPossessionGame = possessionGameMap.get(possessionGame);
        if (!mappedPossessionGame) {
          possessionGameMap.set(possessionGame, [possessionGame, 0]);
        }
      }
    }

    for (const play of filteredPlays) {
      this.plays++;
      const mappedPlayRow = this.zonePlays.get(play.passHitZone);
      if (mappedPlayRow) {
        mappedPlayRow[1]++;
      }

      if (play.isScramble) {
        this.scrambles++;
      }

      if (play.isSack) {
        this.sacks++;
      }

      if (play.isComplete) {
        completions++;
        const mappedPlayRow = zoneCompletions.get(play.passHitZone);
        if (mappedPlayRow) {
          mappedPlayRow[1]++;
        }
      }

      if (play.offenseStrength.isField) {
        fbFieldPlays++;
      } else if (play.offenseStrength.isBoundary) {
        fbBoundaryPlays++
      } else {
        fbMiddlePlays++;
      }
      if (play.offenseStrength.isStrong) {
        swStrongPlays++;
      } else if (play.offenseStrength.isWeak) {
        swWeakPlays++;
      } else {
        swMiddlePlays++;
      }

      {
        const offensePlay = play.offensePlay.length > 0 ? play.offensePlay : 'None';
        const mappedRow = playMap.get(offensePlay);
        if (mappedRow) {
          mappedRow[1]++;
        }
      }
      if (!isNaN(play.receiverTargeted)) {
        const targetedReceiver = `${play.receiverTargeted}`;
        const mappedRow = targetedReceiverMap.get(targetedReceiver);
        if (mappedRow) {
          mappedRow[1]++;
        }
      }
      {
        const mappedRow = receiverSetMap.get(play.receiverSet);
        if (mappedRow) {
          mappedRow[1]++;
        }
      }
      {
        const mappedRow = backfieldMap.get(play.backfield);
        if (mappedRow) {
          mappedRow[1]++;
        }
      }
      {
        const mappedRow = formationMap.get(play.offenseFormation);
        if (mappedRow) {
          mappedRow[1]++;
        }
      }

      const possValue = store.possessionGame(play);
      if (possValue !== undefined) {
        const possessionGame = possValue === 0
          ? '0' : (possValue > 0
            ? `+${possValue}`
            : `-${possValue}`);
        const mappedPossessionGame = possessionGameMap.get(possessionGame);
        if (mappedPossessionGame) {
          mappedPossessionGame[1]++;
        }
      }
    }

    this.completionRate = completions / Math.max(this.plays, 1);
    this.zoneCompletionRates = new Map<PassHitZone, [PassHitZone, number]>();
    for (let value of this.zonePlays.values()) {
      const mappedRow = zoneCompletions.get(value[0]);
      if (mappedRow) {
        this.zoneCompletionRates.set(mappedRow[0], [mappedRow[0], mappedRow[1] / Math.max(1, value[1])]);
      }
    }

    this.playTable = Array.from(playMap.values());
    this.targetedReceiverTable = Array.from(targetedReceiverMap.values());
    this.receiverSetTable = Array.from(receiverSetMap.values());
    this.backfieldTable = Array.from(backfieldMap.values());
    this.formationTable = Array.from(formationMap.values());
    this.possessionGameTable = Array.from(possessionGameMap.values());

    this.fieldBoundaryTable = [
      ['Field', fbFieldPlays],
      ['Middle', fbMiddlePlays],
      ['Boundary', fbBoundaryPlays],
    ];
    this.strongWeakTable = [
      ['Strong', swStrongPlays],
      ['Middle', swMiddlePlays],
      ['Weak', swWeakPlays],
    ];
  }
}
