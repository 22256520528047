import React from 'react';

interface LabeledProps {
  label: string;
  children: React.ReactNode;
}
export function Labeled(props: LabeledProps) {
  return <label className="col-span-6">
    <div className="block text-sm font-medium leading-5 text-gray-400">
      {props.label}
    </div>
    <div className="mt-1 flex rounded-md shadow-sm">
      {props.children}
    </div>
  </label>;
}

interface LabeledInputProps {
  label: string;
  value: string;
  onChange: (_: string) => void;
}
export function LabeledInput(props: LabeledInputProps) {
  const { label, value, onChange } = props;
  return <Labeled label={label}>
    <input type="text" className="flex-1 rounded-md form-input block w-full min-w-0 transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700" value={value} onChange={e => onChange(e.currentTarget.value)} />
  </Labeled>;
}

interface LabeledSelectProps {
  label: string;
  value: string;
  options: Map<string, string>;
  onChange: (_: string) => void;
}
export function LabeledSelect<T>(props: LabeledSelectProps) {
  const { label, value, onChange, options } = props;
  return <Labeled label={label}>
    <select id="position" className="form-select rounded-md block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700 cursor-pointer" value={value} onChange={e => onChange(e.currentTarget.value)} >
      {Array.from(options.entries()).map(([k, v]) => <option key={k} value={k}>{v}</option>)}
    </select>
  </Labeled>;
}