import React, { useState } from 'react';

interface DropZoneProps {
  border: boolean;
  children: React.ReactNode;
  receiveFiles: (files: FileList) => void;
}
export function DropHandler(props: DropZoneProps) {
  const [hovering, setHovering] = useState<boolean>(false);

  function onDrop(e: React.DragEvent<HTMLDivElement>) {
    setHovering(false);
    props.receiveFiles(e.dataTransfer.files);
  }

  const style = hovering
    ? `mt-2 w-full flex pb-6 max-w-xl ${props.border ? 'border-dashed border-2 border-gray-700' : ''} rounded-md`
    : `mt-2 w-full flex pb-6 max-w-xl ${props.border ? 'border-dashed border-2 border-gray-700' : ''} rounded-md opacity-90`;
  return <div className={style} onDragEnter={() => setHovering(true)} onDragLeave={() => setHovering(false)} onDrop={onDrop}>
    {props.children}
  </div>;
}