import React from 'react';

interface SocialLinksProps {
  show: boolean;
}
export function SocialLinks(props: SocialLinksProps) {
  return (!props.show)
    ? null
    : <div>
      <p className="text-sm font-medium text-gray-400">
        Sign in with
      </p>

      <div className="mt-1 grid grid-cols-3 gap-3">
        <div>
          <a href="#" className="w-full inline-flex justify-center py-2 px-4 border border-gray-800 rounded-md shadow-sm bg-gray-900 text-sm font-medium text-gray-500 hover:bg-gray-50">
            <span className="sr-only">Sign in with Google</span>
            <i className="w-5 h-5 text-gray-300 fab fa-google">
            </i>
          </a>
        </div>
        <div>
          <a href="#" className="w-full inline-flex justify-center py-2 px-4 border border-gray-800 rounded-md shadow-sm bg-gray-900 text-sm font-medium text-gray-500 hover:bg-gray-50">
            <span className="sr-only">Sign in with Facebook</span>
            <i className="w-5 h-5 text-gray-300 fab fa-facebook-f">
            </i>
          </a>
        </div>
        <div>
          <a href="#" className="w-full inline-flex justify-center py-2 px-4 border border-gray-800 rounded-md shadow-sm bg-gray-900 text-sm font-medium text-gray-500 hover:bg-gray-50">
            <span className="sr-only">Sign in with Twitter</span>
            <i className="w-5 h-5 text-gray-300 fab fa-twitter">
            </i>
          </a>
        </div>
      </div>
    </div>
}