import React from 'react';
import { Link } from 'react-router-dom';
import { UserMenu } from './user-menu';

export function HeaderBar() {
  return (<div className="fixed w-full z-40 flex-shrink-0 flex h-16 bg-gray-800 shadow">
    <div className="flex-shrink-0 flex items-center px-4">
      <Link to="/schedule">
        <img className="h-7 sm:h-10 w-auto" src="https://storage.googleapis.com/sportsiq/static/sports-iq-logo-white-color.png" alt="Workflow" />
      </Link>
    </div>
    <div className="flex-1 px-4 flex justify-between">
      <div className="flex-1 flex">
      </div>
      <div className="ml-2 sm:ml-4 flex items-center md:ml-6">
        <Link to="/schedule">
          <button className="px-2 sm:px-3 py-2 text-white rounded-full hover:bg-gray-900 hover:text-gray-200 focus:outline-none focus:shadow-outline focus:text-gray-200" aria-label="Notifications">
            <i className="fas fa-calendar-alt text-xl mr-2" />
            <span className="hidden md:inline">Game Schedule</span>
          </button>
        </Link>
        <Link to="/custom-filters/distances">
          <button className="px-2 sm:px-3 py-2 text-white rounded-full hover:bg-gray-900 hover:text-gray-200 focus:outline-none focus:shadow-outline focus:text-gray-200" aria-label="Notifications">
            <i className="fas fa-cog text-xl mr-2" />
            <span className="hidden md:inline">Scout Settings</span>
          </button>
        </Link>
        <UserMenu />
      </div>
    </div>
  </div>);
}
