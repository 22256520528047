import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Heading } from '../components/heading';
import { Shell } from '../components/shell';
import { Block } from '../components/block';
import { CustomFilters as CustomFiltersModel, EditablePair, NamedPair, Pair } from '../logic/custom-filters';
import { customFiltersStorage } from '../hooks/custom-filters';
import { Link, useParams } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SlimModal } from '../components/modal';
import { updateCustomFilters } from '../logic/server-requests';
import { dataStorage, Store } from '../logic/data-v2';
import firebase from "firebase/app";
import "firebase/auth";
import { LoggedOut } from './logged-out';

const rtLn = (ydLn: number) => ydLn > 0 ? 0 - (50 - ydLn) : 50 + ydLn;
const ydLn = (rtLn: number) => rtLn < 0 ? 50 + rtLn : -50 + rtLn;

export function CustomFiltersPage() {

  // in the distance editor, when dealing with negative values (field zones page), we need to adjust input numbers to the following for sorting/displaying purposes:
  // ydLn > 0 ? 0 - (50 - ydLn) : 50 + ydLn

  interface RouteParams {
    filterName: 'distances' | 'field-zones' | 'gains' | 'formations' | 'receiver-sets';
  }

  const [user, setUser] = useState<boolean>(false);
  const { filterName } = useParams<RouteParams>();
  const [customFilterData, setCustomFilterData] = useState<CustomFiltersModel>(CustomFiltersModel.default());
  const [dataStoreData, setDataStoreData] = useState<Store>(new Store([]));
  const customFilters = CustomFiltersModel.copy(customFilterData);

  // Subscribe to data from other parts of the app
  useLayoutEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user !== null);
      const s = [
        customFiltersStorage.subscribe(setCustomFilterData),
        dataStorage.subscribe(setDataStoreData)
      ];
      return () => s.forEach(s => s?.unsubscribe());
    });
  });

  function changeDistances(pairs: Pair[]) {
    if (CustomFiltersModel.default().withDistances(pairs).valid) {
      const newFilters = customFilters.withDistances(pairs);
      customFiltersStorage.save(newFilters);
      uploadChanges(newFilters);
    }
  }

  function changeFieldZones(pairs: Pair[], names: string[]) {
    const newFieldZones = Array.from(pairs.keys()).map<NamedPair>(i => [names[i], pairs[i]]);
    if (CustomFiltersModel.default().withFieldZones(newFieldZones).valid) {
      const newFilters = customFilters.withFieldZones(newFieldZones);
      customFiltersStorage.save(newFilters);
      uploadChanges(newFilters);
    }
  }

  function changeGains(pairs: Pair[], names: string[]) {
    const newGains = Array.from(pairs.keys()).map<NamedPair>(i => [names[i], pairs[i]]);
    if (CustomFiltersModel.default().withGainLosses(newGains).valid) {
      const newFilters = customFilters.withGainLosses(newGains);
      customFiltersStorage.save(newFilters);
      uploadChanges(newFilters);
    }
  }

  function changeFormations(formations: EditablePair[]) {
    if (CustomFiltersModel.default().withFormations(formations).valid) {
      const newFilters = customFilters.withFormations(formations);
      customFiltersStorage.save(newFilters);
      uploadChanges(newFilters);
    }
  }

  function changeReceiverSet(receiverSets: EditablePair[]) {
    if (CustomFiltersModel.default().withReceiverSets(receiverSets).valid) {
      const newFilters = customFilters.withReceiverSets(receiverSets);
      customFiltersStorage.save(newFilters);
      uploadChanges(newFilters);
    }
  }

  function uploadChanges(newData: CustomFiltersModel) {
    try {
      updateCustomFilters("jack", newData);
    } catch (e) {
      console.log("Error uploading data: " + e);
    }
  }

  const fieldZones = customFilters.fieldZones.sort((a, b) => rtLn(b[1][0]) - rtLn(a[1][0]));
  const gains = customFilters.gainLosses.sort((a, b) => a[1][0] - b[1][0]);


  const formationChoices: string[] = [];
  if (filterName === 'formations') {
    dataStoreData.games.map((game) => game.plays.map((play) => {
      if (formationChoices.indexOf(play.offenseFormation) < 0 && play.offenseFormation.length > 0) {
        formationChoices.push(play.offenseFormation);
      }
      return play;
    }));
  } else if (filterName === 'receiver-sets') {
    dataStoreData.games.map((game) => game.plays.map((play) => {
      if (formationChoices.indexOf(play.receiverSet) < 0 && play.receiverSet.length > 0) {
        formationChoices.push(play.receiverSet);
      }
      return play;
    }));
  }

  if (!user) {
    return <LoggedOut />;
  }

  return (<Shell>
    <Heading>
      Customize Your Scout Settings
      <p className="text-gray-600 mb-6 text-sm font-normal">Navigate using the tabs below in order to apply custom settings for your scouting dashboards.</p>
    </Heading>
    <Link className="text-gray-300 hover:text-gray-500 cursor-pointer" to="/pass-zone-chart">Back to charts</Link>

    <Tabs currentTab={filterName} />
    <HiddenWrapper when={filterName} is="distances">
      <DistancesEditor pairs={customFilters.distances} onChange={changeDistances} min={1} max={100} asYardLines={false} defaultPairs={CustomFiltersModel.default().distances} />
    </HiddenWrapper>
    <HiddenWrapper when={filterName} is="field-zones">
      <DistancesEditor pairs={fieldZones.map(a => a[1])} names={fieldZones.map(a => a[0])} onChange={changeFieldZones} min={-49} max={50} asYardLines={true} defaultPairs={CustomFiltersModel.default().fieldZones.map(a => a[1])} defaultNames={CustomFiltersModel.default().fieldZones.map(a => a[0])} />
    </HiddenWrapper>
    <HiddenWrapper when={filterName} is="gains">
      <DistancesEditor pairs={gains.map(a => a[1])} names={gains.map(a => a[0])} onChange={changeGains} min={1} max={100} asYardLines={false} defaultPairs={CustomFiltersModel.default().gainLosses.map(a => a[1])} defaultNames={CustomFiltersModel.default().gainLosses.map(a => a[0])} />
    </HiddenWrapper>
    <HiddenWrapper when={filterName} is="formations">
      <FormationEditor values={customFilters.formations} dataValues={formationChoices} onChange={changeFormations} fromTitle="Formation" toTitle="Personnel" />
    </HiddenWrapper>
    <HiddenWrapper when={filterName} is={"receiver-sets"}>
      <FormationEditor values={customFilters.receiverSets} dataValues={formationChoices} onChange={changeReceiverSet} fromTitle="Formation Strength" toTitle="Receiver Set" />
    </HiddenWrapper>
  </Shell>);
}

interface DistancesEditorProps {
  defaultPairs: Pair[],
  defaultNames?: string[],
  pairs: Pair[],
  names?: string[],
  min: number,
  max: number,
  asYardLines: boolean,
  onChange: (pairs: Pair[], names: string[]) => void;
}
function DistancesEditor(props: DistancesEditorProps) {

  const { pairs, onChange } = props;
  const names = props.names || [];
  const [modalState, setModalState] = useState({
    open: false,
    value: '',
    index: -1,
    type: '',
    which: '',
  });

  // RK: I am not proud of this logic.
  function junction(action: 'split' | 'join', index: number) {
    const theSort = props.asYardLines
      ? (a: Pair, b: Pair) => a[0] - b[0]
      : (a: Pair, b: Pair) => rtLn(a[0]) - rtLn(b[0]);
    if (action === 'split') {
      const values = pairs[index];
      const loValue = props.asYardLines ? rtLn(values[0]) : values[0];
      const hiValue = props.asYardLines ? rtLn(values[1]) : values[1];
      if (hiValue - loValue > 1) {
        const midPoint = props.asYardLines
          ? ydLn(hiValue - Math.floor((hiValue - loValue) / 2))
          : hiValue - Math.floor((hiValue - loValue) / 2);
        const newNames = Array.from(names);
        newNames.splice(index, 0, '');
        const newDistances = pairs
          .filter(a => a !== values)
          .concat([[values[0], midPoint], [midPoint + 1, values[1]]])
          .sort(theSort);
        onChange(newDistances, newNames);
      } else if (hiValue - loValue === 1) {
        const newNames = Array.from(names);
        newNames.splice(index, 0, '');
        const newDistances = pairs
          .filter(a => a !== values)
          .concat([[values[0], values[0]], [values[1], values[1]]])
          .sort(theSort);
        onChange(newDistances, newNames);
      } else {
        alert('cannot split. not enough values');
      }
    } else {
      // When joining [-20, -49], [50, 41], it needs to become [-20, 41]
      const lowValues = pairs[index];
      const highValues = pairs[index + 1];
      if (lowValues[0] < 0) {
        const newDistances = pairs
          .filter(a => a !== lowValues && a !== highValues)
          .concat([[lowValues[1], highValues[0]]])
          .sort(theSort);
        onChange(newDistances, names);
      } else {
        const newDistances = pairs
          .filter(a => a !== lowValues && a !== highValues)
          .concat([[lowValues[0], highValues[1]]])
          .sort(theSort);
        onChange(newDistances, names);
      }
    }
  }

  function edit(which: 'low' | 'high', index: number, newValue: string) {
    const asInt = parseInt(newValue || '', 10);
    // if it is the high, also update the low to be val + 1, assuming that 
    if (!isNaN(asInt)) {
      if (which === 'high') {
        // it must be larger or equal to the low value
        if (asInt < pairs[index][0]) {
          return;
        }
        // it can't be larger than 1 less than the next max
        if (asInt > pairs[index + 1][1] - 1) {
          return;
        }
        const newDistances = Array.from(pairs)
          .filter(distance => distance !== pairs[index] && distance !== pairs[index + 1])
          .concat([
            [pairs[index][0], asInt],
            [asInt + 1, pairs[index + 1][1]],
          ])
          .sort((a, b) => a[0] - b[0]);
        onChange(newDistances, names);
      } else {
        // it must be smaller or equal to the high value
        if (asInt > pairs[index][1]) {
          return;
        }
        // it can't be smaller than 1 more than the last min
        if (asInt < pairs[index - 1][0] + 1) {
          return;
        }
        const newDistances = Array.from(pairs)
          .filter(distance => distance !== pairs[index] && distance !== pairs[index - 1])
          .concat([
            [pairs[index - 1][0], asInt - 1],
            [asInt, pairs[index][1]],
          ])
          .sort((a, b) => a[0] - b[0]);
        onChange(newDistances, names);
      }
    }
  }

  function editName(index: number) {
    setModalState({
      open: true,
      value: names[index],
      index,
      type: 'name',
      which: '',
    });
  }

  function editDistance(index: number, which: 'high' | 'low') {
    if ((which === 'high' && pairs[index][1] === props.max)
      || (which === 'low' && pairs[index][0] === props.min)) {
      return;
    }
    setModalState({
      open: true,
      value: which === 'high' ? `${pairs[index][1]}` : `${pairs[index][0]}`,
      index,
      type: 'distance',
      which,
    });
  }

  function editModalValue(value: string) {
    setModalState({ open: modalState.open, value, index: modalState.index, type: modalState.type, which: modalState.which })
  }

  function modalKeyUp(key: string) {
    if (key === 'Enter') {
      commitModal();
    } else if (key === 'Escape') {
      closeModal();
    }
  }

  function closeModal() {
    setModalState({ open: false, value: '', index: -1, type: '', which: '' });
  }

  function commitModal() {
    if (modalState.value) {
      if (modalState.type === 'name') {
        let x = Array.from(names);
        x[modalState.index] = modalState.value;
        onChange(pairs, x);
      } else {
        edit(modalState.which === 'high' ? 'high' : 'low', modalState.index, modalState.value);
      }
    }
    closeModal();
  }

  const numberColspan = props.names !== undefined ? 'col-span-3' : 'col-span-5';

  const min = props.min + (props.asYardLines ? 50 : 0);
  const max = props.max - (props.asYardLines ? 50 : 0);

  const distances = pairs.map((distance, i) => {
    const swapped = props.asYardLines;
    const loValue = swapped ? distance[1] : distance[0];
    const hiValue = swapped ? distance[0] : distance[1];
    const loDisabled = swapped ? loValue === max : loValue === min;
    const hiDisabled = swapped ? hiValue === min : hiValue === max;

    return [
      props.names !== undefined ? <div className="col-span-4 py-2">
        <button className="block w-full bg-gray-800 rounded-md focus:border-gray-900 focus:ring focus:ring-gray-500 focus:ring-opacity-50 sm:text-sm sm:leading-5 cursor-pointer text-center p-2 mb-4" onClick={() => editName(i)}>
          {names[i] || '\xa0'}
        </button>
      </div> : null,
      <div className={`px-6 ${numberColspan} py-2 whitespace-no-wrap text-right text-sm leading-5 font-medium w-full`}>
        <div className="relative rounded-md shadow-sm">
          <button className={`${loDisabled ? 'opacity-50 pointer-events-none' : 'hover:text-white hover:border-gray-600'} block w-full bg-gray-800 rounded-md focus:border-gray-900 focus:ring focus:ring-gray-500 focus:ring-opacity-50 sm:text-sm sm:leading-5 cursor-pointer text-center p-2`} onClick={() => editDistance(i, swapped ? 'high' : 'low')}>
            {loValue}
          </button>
        </div>
      </div >,
      <div className="py-4 colspan-1">to</div>,
      <div className={`px-6 ${numberColspan} py-2 whitespace-no-wrap text-right text-sm leading-5 font-medium w-full`}>
        <div className="relative rounded-md shadow-sm">
          <button className={`${hiDisabled ? 'opacity-50 pointer-events-none' : 'hover:text-white hover:border-gray-600'} block w-full bg-gray-800 rounded-md focus:border-gray-900 focus:ring focus:ring-gray-500 focus:ring-opacity-50 sm:text-sm sm:leading-5 cursor-pointer text-center p-2`} onClick={() => editDistance(i, swapped ? 'low' : 'high')}>
            {hiValue}
          </button>
        </div>
      </div>,
      (swapped ? loValue > hiValue : hiValue > loValue) ? <button className="h-10 px-4 my-2 colspan-1 text-right cursor-pointer bg-primary-600 hover:bg-primary-700 rounded-md" onClick={() => junction('split', i)}>Split</button> : null,
      i !== pairs.length - 1 ? <div className="col-span-12 text-right my-2 hover:text-primary-500 cursor-pointer" onClick={() => junction('join', i)}>
        Join
        <FontAwesomeIcon className="ml-1" icon={['fas', 'arrows-alt-v']} />
      </div> : null,
    ];
  });

  return (<Block>
    {!modalState.open ? null : <SlimModal title="Edit Value" close={closeModal} save={commitModal}>
      <input autoFocus type="text" className="block w-full bg-gray-800 rounded-md focus:border-gray-900 focus:ring focus:ring-gray-500 focus:ring-opacity-50 sm:text-sm sm:leading-5 cursor-pointer text-center w-full p-1 mb-4" value={modalState.value} onChange={e => editModalValue(e.target.value)} onKeyUp={e => modalKeyUp(e.key)} />
    </SlimModal>}
    <div className="p-6">
      <div className="flex justify-center">
        <div className="pb-4 grid grid-cols-12">
          {distances}
        </div>
      </div>
      <button onClick={() => onChange(props.defaultPairs, props.defaultNames || [])} type="button" className="ml-2 my-4 inline-flex items-center px-4 py-3 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-secondary-600 hover:bg-gray-800 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700 transition ease-in-out duration-150">
        <FontAwesomeIcon icon={['fas', 'sync-alt']} />
        <span className="ml-2">Restore Defaults</span>
      </button>
    </div>
  </Block>);
}

interface FormationEditorProps {
  values: EditablePair[],
  dataValues: string[],
  onChange: (_: EditablePair[]) => void,
  toTitle: string,
  fromTitle: string,
}
function FormationEditor(props: FormationEditorProps) {

  const { values, dataValues, onChange } = props;
  const [finalVals, setFinalVals] = useState<EditablePair[]>();
  const [modalState, setModalState] = useState({
    open: false,
    formationValue: '',
    personnelValue: '',
    index: -1,
    autoFocus: '',
  });

  function openModal(index: number, autoFocus: 'formation' | 'personnel') {
    setModalState({
      open: true,
      index,
      formationValue: finalVals && index >= 0 && index < finalVals.length ? finalVals[index][0] : '',
      personnelValue: finalVals && index >= 0 && index < finalVals.length ? finalVals[index][1] : '',
      autoFocus,
    });
  }

  function closeModal() {
    setModalState({ open: false, formationValue: '', personnelValue: '', index: -1, autoFocus: '' });
  }

  function commitModal() {
    const { index, personnelValue, formationValue } = modalState;
    if (personnelValue.length > 0 && formationValue.length > 0) {
      if (finalVals) {
        finalVals.splice(index, 1, [formationValue, personnelValue]);
        onChange(finalVals.filter((v) => v[1].length > 0));
      }
    }
    closeModal();
  }

  function modalKeyUp(key: string) {
    if (key === 'Enter') {
      commitModal();
    } else if (key === 'Escape') {
      closeModal();
    }
  }

  function editModalValue(which: 'formation' | 'personnel', value: string) {
    setModalState({
      open: modalState.open,
      formationValue: which === 'formation' ? value : modalState.formationValue,
      personnelValue: which === 'personnel' ? value : modalState.personnelValue,
      index: modalState.index,
      autoFocus: modalState.autoFocus,
    });
  }

  function remove(index: number) {
    const x = Array.from(finalVals || []);
    x.splice(index, 1);
    onChange(x);
  }

  useEffect(() => {
    const undefinedValues: EditablePair[] = dataValues.filter((dv) => Array.from(values.map((v) => v[0])).indexOf(dv) < 0).map((dv) => [dv, '']);
    setFinalVals([...undefinedValues, ...values]);
  }, [values, dataValues]);

  const rows = finalVals?.map((row, i) => (<tr key={i}>
    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-300">
      <div className="relative rounded-md shadow-sm">
        <input className="block w-full bg-gray-800 rounded-md focus:border-gray-900 focus:ring focus:ring-gray-500 focus:ring-opacity-50 sm:text-sm sm:leading-5 cursor-pointer text-center" type="text" value={row[0]} onClick={() => openModal(i, 'formation')} />
      </div>
    </td>
    <td className="px-1 py-4 whitespace-no-wrap text-sm leading-5 text-gray-300">
      <div>
        <div className="relative rounded-md shadow-sm">
          <input type="text" className="block w-full bg-gray-800 rounded-md focus:border-gray-900 focus:ring focus:ring-gray-500 focus:ring-opacity-50 sm:text-sm sm:leading-5 cursor-pointer text-center" value={row[1]} onClick={() => openModal(i, 'personnel')} />
        </div>
      </div>
    </td>
    <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
      <a onClick={() => remove(i)} className="text-primary-500 hover:text-primary-700 cursor-pointer">Clear</a>
    </td>
  </tr>));



  return (<div>
    {!modalState.open ? null : <SlimModal title={"Edit " + props.fromTitle} close={closeModal} save={commitModal}>
      <div className="text-sm text-left mb-1">{props.fromTitle}</div>
      <input autoFocus={modalState.autoFocus === 'formation'} type="text" className="w-full p-1 bg-gray-800 rounded-lg border-solid border border-gray-700" value={modalState.formationValue} onKeyUp={e => modalKeyUp(e.key)} />
      <div className="text-sm text-left mb-1 mt-2">{props.toTitle}</div>
      <input autoFocus={modalState.autoFocus === 'personnel'} type="text" className="w-full p-1 bg-gray-800 rounded-lg border-solid border border-gray-700 mb-4" value={modalState.personnelValue} onChange={e => editModalValue('personnel', e.target.value)} onKeyUp={e => modalKeyUp(e.key)} />
    </SlimModal>}
    <table className="m-auto my-6">
      <tbody>
        <tr>
          <th className="text-sm font-normal text-gray-500 uppercase text-left px-6">{props.fromTitle}</th>
          <th className="text-sm font-normal text-gray-500 uppercase text-left px-1">{props.toTitle}</th>
          <th>&nbsp;</th>
        </tr>
        {rows}
      </tbody>
    </table>
    {/* <button onClick={() => openModal(-1, 'formation')} type="button" className="inline-flex items-center px-4 py-3 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:border-primary-700 focus:shadow-outline-blue active:bg-primary-700 transition ease-in-out duration-150">
      <i className="fas fa-plus"></i>
      <span className="ml-3">New Value</span>
    </button> */}
  </div>);
}

interface TabLinkProps {
  active: boolean;
  children: React.ReactNode;
  path: string;
}
function TabLink(props: TabLinkProps) {
  const className = props.active
    ? 'ml-8 group inline-flex items-center py-4 px-1 border-b-2 border-primary-500 font-medium text-base leading-5 text-gray-100 focus:outline-none focus:text-gray-100 focus:border-primary-600"'
    : 'ml-8 group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-base leading-5 text-gray-500 hover:text-gray-200 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300';
  return (<Link to={props.path} className={className}>
    {props.children}
  </Link>);
}

interface TabsProps {
  currentTab: 'distances' | 'field-zones' | 'gains' | 'formations' | 'receiver-sets',
}
function Tabs(props: TabsProps) {
  const tabs: [string, string, IconProp][] = [
    ['distances', 'Distances', ['fas', 'arrows-alt-h']],
    ['field-zones', 'Field Zones', ['fas', 'align-justify']],
    ['gains', 'Yards Gained', ['fas', 'arrows-alt-v']],
    ['formations', 'Formations', ['fas', 'grip-horizontal']],
    ['receiver-sets', 'Receiver Sets', ['fas', 'users']],
  ];
  return (<div>
    <div className="border-b border-gray-900">
      <nav className="flex -mb-px justify-center">
        {tabs.map(tab => <TabLink active={props.currentTab === tab[0]} path={`/custom-filters/${tab[0]}`}>
          <FontAwesomeIcon icon={tab[2]} />
          &nbsp;
          {tab[1]}
        </TabLink>)}
      </nav>
    </div>
  </div>);
}

interface HiddenWrapperProps<T> {
  when: T;
  is: T;
  children: React.ReactNode;
}
function HiddenWrapper<T>(props: HiddenWrapperProps<T>) {
  return (<div className={`${props.when === props.is ? '' : 'hidden'}`}>
    {props.children}
  </div>);
}
