import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

interface Props {
  mobile: boolean;
}

export function Nav(props: Props) {
  const className = props.mobile ? 'px-2 space-y-3 w-52' : 'flex-1 px-2 space-y-3 w-52 mx-4 my-4';
  return (<nav className={className}>
    <NavLink to={`/pass-zone-chart`} title="Pass Zone Chart" icon={['fas', 'football-ball']} />
    <NavLink to={`/run-zone-chart`} title="Run Zone Chart" icon={['fas', 'running']} />
    <NavLink to={`/field-zone-chart`} title="Field Zone Chart" icon={['fas', 'grip-vertical']} />
    <NavLink to={`/schedule`} title="Back to Schedule" icon={['fas', 'arrow-left']} />
  </nav >);
}

interface NavLinkProps {
  icon: IconProp;
  to: string;
  title: string;
}

function NavLink(props: NavLinkProps) {
  const { pathname, search } = useHistory().location;
  const classes = (pathname).substr(-props.to.length) === props.to
    ? 'group flex items-center justify-left px-1 text-base leading-6 font-medium text-white focus:outline-none transition ease-in-out duration-150 ux-nav-text-show transform bg-gray-600 rounded-full ux-nav-link'
    : 'group flex items-center justify-left px-1 text-sm leading-5 font-medium text-gray-300 hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150 transform border border-gray-700 hover:border-gray-600 bg-gray-900 rounded-full ux-nav-link hide-ux-nav-text';

  return (<Link to={props.to + search} className={classes}>
    <div className="rounded-full p-2 h-10 w-10 mr-2">
      <FontAwesomeIcon icon={props.icon} className="fa-fw h-6 w-6 text-gray-200 group-hover:text-white group-focus:text-white transition ease-in-out duration-150" />
    </div>
    <span className="ux-nav-text">{props.title}</span>
  </Link>);
}
