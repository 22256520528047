import React, { useEffect, useLayoutEffect, useState } from 'react';
import { ShellWithSideBar } from '../components/shell';
import { MapSelect } from '../components/map-select-v2';
import { CustomFilters, Pair } from '../logic/custom-filters';
import { FootballField } from '../components/football-field';
import { DataCell, GridCell, GridCell2Responsive, GridCellSplit } from '../components/grid-cell';
import { Block } from '../components/block';
import { defaultTeam, Team, teamStorage } from '../hooks/my-team-hook';
import { dataStorage, ODK, Play, PlayType, Store } from '../logic/data-v2';
import { FieldZoneChart } from '../logic/field-zone-chart';
import { customFiltersStorage } from '../hooks/custom-filters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, TableValue } from '../components/table';
import { useHistory } from 'react-router-dom';
import firebase from "firebase/app";
import "firebase/auth";
import { LoggedOut } from './logged-out';

// This is necessary so that pointer problems don't happen during re-render
const noGain: Pair = [0, 0];
const loss: Pair = [-1000, -1];

// This function turns a setState function into a MapSelect shouldChange function
function mapSet<T>(setState: (_: T[]) => void) {
  return (values: T[]) => {
    setState(values);
    return true;
  };
}

function baseFilter(play: Play) {
  return play.odk === ODK.Offense;
}

// This one was fun. Turns [[1,3], [5, 9]] => [1,2,3,5,6,7,8,9]
function pairVectorToRange(input: Pair[]) {
  return input.reduce<number[]>((a, pair) => a.concat(Array.from(Array(pair[1] - pair[0] + 1).keys()).map(x => x + pair[0])), []);
}

function flatTableToMap(table: [string, number][]) {
  const map = new Map<string, TableValue[]>();
  const total = table.reduce((a, e) => a + e[1], 0);
  table.forEach(play => map.set(play[0], [
    play[0],
    play[1],
    Math.max((play[1] / Math.max(total, 1) - 0.001), 0),
  ]));
  return map;
}

export function FieldZoneChartPage() {

  // Set up hooks to other parts of the application
  const [user, setUser] = useState<boolean>(false);
  const [customFilterData, setCustomFilterData] = useState<CustomFilters>(CustomFilters.default());
  const [dataStoreData, setDataStoreData] = useState<Store>(Store.default());
  const [team, setTeam] = useState<Team>(defaultTeam());
  useLayoutEffect(() => {
    const s = [
      customFiltersStorage.subscribe(setCustomFilterData),
      dataStorage.subscribe(setDataStoreData),
      teamStorage.subscribe(setTeam),
    ];
    return () => s.forEach(s => s?.unsubscribe());
  })

  const rangeStr = (min: number, max: number) => min === max ? `${min}` : (max < 100 ? `${min}-${max}` : `${min}+`);
  const rangeStrV = (v: [number, number]) => rangeStr(v[0], v[1]);

  const [viewBy, setViewBy] = useState<'plays' | 'formations'>('formations');

  // Filters

  const customFilters = CustomFilters.copy(customFilterData);
  const [filteredDistances, setFilteredDistances] = useState<Pair[]>(customFilters.distances);
  const [filteredDowns, setFilteredDowns] = useState<number[]>([1, 2, 3, 4]);
  const [filteredFieldZones, setFilteredFieldZones] = useState<Pair[]>([customFilters.fieldZones[0][1]]);
  const [filteredPreviousGains, setFilteredPreviousGains] = useState<Pair[]>(customFilters.gainLosses.map(a => a[1]));
  const [filteredPlayNames, setFilteredPlayNames] = useState<string[]>([]);
  const [filteredPlayTypes, setFilteredPlayTypes] = useState<PlayType[]>([PlayType.Run, PlayType.Pass]);
  const [filteredPlayers, setFilteredPlayers] = useState<string[]>([]);
  const [filteredFormations, setFilteredFormations] = useState<string[]>([]);
  const [filteredTeamNames, setFilteredTeamNames] = useState<string[]>([]);
  const [filteredPossessionGames, setFilteredPossessionGames] = useState<string[]>([]);
  const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(false);
  const [field3D, setField3D] = useState<boolean>(false);
  const [targetedTeamId, setTargetedTeamId] = useState<string>('Your Team Name');
  const history = useHistory();

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user !== null);
      const queryString = history.location.search.substr(1);
      const matches = queryString.match(/team=(\d+)/);
      if (matches) {
        setTargetedTeamId(matches[1]);
      }
    });
  }, []);

  const distanceChoices = new Map<Pair, string>();
  for (const distance of customFilters.distances) {
    distanceChoices.set(distance, rangeStrV(distance));
  }

  const downChoices = new Map<number, string>();
  downChoices.set(1, '1');
  downChoices.set(2, '2');
  downChoices.set(3, '3');
  downChoices.set(4, '4');

  const fieldZoneChoices = new Map<Pair, string>();
  for (let fieldZone of customFilters.fieldZones) {
    fieldZoneChoices.set(fieldZone[1], fieldZone[0]);
  }

  const previousGainChoices = new Map<Pair, string>();
  for (const gainChoice of customFilters.gainLosses) {
    previousGainChoices.set(gainChoice[1], gainChoice[0]);
  }
  previousGainChoices.set(noGain, 'No Gain');
  previousGainChoices.set(loss, 'Loss');

  // Revert state - allows clearing all filters
  interface RevertState {
    filteredDistances: Pair[],
    filteredDowns: number[],
    filteredFieldZones: Pair[],
    filteredPreviousGains: Pair[],
    filteredPlayNames: string[],
    filteredPlayTypes: PlayType[],
    filteredPlayers: string[],
    filteredFormations: string[],
    filteredTeamNames: string[],
    filteredPossessionGames: string[],
  }
  const [revertState, setRevertState] = useState<RevertState>({
    filteredDistances: [],
    filteredDowns: [],
    filteredFieldZones: [],
    filteredPreviousGains: [],
    filteredPlayNames: [],
    filteredPlayTypes: [],
    filteredPlayers: [],
    filteredFormations: [],
    filteredTeamNames: [],
    filteredPossessionGames: [],
  });

  function revert() {
    setFilteredDistances(revertState.filteredDistances);
    setFilteredDowns(revertState.filteredDowns);
    setFilteredFieldZones(revertState.filteredFieldZones);
    setFilteredPreviousGains(revertState.filteredPreviousGains);
    setFilteredPlayNames(revertState.filteredPlayNames);
    setFilteredPlayTypes(revertState.filteredPlayTypes);
    setFilteredPlayers(revertState.filteredPlayers);
    setFilteredFormations(revertState.filteredFormations);
    setFilteredTeamNames(revertState.filteredTeamNames);
    setFilteredPossessionGames(revertState.filteredPossessionGames);
  }

  // Update all filter states when custom filters & play data changes.
  useEffect(() => {
    const store = Store.copy(dataStoreData);
    const chart = (() => {
      const plays = store.plays(targetedTeamId, baseFilter);
      return new FieldZoneChart(store, plays, plays);
    })();

    setFilteredTeamNames(store.opponentsOf(targetedTeamId));
    setFilteredPlayNames(chart.allPlays.map(a => a[0]));
    setFilteredPlayers(chart.playerTable.map(a => a[0]));
    setFilteredFormations(chart.allFormations.map(a => a[0]));
    setFilteredPossessionGames(chart.possessionGameTable.map(a => a[0]));

    setFilteredDistances(customFilters.distances);
    setFilteredFieldZones(customFilters.fieldZones.map(a => a[1]));
    setFilteredPreviousGains(customFilters.gainLosses.map(a => a[1]).concat([noGain, loss]));

    setRevertState({
      filteredDistances: customFilters.distances,
      filteredDowns,
      filteredFieldZones: customFilters.fieldZones.map(a => a[1]),
      filteredPreviousGains: customFilters.gainLosses.map(a => a[1]).concat([noGain, loss]),
      filteredPlayTypes: filteredPlayTypes,
      filteredPlayers: chart.playerTable.map(a => a[0]),
      filteredPlayNames: chart.allPlays.map(a => a[0]),
      filteredFormations: chart.allFormations.map(a => a[0]),
      filteredTeamNames: store.opponentsOf(targetedTeamId),
      filteredPossessionGames: chart.possessionGameTable.map(a => a[0]),
    });
  }, [dataStoreData, customFilterData, team, targetedTeamId]);

  // Read play data
  const dataStore = Store.copy(dataStoreData);
  const allPlays = dataStore.plays(targetedTeamId, baseFilter);
  const filteredPlays = dataStore.plays(targetedTeamId, play => {
    const offensePlay = play.offensePlay.length > 0 ? play.offensePlay : 'None';
    const offenseFormation = play.offenseFormation.length > 0 ? play.offenseFormation : 'None';
    const noPlayer = isNaN(play.receiverTargeted) && isNaN(play.ballCarrier);
    const possessionValue = dataStore.possessionGame(play);
    const possessionGame = possessionValue === 0
      ? '0' : ((possessionValue || 0) > 0
        ? `+${possessionValue}`
        : `-${possessionValue}`);
    return baseFilter(play)
      && pairVectorToRange(filteredDistances).includes(play.distance)
      && filteredDowns.includes(play.down)
      && pairVectorToRange(filteredFieldZones).includes(play.yardLine)
      && pairVectorToRange(filteredPreviousGains).includes(dataStore.previousPlay(play)?.distance || 0)
      && (play.offensePlay.length < 1 || filteredPlayNames.includes(offensePlay))
      && (play.type === PlayType.Error || filteredPlayTypes.includes(play.type))
      && (noPlayer || filteredPlayers.includes(`${play.receiverTargeted}`) || filteredPlayers.includes(`${play.ballCarrier}`))
      && filteredFormations.includes(offenseFormation)
      && filteredTeamNames.includes(play.opponent)
      && (possessionValue === undefined || filteredPossessionGames.includes(possessionGame));
  });
  const chart = new FieldZoneChart(dataStore, allPlays, filteredPlays);

  // Read values from chart
  const playTable = flatTableToMap(chart.allPlays);
  const allFormations = flatTableToMap(chart.allFormations);
  const possessionGameTable = flatTableToMap(chart.possessionGameTable);

  const playTypeTable = new Map<string, number>();
  chart.playTypeTable.forEach(play => playTypeTable.set(play[0], play[1]));

  const [view, setView] = useState<'fb' | 'lr' | 'sw'>('fb');
  let leftLabel: string,
    rightLabel: string,
    middleLabel: string,
    mainLabel: string,
    mainChange: (_: string[]) => boolean,
    mainSelected: string[],
    middleFormationTable: Map<string, TableValue[]>,
    leftFormationTable: Map<string, TableValue[]>,
    rightFormationTable: Map<string, TableValue[]>;

  const p = viewBy === 'plays';

  if (view === 'lr') {
    leftLabel = 'Left';
    rightLabel = 'Right';
    middleLabel = 'Middle';
    middleFormationTable = p ? flatTableToMap(chart.middlePlays) : flatTableToMap(chart.middleFormations);
    leftFormationTable = p ? flatTableToMap(chart.leftPlays) : flatTableToMap(chart.leftFormations);
    rightFormationTable = p ? flatTableToMap(chart.rightPlays) : flatTableToMap(chart.rightFormations);
    mainLabel = p ? 'Play' : 'Formation';
    mainSelected = p ? filteredPlayNames : filteredFormations;
    mainChange = p ? mapSet(setFilteredPlayNames) : mapSet(setFilteredFormations);
  } else if (view === 'sw') {
    leftLabel = 'Strong';
    rightLabel = 'Weak';
    middleLabel = p ? 'All Formations' : 'All Plays';
    middleFormationTable = p ? playTable : allFormations;
    leftFormationTable = p ? flatTableToMap(chart.strongPlays) : flatTableToMap(chart.strongFormations);
    rightFormationTable = p ? flatTableToMap(chart.weakPlays) : flatTableToMap(chart.weakFormations);
    mainLabel = p ? 'Play' : 'Formation';
    mainSelected = p ? filteredPlayNames : filteredFormations;
    mainChange = p ? mapSet(setFilteredPlayNames) : mapSet(setFilteredFormations);
  } else {
    leftLabel = 'Field';
    rightLabel = 'Boundary';
    middleLabel = p ? 'All Formations' : 'All Plays';
    middleFormationTable = p ? playTable : allFormations;
    leftFormationTable = p ? flatTableToMap(chart.fieldPlays) : flatTableToMap(chart.fieldFormations);
    rightFormationTable = p ? flatTableToMap(chart.boundaryPlays) : flatTableToMap(chart.boundaryFormations);
    mainLabel = p ? 'Play' : 'Formation';
    mainSelected = p ? filteredPlayNames : filteredFormations;
    mainChange = p ? mapSet(setFilteredPlayNames) : mapSet(setFilteredFormations);
  }

  const fieldZoneOptions = (() => {
    function clickFieldZone(zone: Pair) {
      const newFieldZones = filteredFieldZones.indexOf(zone) > -1
        ? filteredFieldZones.filter(theZone => theZone !== zone)
        : filteredFieldZones.concat([zone]);
      let yardLines = [];
      for (let fieldZone of newFieldZones) {
        for (let j = fieldZone[0]; j <= fieldZone[1]; j++) {
          yardLines.push(j);
        }
      }
      setFilteredFieldZones(newFieldZones);
      return true;
    }

    return Array.from(fieldZoneChoices.entries()).map(pair => <FieldButton key={`${pair[0].join('-')}`} isSelected={filteredFieldZones.indexOf(pair[0]) >= 0} onClick={() => clickFieldZone(pair[0])}>{pair[1]}</FieldButton>);
  })();

  // Flip what is visible in the left table based on viewBy variable.
  const playTableLabel = viewBy === 'plays' ? 'ALL FORMATIONS' : 'ALL PLAYS';
  const playTableTable = viewBy === 'plays' ? allFormations : playTable;
  const playTableSelected = viewBy === 'plays' ? filteredFormations : filteredPlayNames;
  const playTableChange = viewBy === 'plays' ? mapSet(setFilteredFormations) : mapSet(setFilteredPlayNames);

  function PlayerTable() {
    function onClick(player: string) {
      setFilteredPlayers([player]);
    }
    function selectAll() {
      setFilteredPlayers(chart.playerTable.map(a => a[0]));
    }
    const [hoveredRow, setHoveredRow] = useState<string>('');
    const magnitude = chart.playerTable.reduce((a, row) => Math.max(a, Math.max(row[1], row[2])), 0);
    const rows = chart.playerTable
      .sort((a, b) => Math.max(b[1], b[2]) - Math.max(a[1], a[2]))
      .reduce<JSX.Element[]>((a, row) => a.concat([
        <div className={`col-span-1 p-2 ${hoveredRow === row[0] ? 'bg-gray-900' : ''}`} onClick={() => onClick(row[0])} onMouseEnter={() => setHoveredRow(row[0])} onMouseLeave={() => setHoveredRow('')}>
          <span className="text-primary-500 mr-2">
            {filteredPlayers.indexOf(row[0]) >= 0
              ? <FontAwesomeIcon icon={['fas', 'check-circle']} />
              : <FontAwesomeIcon icon={['far', 'circle']} />}
          </span>
        </div >,
        <div className={`col-span-3 p-2 ${hoveredRow === row[0] ? 'bg-gray-900' : ''}`} onClick={() => onClick(row[0])} onMouseEnter={() => setHoveredRow(row[0])} onMouseLeave={() => setHoveredRow('')}>
          {row[0]}
        </div>,
        <div onClick={() => onClick(row[0])} style={{ color: `hsl(${team.primaryHue}, 100%, ${100 - (row[1] / magnitude * 50)}%)` }} className={`col-span-4 p-2 text-right ${hoveredRow === row[0] ? 'bg-gray-900' : ''}`} onMouseEnter={() => setHoveredRow(row[0])} onMouseLeave={() => setHoveredRow('')}>{row[1]}</div>,
        <div onClick={() => onClick(row[0])} style={{ color: `hsl(${team.primaryHue}, 100%, ${100 - (row[2] / magnitude * 50)}%)` }} className={`col-span-4 p-2 text-right ${hoveredRow === row[0] ? 'bg-gray-900' : ''}`} onMouseEnter={() => setHoveredRow(row[0])} onMouseLeave={() => setHoveredRow('')}>{row[2]}</div>,
      ]), []);
    return (<div>
      <div className="min-w-full min-h-full overflow-hidden grid grid-cols-12 uppercase text-gray-500 pb-2">
        <div className="col-span-1 p-2" onClick={selectAll}><i className="fas fa-check-circle"></i></div>
        <div className="col-span-3 p-2">Player</div>
        <div className="col-span-4 p-2 text-right">Carries</div>
        <div className="col-span-4 p-2 text-right">Targets</div>
      </div>
      <div className="grid grid-cols-12 border-t border-solid border-gray-900 ux-h cursor-pointer">
        {rows}
      </div>

    </div>);
  }
  const playerTable = <PlayerTable />;

  const opponentChoices = new Map<string, string>();
  dataStore.opponentsOf(targetedTeamId).forEach(opponent => opponentChoices.set(opponent, opponent));

  interface CenterTableProps {
    children: React.ReactNode,
    label: string,
  }
  function CenterTable(props: CenterTableProps) {
    return (<li>
      <div className="flex flex-col h-56 bg-gray-800 bg-opacity-75 hover:bg-opacity-100 rounded-lg shadow py-5">
        <h3 className="text-lg leading-6 font-medium text-gray-100 px-2 text-center">
          {props.label}
        </h3>
        {props.children}
      </div>
    </li>);
  }

  const base = 'mt-5 w-full items-center px-6 py-3 text-sm leading-5 font-sm rounded-md text-white transition ease-in-out duration-150 hover:text-white focus:outline-none';
  const inactiveButton = 'border border-gray-700 rounded-full hover:border-gray-600 focus:shadow-outline-blue focus:border-gray-600';
  const activeButton = 'bg-gray-600 rounded-full focus:shadow-outline-primary focus:border-gray-700 active:bg-gray-700';

  if (!user) {
    return <LoggedOut />;
  }

  return (<ShellWithSideBar teamId={targetedTeamId}>
    <div className="max-1600 mx-auto">
      {!sideMenuOpen &&
        <button onClick={() => setSideMenuOpen(true)} className="filter-button z-20 px-4 py-3 mx-2 md:mx-4 lg:mx-6 rounded-full bg-primary-600">
          <i className="fa fa-cog text-xl"></i>
          <span className="filter-field-text ml-2">Filter Scouting Data</span>
        </button>
      }
      <button onClick={revert} className="revert-button z-20 px-4 py-3 mx-2 md:mx-4 lg:mx-6 rounded-full bg-red-600">
        <i className="fa fa-sync-alt text-xl"></i>
        <span className="revert-text ml-2">Revert Filters</span>
      </button>
      {!sideMenuOpen &&
        <button onClick={() => setField3D(!field3D)} className="perspective-button z-20 px-4 py-3 mx-2 md:mx-4 lg:mx-6 rounded-full bg-secondary-600">
          {field3D
            ? <i className="fa fa-square text-xl"></i>
            : <i className="fa fa-cube text-xl"></i>
          }
          <span className="perspective-text ml-2">{field3D ? '3D' : '2D'} View</span>
        </button>
      }
      <ul className="dark-bg shadow-inner grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 px-6 gap-6 text-left mb-6">
        <li className="col-span-5 flex mt-5">
          <FootballField threeD={field3D}>
            <Block>
              <div className="py-5">
                <ul className="grid grid-cols-3 gap-6">
                  <li className="col-span-3 flex">
                    <ul tabIndex={-1} role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" className="flex justify-center w-full py-1 leading-6 focus:outline-none sm:leading-5">
                      {fieldZoneOptions}
                    </ul>
                  </li>
                  <CenterTable label={leftLabel}>
                    <Table data={leftFormationTable} initialSort={[1, 'desc']} selected={mainSelected} onChange={mainChange} titles={[mainLabel, 'ATT', 'Call %']} />
                  </CenterTable>
                  <CenterTable label={middleLabel}>
                    <Table data={middleFormationTable} initialSort={[1, 'desc']} selected={mainSelected} onChange={mainChange} titles={[mainLabel, 'ATT', 'Call %']} />
                  </CenterTable>
                  <CenterTable label={rightLabel}>
                    <Table data={rightFormationTable} initialSort={[1, 'desc']} selected={mainSelected} onChange={mainChange} titles={[mainLabel, 'ATT', 'Call %']} />
                  </CenterTable>
                  <li className="col-span-3 flex">
                    <FieldButton key="fb" onClick={() => setView('fb')} isSelected={view === 'fb'}>Field/Boundary</FieldButton>
                    <FieldButton key="lr" onClick={() => setView('lr')} isSelected={view === 'lr'}>Hash</FieldButton>
                    <FieldButton key="sw" onClick={() => setView('sw')} isSelected={view === 'sw'}>Strong/Weak</FieldButton>
                  </li>
                </ul>
              </div>
            </Block>
          </FootballField>
        </li>
      </ul>
      <ul className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 px-6 gap-6 max-w-screen-xl mx-auto">
        <GridCellSplit
          label='Avg. Gain' value={`${chart.averageGain.toFixed(1)}`}
          label2='Conversion Rate' value2={`${(chart.conversionRate * 100).toFixed(0)}%`}
        />
        <DataCell label="Total Plays" value={`${chart.totalPlays}`} />
        {/* <DataCell label="Avg. Gain" value={`${chart.averageGain.toFixed(1)}`}></DataCell>
        <DataCell label="Conversion Rate" value={`${(chart.conversionRate * 100).toFixed(0)}%`}></DataCell> */}
        <li>
          <GridCell2Responsive
            values={[PlayType.Pass, PlayType.Run]}
            selected={filteredPlayTypes}
            onChange={setFilteredPlayTypes}
            label={PlayType.Run} value={chart.playTypeTable[0][1] || 0}
            label2={PlayType.Pass} value2={chart.playTypeTable[1][1] || 0}
            total={chart.playTypeTable[0][1] + chart.playTypeTable[1][1]} />
        </li>
        <GridCell padding={0} height={56} className="flex flex-col">
          <Table data={playTableTable} initialSort={[1, 'desc']} selected={playTableSelected} onChange={playTableChange} titles={[playTableLabel, 'ATT', 'Call %']} />
        </GridCell>
        <GridCell height={56} padding={3}>
          {playerTable}
        </GridCell>
        <GridCell padding={0} height={56} className="flex flex-col">
          <Table data={possessionGameTable} initialSort={[1, 'desc']} selected={filteredPossessionGames.map(x => `${x}`)} onChange={mapSet(setFilteredPossessionGames)} titles={['Poss. Game', 'ATT', '%']} />
        </GridCell>
      </ul>
      {sideMenuOpen &&
        <div className="filter-slideout bg-gray-900 border-l border-gray-800 z-20 text-left shadow-xl">
          <div className="overflow-y-scroll max-h-screen pb-56 pt-12">
            <button onClick={() => setSideMenuOpen(false)} className="float-right m-2">
              <i className="fa fa-times text-2xl"></i>
            </button>
            <button type="button" onClick={revert} className="items-center px-6 py-3 text-sm leading-5 font-sm rounded-md text-white bg-red-600 rounded-full hover:text-white hover:bg-red-700 focus:outline-none focus:shadow-outline-blue focus:border-secondary-600 active:bg-secondary-600 active:text-white transition ease-in-out duration-150">
              <i className="fas fa-sync h-3 w-3"></i>
              <span className="ml-2">Revert Filters</span>
            </button>
            <li className="col-span-1 flex">
              <button type="button" onClick={() => setViewBy('plays')} className={`${viewBy === 'plays' ? activeButton : inactiveButton} ${base}`}>
                <i className="fas fa-clipboard-list h-3 w-3"></i>
                <span className="ml-2">View By Plays</span>
              </button>
            </li>
            <li className="col-span-1 flex">
              <button type="button" onClick={() => setViewBy('formations')} className={`${viewBy === 'formations' ? activeButton : inactiveButton} ${base}`}>
                <i className="fas fa-ellipsis-h h-3 w-3"></i>
                <span className="ml-2">View By Formation</span>
              </button>
            </li>
            <MapSelect display="block" label="Opponent" choices={opponentChoices} selected={filteredTeamNames} onChange={mapSet(setFilteredTeamNames)} />
            <MapSelect display="inline" label="Down" choices={downChoices} selected={filteredDowns} onChange={mapSet(setFilteredDowns)} />
            <MapSelect display="inline" label="Distance (Yds)" choices={distanceChoices} selected={filteredDistances} onChange={mapSet(setFilteredDistances)} />
            <MapSelect display="block-open" label="Previous Play" choices={previousGainChoices} selected={filteredPreviousGains} onChange={mapSet(setFilteredPreviousGains)} />
          </div>
        </div>
      }
    </div>


  </ShellWithSideBar >);
}

interface FieldButtonProps {
  children: React.ReactNode;
  isSelected: boolean;
  key?: string;
  onClick: () => void,
}
function FieldButton(props: FieldButtonProps) {
  const colorClass = props.isSelected ? 'text-primary-400 bg-gray-800 border-solid border-2 border-gray-600' : 'bg-gray-800 text-gray-600';
  return (<button key={props.key} onClick={props.onClick} className={`${colorClass} bg-opacity-75 w-full mx-1 rounded-md cursor-pointer text-gray-100 cursor-default select-none relative p-2 hover:text-gray-100`} role="option">
    {props.children}
  </button>);
}
