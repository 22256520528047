export class Strength {
  public id: string;

  constructor(id: string) {
    this.id = id.replace('Left', 'L').replace('Right', 'R').replace('Balanced', 'BAL');
  }

  get isStrong() {
    return ['PassL1', 'PassL3', 'PassL4', 'PassL8', 'PassR10', 'PassR2', 'PassR6', 'PassR7', 'RunL1', 'RunL3', 'RunL5', 'RunL7', 'RunR0', 'RunR2', 'RunR4', 'RunR6'].indexOf(this.id) >= 0;
  }

  get isMiddleStrength() {
    return ['PassL5', 'PassL9', 'PassR5', 'PassR9'].indexOf(this.id) >= 0;
  }

  get isWeak() {
    return ['PassL1', 'PassL3', 'PassL4', 'PassL8', 'PassR10', 'PassR2', 'PassR6', 'PassR7', 'PassL5', 'PassL9', 'PassR5', 'PassR9', 'RunL1', 'RunL3', 'RunL5', 'RunL7', 'RunR0', 'RunR2', 'RunR4', 'RunR6'].indexOf(this.id) < 0;
  }

  get isField() {
    return ['PassL10', 'PassL2', 'PassL6', 'PassL7', 'PassR1', 'PassR3', 'PassR4', 'PassR8'].indexOf(this.id) >= 0;
  }

  get isMiddleFB() {
    return ['PassL5', 'PassL9', 'PassR5', 'PassR9'].indexOf(this.id) >= 0;
  }

  get isBoundary() {
    return ['PassL10', 'PassL2', 'PassL6', 'PassL7', 'PassR1', 'PassR3', 'PassR4', 'PassR8', 'PassL5', 'PassL9', 'PassR5', 'PassR9'].indexOf(this.id) < 0;
  }
}
