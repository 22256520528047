export class Account {
  constructor(
    public firstName: string,
    public lastName: string,
    public email: string,
    public photoUrl: string,
    public location: string, 
    public position: string
  ) {}
  
  static default(): Account {
    return new Account('','','','','','');
  }
}