import { BehaviorSubject } from "rxjs";
import * as LZString from 'lz-string';

const pipes = new Map<string, BehaviorSubject<any>>();

// RK: Proposed storage system update
// 1. Remove apiMethod from storage signature
// 2. Create OnlineOperation interface
// 3. Add retriever (_?: CachePolicy) => T to storage signature
// 4. Add update(policy: CachePolicy) => void to storage interface, which is an OnlineOperation
// 5. Add checks to see if localStorage is supported
// 6. Roll out to system

export class Storage<T> {
  constructor(
    public apiMethod: string,
    public localStorageKey: string,
    public defaultValue: T,
    public compressed: boolean
  ) {
    if (!pipes.has(this.localStorageKey)) {
      pipes.set(this.localStorageKey, new BehaviorSubject<T>(this.defaultValue));
      // Try to get any value out of localStorage
      try {
        const item = localStorage.getItem(this.localStorageKey);
        if (item) {
          const parsed = this.compressed
            ? JSON.parse(LZString.decompress(item) || 'null')
            : JSON.parse(item);
          if (!parsed) {
            throw new Error('could not parse');
          }
          const pipe = pipes.get(this.localStorageKey);
          if (pipe) {
            pipe.next(parsed);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  save(value: T) {
    const pipe = pipes.get(this.localStorageKey);
    if (pipe) {
      pipe.next(value);
      try {
        localStorage.setItem(
          this.localStorageKey,
          this.compressed
            ? LZString.compress(JSON.stringify(value))
            : JSON.stringify(value)
        );
      } catch (error) {
        console.log(error);
      }
    }
  }

  subscribe(setState: (a: T) => void) {
    const pipe = pipes.get(this.localStorageKey);
    if (pipe) {
      return pipe.asObservable().subscribe(setState);
    }
  }
}

