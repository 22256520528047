import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Heading } from '../components/heading';
import { Shell } from '../components/shell';
import { Block } from '../components/block';
import { SearchBar } from '../components/search';
import { Link, useHistory } from 'react-router-dom';
import { opponentsStorage, Team as Opponent } from '../hooks/my-opponents-hook';
import { states as stateList, statesStorage } from '../hooks/states';
import { GridCell } from '../components/grid-cell';
import { Game } from '../logic/team';
import { dataStorage, Store } from '../logic/data-v2';
import firebase from "firebase/app";
import "firebase/auth";
import { LoggedOut } from './logged-out';
import { dataService } from "../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Status = 'W' | 'L' | 'T' | '?';

export function SchedulePage() {
  const [user, setUser] = useState<boolean>(false);
  const [jwt, setJwt] = useState<string>('');
  const [dataHook, setDataHook] = useState<Store>();
  const [opponents, setOpponents] = useState<Opponent[]>([]);
  const [search, setSearch] = useState<string>('');
  const [states, setStates] = useState<string[]>(stateList());
  const [viewMode, setViewMode] = useState<'grid' | 'table'>('grid');
  const history = useHistory();

  useLayoutEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      setUser(user !== null);
      if (user) {
        user.getIdToken().then(setJwt);
      }
      const l = [opponentsStorage.subscribe(setOpponents), statesStorage.subscribe(setStates), dataStorage.subscribe(setDataHook)];
      return () => l.forEach(l => l?.unsubscribe());
    });
  });

  // upgrade teams to games instead of old gamedates
  useEffect(() => {
    let upgraded = true;
    opponents.map((opp) => { upgraded = opp.games ? upgraded && true : false });
    if (!upgraded) { // shouldn't trigger unless there's at least one team
      const upgradedOpponents = opponents.map((opp: any) => {
        if (!opp.games) {
          const newOpponent: Opponent = new Opponent(
            opp.id,
            opp.name,
            opp.mascot,
            opp.logo,
            opp.city,
            opp.state,
            opp.primaryHue,
            opp.secondaryHue,
            opp.accentHue,
            opp.gameDates ? opp.gameDates.map((gd: Date) => ({ date: new Date(gd), status: '?' } as Game)) : []
          );
          return newOpponent;
        } else {
          return opp;
        }
      });
      opponentsStorage.save(upgradedOpponents);
    }
  }, [opponents]);

  interface Entry { opponentIndex: number, logoURI: string; teamId: number, teamName: string, date: Date, status: Status }
  const entries = opponents.reduce<Entry[]>((a, team, i) => {
    const games = team.games.length > 0
      ? team.games.map(game => game)
      : [{ date: new Date(2000, 1, 1), status: '?' } as Game];
    return a.concat(games.map(game => ({
      opponentIndex: i,
      teamId: team.id,
      teamName: team.name,
      date: game.date,
      status: game.status,
      logoURI: team.logo,
    })));
  }, [])
    .filter(entry => (!search) || entry.teamName.toLowerCase().indexOf(search.toLowerCase()) >= 0);

  entries.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  function edit(entry: Entry) {
    history.push(`/opponent/${entry.teamId}`);
  }

  async function SetStatus(opponentIndex: number, date: Date, status: Status) {
    const newOpponents = Array.from(opponents);
    newOpponents[opponentIndex].games = newOpponents[opponentIndex].games.map((game) => ({
      date: game.date,
      status: game.date === date ? status : game.status
    }));
    opponentsStorage.save(newOpponents);
    await fetch(`${dataService}/teams/game-status`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${jwt}`
      },
      body: JSON.stringify({
        team_id: opponents[opponentIndex].id,
        date,
        status,
      })
    });
  }

  const opponentsWithData = (!dataHook)
    ? []
    : dataHook.games.reduce<string[]>((arr, game) => {
      if (arr.indexOf(game.teamId) === -1) {
        return arr.concat([game.teamId]);
      }
      if (arr.indexOf(game.opponentName) === -1) {
        return arr.concat([game.opponentName]);
      }
      return arr;
    }, []);

  const scheduleEntries = viewMode === 'grid'
    ? (<ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 p-8 max-w-6xl mx-auto">
      {entries.map((entry, i) => <OpponentGridCell key={i} edit={() => edit(entry)} name={entry.teamName} teamId={entry.teamId} logoURI={entry.logoURI} date={new Date(entry.date)} status={entry.status} setOutsideStatus={(status: Status) => SetStatus(entry.opponentIndex, entry.date, status)} scoutable={opponentsWithData?.includes(`${entry.teamId}`)} />)}
    </ul>)
    : (<ul className="grid grid-cols-1 p-8 max-w-6xl mx-auto" >
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border border-gray-800 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-800">
                <thead className="border-b border-gray-800">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                      Opponent Name
                </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                      Game Date
                </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                      Status
                </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit or Scout</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-800 text-left">
                  {entries.map((entry, i) => <OpponentTableRow key={i} edit={() => edit(entry)} name={entry.teamName} logoURI={entry.logoURI} teamId={entry.teamId} date={entry.date} status={entry.status} scoutable={opponentsWithData?.includes(entry.teamName)} />)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ul >);

  if (!user) {
    return <LoggedOut />;
  }

  return <Shell>
    <Heading>
      Game Schedule
      <p className="text-gray-600 mb-6 text-sm font-normal">Add opponents to your schedule and upload data to populate your scouting dashboards for that opponent.</p>
    </Heading>
    <div className="max-w-6xl mx-auto p-4">
      <div className="mt-1 flex">
        <SearchBar onChange={newSearch => setSearch(newSearch)} />
        <span className="inline-flex rounded-md shadow-sm ml-2">
          <button onClick={() => setViewMode('grid')} type="button" className="inline-flex items-center px-4 py-2 border border-gray-700 text-sm leading-5 font-medium rounded-md text-gray-400 bg-gray-800 hover:text-primary-400 hover:bg-gray-950 focus:outline-none focus:ring-blue focus:ring-opacity-50 focus:border-primary-300 active:bg-gray-100 active:text-gray-400 transition ease-in-out duration-150">
            <i className="fas fa-th-large h-5 w-5" />
          </button>
        </span>
        <span className="inline-flex rounded-md shadow-sm ml-2">
          <button onClick={() => setViewMode('table')} type="button" className="inline-flex items-center px-4 py-2 border border-gray-700 text-sm leading-5 font-medium rounded-md text-gray-400 bg-gray-800 hover:text-primary-400 hover:bg-gray-950 focus:outline-none focus:ring-blue focus:ring-opacity-50 focus:border-primary-300 active:bg-gray-100 active:text-gray-400 transition ease-in-out duration-150">
            <i className="fas fa-list h-5 w-5" />
          </button>
        </span>
        <span className="inline-flex rounded-md shadow-sm ml-2">
          <Link to="/opponent/new">
            <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:border-primary-600 focus:ring-blue focus:ring-opacity-50 active:bg-primary-600 transition ease-in-out duration-150">
              <FontAwesomeIcon icon={['fas', 'plus']} className="-ml-1 mr-2 h-5 w-5" />
              Opponent
            </button>
          </Link>
        </span>
      </div>
    </div>
    <Block>
      {scheduleEntries}
    </Block>
  </Shell>;
}

interface OpponentViewProps {
  edit: () => void,
  teamId: number,
  name: string,
  date: Date,
  status: Status,
  logoURI: string,
  scoutable?: boolean,
  setOutsideStatus?: (status: Status) => void
}

function OpponentGridCell(props: OpponentViewProps) {
  const history = useHistory();
  const statusClasses = new Map<Status, string>();
  statusClasses.set('W', 'px-2 py-1 text-primary-700 text-xs leading-4 font-medium bg-primary-100 rounded-full m-1.5 cursor-pointer transform hover:scale-105');
  statusClasses.set('L', 'px-2 py-1 text-red-700 text-xs leading-4 font-medium bg-red-100 rounded-full m-1.5 cursor-pointer transform hover:scale-105');
  statusClasses.set('T', 'px-2 py-1 text-secondary-700 text-xs leading-4 font-medium bg-accent-100 rounded-full m-1.5 cursor-pointer transform hover:scale-105')
  statusClasses.set('?', 'px-2 py-1 text-primary-700 text-xs leading-4 font-medium bg-gray-100 rounded-full m-1.5 cursor-pointer transform hover:scale-105');

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [logoURI, setLogoURI] = useState('https://storage.googleapis.com/sportsiq/static/football-helmet-icon.png');

  useEffect(() => {
    const image = new Image();
    image.src = props.logoURI;
    image.onload = () => {
      setLogoURI(props.logoURI);
    }
  }, []);

  function WLTDropdown() {
    return (
      <div>
        {Array.from(statusClasses.keys()).map((key) => <span
          onClick={() => {
            setDropdownOpen(false);
            if (props.setOutsideStatus) {
              props.setOutsideStatus(key);
            }
          }}
          className={statusClasses.get(key)}>{key === '?' ? 'TBD' : key}</span>)}
      </div>
    )
  }

  return (<GridCell padding={0} primaryIcon={['fas', 'binoculars']} primaryText="Scout" primaryClick={() => history.push(`/pass-zone-chart?team=${props.teamId}`)} secondaryIcon={['fas', 'pencil-alt']} secondaryText="Edit Opp." secondaryClick={props.edit} primaryBlocked={!props.scoutable}>
    <div className="flex-1 flex flex-col p-8">
      <img className="w-32 h-32 flex-shrink-0 mx-auto bg-gray-800 rounded-full" src={logoURI} alt="" />
      <h3 className="mt-6 text-gray-100 text-sm leading-5 font-medium">{props.name}</h3>
      <dl className="mt-1 flex-grow flex flex-col justify-between">
        <dt className="sr-only">{props.name}</dt>
        {props.date.getFullYear() < 2018
          ? [
            <dd key="1" className="text-gray-500 text-sm leading-5">No Dates</dd>,
            <dt key="2" className="sr-only">No Dates</dt>,
          ]
          : [
            <dd key="1" className="text-gray-500 text-sm leading-5">{props.date.toLocaleDateString()}</dd>,
            <dt key="2" className="sr-only">{props.date.toLocaleDateString()}</dt>,
          ]}
        <dd className="mt-3">
          {props.date.getFullYear() < 2018 ?
            <span className='px-2 py-1 text-primary-700 text-xs leading-4 font-medium'>{' '}</span>
            :
            dropdownOpen ?
              WLTDropdown()
              : <span onClick={() => setDropdownOpen(true)} className={statusClasses.get(props.status)}>{props.status == '?' ? 'TBD' : props.status}</span>
          }
        </dd>
      </dl>
    </div>
  </GridCell>);
}

function OpponentTableRow(props: OpponentViewProps) {

  // const teamHash = CRC32.str(props.name) + 2147483648;
  const statusClasses = new Map<Status, string>();
  statusClasses.set('W', 'px-2 py-1 text-primary-700 text-xs leading-4 font-medium bg-primary-100 rounded-full m-1.5 cursor-pointer transform hover:scale-105');
  statusClasses.set('L', 'px-2 py-1 text-red-700 text-xs leading-4 font-medium bg-red-100 rounded-full m-1.5 cursor-pointer transform hover:scale-105');
  statusClasses.set('T', 'px-2 py-1 text-secondary-700 text-xs leading-4 font-medium bg-accent-100 rounded-full m-1.5 cursor-pointer transform hover:scale-105')
  statusClasses.set('?', 'px-2 py-1 text-primary-700 text-xs leading-4 font-medium bg-gray-100 rounded-full m-1.5 cursor-pointer transform hover:scale-105');

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [logoURI, setLogoURI] = useState('https://storage.googleapis.com/sportsiq/static/football-helmet-icon.png');

  useEffect(() => {
    const image = new Image();
    image.src = props.logoURI;
    image.onload = () => {
      setLogoURI(props.logoURI);
    }
  }, []);

  function WLTDropdown() {
    return (
      <div>
        {Array.from(statusClasses.keys()).map((key) => <span
          onClick={() => {
            setDropdownOpen(false);
            if (props.setOutsideStatus) {
              props.setOutsideStatus(key);
            }
          }}
          className={statusClasses.get(key)}>{key}</span>)}
      </div>
    )
  }

  return (

    <tr>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            <img className="h-10 w-10 flex-shrink-0 bg-gray-900 rounded-full" src={logoURI} alt="" />
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-200">
              {props.name}
            </div>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-500">{new Date(props.date).getFullYear() < 2018 ? 'No Dates' : new Date(props.date).toLocaleDateString()}</div>
      </td>
      <td className="px-6 py-4 w-24 whitespace-nowrap">
        {new Date(props.date).getFullYear() < 2018 ?
          <span className='px-2 py-1 text-primary-700 text-xs leading-4 font-medium'>{' '}</span>
          :
          dropdownOpen ?
            WLTDropdown()
            : <span onClick={() => setDropdownOpen(true)} className={statusClasses.get(props.status)}>{props.status}</span>
        }
      </td>
      <td className="text-right pr-6 text-white text-sm">
        <span onClick={props.edit} className="mx-4 cursor-pointer hover:text-primary-500">
          <i className="fa fa-pencil-alt mr-2" />
          Edit
          </span>
        <Link to={`/pass-zone-chart?team=${props.teamId}`}><span className="hover:text-primary-500">
          <i className="fa fa-binoculars mr-2" />
          Scout
          </span></Link>
      </td>
    </tr>
  );
}
