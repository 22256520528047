import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import firebase from "firebase/app";
import "firebase/auth";
import { FirebaseToken } from "./firebase-token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Profile, profileStorage} from "../hooks/profile-storage";

export function UserMenu() {
  const [avatar, setAvatar] = useState('');
  const [jwt, setJwt] = useState('');
  const [isOpen, setOpen] = useState<boolean>();
  const [profile, setProfile] = useState(Profile.default());
  const history = useHistory();

  function logout() {
    firebase.auth()
      .signOut()
      .finally(() => {
        history.push('/');
      });
  }

  useEffect(() => {
    setAvatar(profile.avatar);
  }, [profile]);

  useEffect(() => {
    const l = [profileStorage.subscribe(profiles => setProfile(profiles.get('') as Profile))];
    return () => l.forEach(s => s?.unsubscribe());
  }, []);

  useEffect(() => {
    profileStorage.authenticate(jwt);
  }, [jwt]);

  // Close menu on body click
  useEffect(() => {
    const handler = (e: MouseEvent) => {
      const target = e.target as Element;
      if (!target.closest('.ux-user-menu')) {
        setOpen(false);
      }
    };
    if (isOpen) {
      document.body.addEventListener('click', handler);
    }
    return () => {
      document.body.removeEventListener('click', handler);
    }
  }, [isOpen]);

  return (<div className="mx-2 sm:mx-6 relative ux-user-menu z-40">
    <FirebaseToken onChange={setJwt} />
    <div onClick={() => setOpen(!isOpen)}>
      <button className="max-w-xs flex items-center text-center text-sm rounded-full focus:outline-none focus:shadow-outline bg-primary-600 rounded-full h-8 w-8" id="user-menu" aria-label="User menu" aria-haspopup="true">
        <div className="text-center w-full">
          {avatar
            ? <img className="rounded-full" src={avatar} alt="avatar" />
            : <FontAwesomeIcon icon={['fas', 'user']} />}
        </div>
      </button>
    </div>
    {(!isOpen) ? null : <div className="origin-top-right absolute right-0 mt-3 w-48 rounded-md shadow-lg">
      <div className="py-1 rounded-md bg-gray-800 shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
        <Link to="/team" className="block px-4 py-3 text-sm text-gray-400 hover:bg-gray-900 transition ease-in-out duration-150" role="menuitem">My Team Profile</Link>
        <Link to="/profile" className="block px-4 py-3 text-sm text-gray-400 hover:bg-gray-900 transition ease-in-out duration-150" role="menuitem">Account Settings</Link>
        <a onClick={() => logout()} className="block px-4 py-3 text-sm text-gray-400 hover:bg-gray-900 transition ease-in-out duration-150 cursor-pointer" role="menuitem">Sign out</a>
      </div>
    </div>}
  </div>);
}
