import React, { useEffect, useState } from 'react';

interface DatePickerProps {
  date: Date,
  onChange: (_: Date) => void,
}

interface Month {
  month: number,
  year: number,
  dates: (Date | null)[],
}

export function DatePicker(props: DatePickerProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [lastClosed, setLastClosed] = useState<Date>(new Date(2000, 1, 1));
  const ref = React.createRef<HTMLDivElement>();

  const { date } = props;
  const firstDay = new Date(date.getTime());
  firstDay.setDate(-365);

  const months = new Map<string, Month>();

  for (let i = 0; i < 730; i++) {
    const nextDay = new Date(firstDay.getTime());
    nextDay.setDate(firstDay.getDate() + i);
    const key = `${nextDay.getFullYear()}-${nextDay.getMonth()}`;
    const month = months.get(key);
    if (month) {
      month.dates.push(nextDay);
    } else {
      const dates: (Date | null)[] = [nextDay];
      for (let j = 0; j < nextDay.getDay(); j++) {
        dates.unshift(null);
      }
      months.set(key, {
        month: nextDay.getMonth(),
        year: nextDay.getFullYear(),
        dates,
      });
    }
  }

  const monthStrings = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  useEffect(() => {
    if (!isOpen) {
      setLastClosed(new Date());
    } else if ((new Date()).getTime() - lastClosed.getTime() < 500) {
      setIsOpen(false);
    } else if (ref.current) {
      ref.current.scrollTo({ top: ref.current.scrollHeight * 0.495 });
    }
  }, [isOpen]);

  function change(d: Date) {
    props.onChange(d);
    setIsOpen(false);
  }

  return <div className="relative">
    <input className="form-input block w-full rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900 border-gray-700" type="text" value={`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`} readOnly={true} onFocus={() => setIsOpen(true)} />
    {isOpen ? <div style={{ width: '400px' }} className="absolute top-1 left-1 bg-gray-800 rounded-md z-50">
      <div key={date.toString()} className="grid grid-cols-7 px-4 py-2">
        <div>Sun</div>
        <div>Mon</div>
        <div>Tue</div>
        <div>Wed</div>
        <div>Thu</div>
        <div>Fri</div>
        <div>Sat</div>
      </div>
      <div style={{ height: '250px' }} className="h-96 overflow-y-auto" ref={ref}>
        {Array.from(months.values()).map(month => <div className="px-4">
          <div className="mt-4 mb-2">{monthStrings[month.month]} {month.year}</div>
          <div className="grid grid-cols-7 border-b border-gray-700 mb-2 pb-4">
            {month.dates.map(theDate => theDate ? <div
              onClick={() => change(theDate)}
              className={date.getFullYear() === theDate.getFullYear() && date.getMonth() === theDate.getMonth() && date.getDate() === theDate.getDate() ? 'text-primary-500' : ''}>
              {theDate.getDate()}
            </div> : <div>{'\xa0'}</div>)}
          </div>
        </div>)}
      </div>
    </div> : null}
  </div>;
}