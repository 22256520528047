import React, { useLayoutEffect, useState, useEffect } from 'react';
import { Block } from '../components/block';
import { FootballField } from '../components/football-field';
import { GridCell, GridCell2Responsive, GridCellSplit, GridCellSplit3 } from '../components/grid-cell';
import { ShellWithSideBar } from '../components/shell';
import { RunZoneChart } from '../logic/run-zone-chart';
import { customFiltersStorage } from '../hooks/custom-filters';
import { CustomFilters, Pair } from '../logic/custom-filters';
import { MapSelect } from '../components/map-select-v2';
import { defaultTeam, Team, teamStorage } from '../hooks/my-team-hook';
import { useHistory } from 'react-router-dom';
import { Store, Hash, dataStorage, ODK, Play, PlayResult, PlayType, RunGap, Tendency } from '../logic/data-v2';
import { Table } from '../components/table';
import firebase from "firebase/app";
import "firebase/auth";
import { LoggedOut } from './logged-out';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// RK: This is necessary so that pointer problems don't happen during re-render
const noGain: Pair = [0, 0];
const loss: Pair = [-100, -1];

function baseFilter(play: Play) {
  return play.odk === ODK.Offense
    && play.type === PlayType.Run
    && play.result !== PlayResult.Penalty;
}

// This function turns a setState function into a MapSelect shouldChange function
function mapSet<T>(setState: (_: T[]) => void) {
  return (values: T[]) => {
    setState(values);
    return true;
  };
}

// This one was fun. Turns [[1,3], [5, 9]] => [1,2,3,5,6,7,8,9]
function pairVectorToRange(input: Pair[]) {
  return input.reduce<number[]>((a, pair) => a.concat(Array.from(Array(pair[1] - pair[0] + 1).keys()).map(x => x + pair[0])), []);
}

function rangeStr(min: number, max: number) {
  return min === max ? `${min}` : (max < 100 ? `${min}-${max}` : `${min}+`);
}

function rangeStrV(v: [number, number]) {
  return rangeStr(v[0], v[1]);
}

export function RunZoneChartPage() {

  // Set up state/hooks
  const [user, setUser] = useState<boolean>(false);
  const [customFilterData, setCustomFilterData] = useState<CustomFilters>(CustomFilters.default());
  const [dataStoreData, setDataStoreData] = useState<Store>(Store.default());
  const [team, setTeam] = useState<Team>(defaultTeam());
  const [filteredDistances, setFilteredDistances] = useState<Pair[]>([]);
  const [filteredDowns, setFilteredDowns] = useState<number[]>([1, 2, 3, 4]);
  const [filteredHashes, setFilteredHashes] = useState<Hash[]>([Hash.Left, Hash.Middle, Hash.Right]);
  const [filteredFieldZones, setFilteredFieldZones] = useState<Pair[]>([]);
  const [filteredPreviousGains, setFilteredPreviousGains] = useState<Pair[]>([]);
  const [filteredPlayNames, setFilteredPlayNames] = useState<string[]>([]);
  const [filteredFormations, setFilteredFormations] = useState<string[]>([]);
  const [filteredTeamNames, setFilteredTeamNames] = useState<string[]>([]);
  const [filteredPossessionGames, setFilteredPossessionGames] = useState<string[]>([]);
  const [filteredStrongWeak, setFilteredStrongWeak] = useState<string[]>(['Strong', 'Weak']);
  const [filteredBackfields, setFilteredBackfields] = useState<string[]>([]);
  const [filteredReceiverSets, setFilteredReceiverSets] = useState<string[]>([]);
  const [filteredPersonnelSets, setFilteredPersonnelSets] = useState<string[]>([]);
  const [filteredBallCarriers, setFilteredBallCarriers] = useState<string[]>([]);
  const [filteredDirections, setFilteredDirection] = useState<string[]>(['Left', 'Balanced', 'Right']);
  const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(false);
  const [field3D, setField3D] = useState<boolean>(false);
  const [targetedTeamId, setTargetedTeamId] = useState<string>('0');
  const history = useHistory();

  useLayoutEffect(() => {
    const s = [
      customFiltersStorage.subscribe(setCustomFilterData),
      dataStorage.subscribe(setDataStoreData),
      teamStorage.subscribe(setTeam),
    ];
    return () => s.forEach(s => s?.unsubscribe());
  });

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user !== null);
      const queryString = history.location.search.substr(1);
      const matches = queryString.match(/team=(\d+)/);
      if (matches) {
        setTargetedTeamId(matches[1]);
      }
    });
  }, []);

  const store = Store.copy(dataStoreData);
  const customFilters = CustomFilters.copy(customFilterData);

  function filter(play: Play) {
    const personnelFormations: string[] = [];
    filteredPersonnelSets.forEach(personnelNumber => {
      for (const row of customFilters.formations) {
        if (row[1] === personnelNumber) {
          personnelFormations.push(row[0].toLowerCase());
        }
      }
    });
    const recSetNoAlias: string[] = Array.from(filteredReceiverSets.map((s) => s.toLowerCase()));
    const found: string[] = [];
    for (const alias of customFilters.receiverSets) {
      let index = recSetNoAlias.indexOf(alias[1].toLowerCase());
      if (index >= 0) {
        recSetNoAlias.splice(index, 1, alias[0].toLowerCase());
        found.push(alias[1]);
      } else {
        if (found.indexOf(alias[1]) >= 0) { //only push if this alias is in the filter
          recSetNoAlias.push(alias[0].toLowerCase());
        }
      }
    }
    const formationsWithoutPersonnel = filteredFormations.map(a => a.toLowerCase()).filter(a => !customFilters.formations.map(x => x[0].toLowerCase()).includes(a));
    const offensePlay = play.offensePlay.length > 0 ? play.offensePlay : 'None';
    const offenseFormation = play.offenseFormation.length > 0 ? play.offenseFormation : 'None';
    const possessionValue = store.possessionGame(play);
    const possessionGame = possessionValue === 0
      ? '0' : ((possessionValue || 0) > 0
        ? `+${possessionValue}`
        : `-${possessionValue}`);
    return baseFilter(play)
      && pairVectorToRange(filteredDistances).includes(play.distance)
      && filteredDowns.includes(play.down)
      && pairVectorToRange(filteredFieldZones).includes(play.yardLine)
      && pairVectorToRange(filteredPreviousGains).includes(store.previousPlay(play)?.distance || 0)
      && (play.offensePlay.length < 1 || filteredPlayNames.includes(offensePlay))
      && (recSetNoAlias.includes(`${play.receiverSet.toLowerCase()}`))
      && filteredFormations.includes(offenseFormation)
      && (personnelFormations.includes(offenseFormation.toLowerCase())
        || formationsWithoutPersonnel.includes(offenseFormation.toLowerCase()))
      && filteredTeamNames.includes(play.opponent)
      && filteredHashes.includes(play.hash)
      && (!play.backfield || filteredBackfields.includes(play.backfield))
      && ((filteredStrongWeak.includes('Strong') && play.offenseStrength.isStrong)
        || (filteredStrongWeak.includes('Middle') && play.offenseStrength.isMiddleStrength)
        || (filteredStrongWeak.includes('Weak') && play.offenseStrength.isWeak))
      && (possessionGame === undefined || filteredPossessionGames.includes(possessionGame))
      && (isNaN(play.ballCarrier) || filteredBallCarriers.includes(`${play.ballCarrier}`))
      && (play.offenseTendency === Tendency.Error || filteredDirections.includes(play.offenseTendency));
  }

  // Choices
  const distanceChoices = new Map<Pair, string>();
  for (const distance of customFilters.distances) {
    distanceChoices.set(distance, rangeStrV(distance));
  }

  const downChoices = new Map<number, string>();
  downChoices.set(1, '1');
  downChoices.set(2, '2');
  downChoices.set(3, '3');
  downChoices.set(4, '4');

  const hashChoices = new Map<Hash, string>();
  hashChoices.set(Hash.Left, 'L');
  hashChoices.set(Hash.Middle, 'MD');
  hashChoices.set(Hash.Right, 'R');

  const fieldZoneChoices = new Map<Pair, string>();
  for (let fieldZone of customFilters.fieldZones) {
    fieldZoneChoices.set(fieldZone[1], fieldZone[0]);
  }

  const previousGainChoices = new Map<Pair, string>();
  for (const gainChoice of customFilters.gainLosses) {
    previousGainChoices.set(gainChoice[1], gainChoice[0]);
  }
  previousGainChoices.set(noGain, 'No Gain');
  previousGainChoices.set(loss, 'Loss');

  const opponentChoices = new Map<string, string>();
  store.opponentsOf(targetedTeamId).forEach(opponent => opponentChoices.set(opponent, opponent));

  function personnelTable(chart: RunZoneChart) {
    const personnelTable = new Map<string, [string, number, number]>();
    chart.formationTable.forEach(row => {
      for (const personnelMapping of customFilters.formations) {
        if (personnelMapping[0].toLowerCase() === row[0].toLowerCase()) {
          const mapping = personnelTable.get(row[0]);
          if (mapping !== undefined) {
            personnelTable.set(personnelMapping[1], [personnelMapping[1], mapping[1] + row[1], 0]);
          } else {
            personnelTable.set(personnelMapping[1], [personnelMapping[1], row[1], 0]);
          }
        }
      }
    });
    const total = Array.from(personnelTable.values()).reduce((a, e) => a + e[1], 0);
    for (const key of personnelTable.keys()) {
      const mapping = personnelTable.get(key);
      if (mapping) {
        personnelTable.set(key, [mapping[0], mapping[1], Math.max(0, mapping[1] / total - 0.001)]);
      }
    }
    return personnelTable;
  }

  function receiverSetTable(chart: RunZoneChart) {
    const recSetTable = new Map<string, [string, number, number]>();
    chart.receiverSetTable.forEach(row => {
      let found: boolean = false;
      for (const recSetMapping of customFilters.receiverSets) {
        if (recSetMapping[0].toLowerCase() === row[0].toLowerCase()) {
          const mapping = recSetTable.get(recSetMapping[1]);
          found = true;
          if (mapping !== undefined) {
            recSetTable.set(recSetMapping[1], [recSetMapping[1], mapping[1] + row[1], 0]);
          } else {
            recSetTable.set(recSetMapping[1], [recSetMapping[1], row[1], 0]);
          }
        }
      }
      if (!found) { // this formation strength hasn't been mapped, still needs to be added
        recSetTable.set(row[0], row);
      }
    });
    const total = Array.from(recSetTable.values()).reduce((a, e) => a + e[1], 0);
    for (const key of recSetTable.keys()) {
      const mapping = recSetTable.get(key);
      if (mapping) {
        recSetTable.set(key, [mapping[0], mapping[1], Math.max(0, mapping[1] / total - .001)])
      }
    }
    return recSetTable;
  }

  const chart = new RunZoneChart(store, store.plays(targetedTeamId, baseFilter), store.plays(targetedTeamId, filter));

  const maxGap = Array.from(chart.runGaps.values()).reduce((a, e) => Math.max(a, e), 0);
  interface RunGapProps {
    gapNumber: RunGap,
  }
  function RunGap(props: RunGapProps) {
    const plays = chart.runGaps.get(props.gapNumber) || 0;
    const perc = plays / Math.max(1, maxGap);
    const height = perc * 250;

    return (<li className="col-span-1 pt-12 mt-8">
      <div className="flex flex-col justify-end h-64">
        <div className="text-center items-center text-lg text-primary-500 gap-arrow mt-2">
          <div className="mb-2 text-secondary-400 text-2xl font-bold">{(plays / Math.max(1, chart.plays) * 100).toFixed(0)}%<span className="py-1 text-primary-500 text-lg ml-1">{plays}</span></div>
          <FontAwesomeIcon icon={['fas', 'chevron-up']} className="mt-4 text-primary-500 text-md block" />
          <div className={`flex flex-col border-l-4 w-0 mx-auto border-primary-400 bg-opacity-75 rounded-lg shadow-lg hover:bg-opacity-75`} style={{ height: height }} />
          <div className="text-center w-full mt-2 text-gray-500 text-lg">{props.gapNumber}</div>
        </div>
      </div>
    </li>);
  }

  function PlayerCircle() {
    return (<li className="relative">
      <div className="absolute bottom-0 w-full">
        <div className="absolute bottom-0 rounded-full bg-gray-800 bg-opacity-75 h-8 w-8 mx-auto mb-4" />
      </div>
    </li >);
  }
  function PlayerCircleRB() {
    return (<li className="relative">
      <div className="rounded-full bg-gray-800 bg-opacity-75 h-8 w-8 mx-auto mb-4" />
    </li>);
  }
  function PlayerSquare() {
    return (<li className="relative">
      <div className="absolute bottom-0 w-full">
        <div className="bg-gray-800 bg-opacity-75 h-8 w-8 mx-auto mb-4" />
      </div>
    </li >);
  }

  interface RevertState {
    filteredDistances: Pair[],
    filteredDowns: number[],
    filteredFieldZones: Pair[],
    filteredPreviousGains: Pair[],
    filteredPlayNames: string[],
    filteredFormations: string[],
    filteredTeamNames: string[],
    filteredPossessionGames: string[],
    filteredHashes: Hash[],
    filteredStrongWeak: string[],
    filteredBackfields: string[],
    filteredReceiverSets: string[],
    filteredPersonnelSets: string[],
    filteredDirection: string[],
    filteredBallCarriers: string[],
  }
  const [revertState, setRevertState] = useState<RevertState>({
    filteredDistances: [],
    filteredDowns: [],
    filteredFieldZones: [],
    filteredPreviousGains: [],
    filteredPlayNames: [],
    filteredFormations: [],
    filteredTeamNames: [],
    filteredPossessionGames: [],
    filteredHashes: [],
    filteredStrongWeak: [],
    filteredBackfields: [],
    filteredReceiverSets: [],
    filteredPersonnelSets: [],
    filteredDirection: [],
    filteredBallCarriers: [],
  });
  function revert() {
    setFilteredDistances(revertState.filteredDistances);
    setFilteredDowns(revertState.filteredDowns);
    setFilteredFieldZones(revertState.filteredFieldZones);
    setFilteredPreviousGains(revertState.filteredPreviousGains);
    setFilteredPlayNames(revertState.filteredPlayNames);
    setFilteredFormations(revertState.filteredFormations);
    setFilteredTeamNames(revertState.filteredTeamNames);
    setFilteredPossessionGames(revertState.filteredPossessionGames);
    setFilteredHashes(revertState.filteredHashes);
    setFilteredStrongWeak(revertState.filteredStrongWeak);
    setFilteredBackfields(revertState.filteredBackfields);
    setFilteredReceiverSets(revertState.filteredReceiverSets);
    setFilteredPersonnelSets(revertState.filteredPersonnelSets);
    setFilteredDirection(revertState.filteredDirection);
    setFilteredBallCarriers(revertState.filteredBallCarriers);
  }

  // Update all filters when cfs & play data changes.
  useEffect(() => {
    const store = Store.copy(dataStoreData);
    const chart = (() => {
      const plays = store.plays(targetedTeamId, baseFilter);
      return new RunZoneChart(store, plays, plays);
    })();

    setFilteredTeamNames(store.opponentsOf(targetedTeamId));
    setFilteredPlayNames(Array.from(chart.playTable.keys()));
    setFilteredFormations(Array.from(chart.formationTable.keys()));
    setFilteredPossessionGames(Array.from(chart.possessionGameTable.keys()));
    setFilteredBackfields(Array.from(chart.backfieldTable.keys()));
    setFilteredReceiverSets(Array.from(receiverSetTable(chart).keys()));
    setFilteredBallCarriers(Array.from(chart.ballCarrierTable.keys()));
    setFilteredPersonnelSets(Array.from(personnelTable(chart).keys()));

    setFilteredDistances(customFilters.distances);
    setFilteredFieldZones(customFilters.fieldZones.map(a => a[1]));
    setFilteredPreviousGains(customFilters.gainLosses.map(a => a[1]).concat([noGain, loss]));

    setRevertState({
      filteredDistances: customFilters.distances,
      filteredDowns,
      filteredFieldZones: customFilters.fieldZones.map(a => a[1]),
      filteredPreviousGains: customFilters.gainLosses.map(a => a[1]).concat([noGain, loss]),
      filteredPlayNames: Array.from(chart.playTable.keys()),
      filteredFormations: Array.from(chart.formationTable.keys()),
      filteredTeamNames: store.opponentsOf(targetedTeamId),
      filteredPossessionGames: Array.from(chart.possessionGameTable.keys()),
      filteredHashes,
      filteredStrongWeak,
      filteredBackfields: Array.from(chart.backfieldTable.keys()),
      filteredReceiverSets: Array.from(receiverSetTable(chart).keys()),
      filteredPersonnelSets: Array.from(personnelTable(chart).keys()),
      filteredDirection: Array.from(chart.rightLeftTable.keys()),
      filteredBallCarriers: Array.from(chart.ballCarrierTable.keys()),
    });
  }, [dataStoreData, customFilterData, team, targetedTeamId]);

  if (!user) {
    return <LoggedOut />;
  }

  return (<ShellWithSideBar teamId={targetedTeamId}>
    {!sideMenuOpen &&
      <button onClick={() => setSideMenuOpen(true)} className="filter-button z-20 px-4 py-3 mx-2 md:mx-4 lg:mx-6 rounded-full bg-primary-600">
        <FontAwesomeIcon icon={['fas', 'cog']} className="text-xl" />
        <span className="filter-field-text ml-2">Filter Scouting Data</span>
      </button>
    }
    <button onClick={revert} className="revert-button z-20 px-4 py-3 mx-2 md:mx-4 lg:mx-6 rounded-full bg-red-600">
      <FontAwesomeIcon icon={['fas', 'sync-alt']} className="text-xl" />
      <span className="revert-text ml-2">Revert Filters</span>
    </button>
    {!sideMenuOpen &&
      <button onClick={() => setField3D(!field3D)} className="perspective-button z-20 px-4 py-3 mx-2 md:mx-4 lg:mx-6 rounded-full bg-secondary-600">
        <FontAwesomeIcon icon={['fas', field3D ? 'square' : 'cube']} className="text-xl" />
        <span className="perspective-text ml-2">{field3D ? '3D' : '2D'} View</span>
      </button>
    }
    <div className="w-screen">
      <ul className="dark-bg shadow-inner grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 px-6 gap-6 text-left mb-6">
        <li className="col-span-5 flex mt-5">
          <FootballField threeD={field3D}>
            <Block>
              <div className="py-3">
                <ul className="grid grid-cols-7 xl:max-w-xl mx-auto justify-center">
                  <li className="col-span-3">
                    <ul className="grid grid-cols-7 xl:max-w-xl mx-auto justify-around">
                      <RunGap gapNumber={7} />
                      <PlayerCircle />
                      <RunGap gapNumber={5} />
                      <PlayerCircle />
                      <RunGap gapNumber={3} />
                      <PlayerCircle />
                      <RunGap gapNumber={1} />
                    </ul>
                  </li>

                  <PlayerSquare />

                  <li className="col-span-3">
                    <ul className="grid grid-cols-7 xl:max-w-lg mx-auto justify-around">
                      <RunGap gapNumber={0} />
                      <PlayerCircle />
                      <RunGap gapNumber={2} />
                      <PlayerCircle />
                      <RunGap gapNumber={4} />
                      <PlayerCircle />
                      <RunGap gapNumber={6} />
                    </ul>
                  </li>
                  <li className="col-span-3" />
                  <PlayerCircleRB />
                  <li className="col-span-3" />
                </ul>
              </div>
            </Block>
          </FootballField>
        </li>
      </ul>
      <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 px-6 gap-6 max-w-screen-xl mx-auto">
        <li>
          <GridCellSplit label="Total Run Plays" value={`${chart.plays}`} label2="Yds/Carry" value2={chart.yardsPerCarry.toFixed(1)} />
          <div className="mt-7" />
          <GridCell height={24} padding={0} className="flex flex-col">
            <Table
              initialSort={[1, 'desc']}
              data={chart.backfieldTable}
              titles={['Backfield', 'Att', 'Call %']}
              onChange={mapSet(setFilteredBackfields)}
              selected={filteredBackfields}
            />
          </GridCell>
        </li>
        <GridCell height={56} padding={0} className="bg-opacity-75">
          <Table
            initialSort={[1, 'desc']}
            data={chart.playTable}
            titles={['Run Play', 'Att', 'Avg. Gain']}
            onChange={mapSet(setFilteredPlayNames)}
            selected={filteredPlayNames}
          />
        </GridCell>
        <GridCell height={56} padding={0} className="bg-opacity-75">
          <Table
            initialSort={[1, 'desc']}
            data={chart.formationTable}
            titles={['Formation', 'Att', 'Call %']}
            onChange={mapSet(setFilteredFormations)}
            selected={filteredFormations}
          />
        </GridCell>
        <li>
          <GridCell2Responsive
            values={['Strong', 'Weak']}
            selected={filteredStrongWeak}
            onChange={setFilteredStrongWeak}
            label="Strong" value={chart.strongWeakTable.get('Strong') || 0}
            label2="Weak" value2={chart.strongWeakTable.get('Weak') || 0}
            total={Array.from(chart.strongWeakTable.values()).reduce((sum, n) => sum + n, 0)} />
          <div className="mt-6" />
          <GridCellSplit3
            values={['Field', 'Balanced', 'Boundary']}
            selected={filteredDirections}
            onChange={setFilteredDirection}
            label="Left" value={chart.rightLeftTable.get('Left') || 0}
            label2="Balanced" value2={chart.rightLeftTable.get('Balanced') || 0}
            label3="Right" value3={chart.rightLeftTable.get('Right') || 0}
            total={Array.from(chart.rightLeftTable.values()).reduce((sum, n) => sum + n, 0)} />
        </li>
        <GridCell height={56} padding={0} className="flex flex-col">
          <Table
            initialSort={[1, 'desc']}
            data={chart.ballCarrierTable}
            titles={['Ball Carrier', 'Att', 'Call %']}
            onChange={mapSet(setFilteredBallCarriers)}
            selected={filteredBallCarriers}
          />
        </GridCell>
        <GridCell height={56} padding={0}>
          <Table
            initialSort={[1, 'desc']}
            data={chart.possessionGameTable}
            titles={['Poss. Game', 'Att', '%']}
            onChange={mapSet(setFilteredPossessionGames)}
            selected={filteredPossessionGames}
          />
        </GridCell>
        <GridCell height={56} padding={0}>
          <Table
            initialSort={[1, 'desc']}
            data={receiverSetTable(chart)}
            titles={['Receiver Set', 'Att', 'Call %']}
            onChange={mapSet(setFilteredReceiverSets)}
            selected={filteredReceiverSets}
            priority={Array.from(customFilters.receiverSets.map((set) => set[1]))}
          />
        </GridCell>
        <GridCell height={56} padding={0} primaryText="Customize" primaryIcon={['fas', 'wrench']} primaryClick={() => history.push('/custom-filters/formations')}>
          <Table
            initialSort={[1, 'desc']}
            data={personnelTable(chart)}
            titles={['Personnel', 'Att', 'Call %']}
            onChange={mapSet(setFilteredPersonnelSets)}
            selected={filteredPersonnelSets}
          />
        </GridCell>
      </ul>
      {sideMenuOpen &&
        <div className="filter-slideout bg-gray-900 border-l border-gray-800 z-20 text-left shadow-xl">
          <div className="overflow-y-scroll max-h-screen pb-56 pt-12">
            <button onClick={() => setSideMenuOpen(false)} className="float-right m-2">
              <FontAwesomeIcon icon={['fas', 'times']} className="text-2xl" />
            </button>
            <button type="button" onClick={revert} className="items-center px-6 py-3 text-sm leading-5 font-sm rounded-md text-white bg-red-600 rounded-full hover:text-white hover:bg-red-700 focus:outline-none focus:shadow-outline-blue focus:border-secondary-600 active:bg-secondary-600 active:text-white transition ease-in-out duration-150">
              <FontAwesomeIcon icon={['fas', 'sync']} className="h-3 w-3" />
              <span className="ml-2">Revert Filters</span>
            </button>
            <MapSelect display="block" label="Opponent" choices={opponentChoices} selected={filteredTeamNames} onChange={mapSet(setFilteredTeamNames)} />
            <MapSelect display="inline" label="Down" choices={downChoices} selected={filteredDowns} onChange={mapSet(setFilteredDowns)} />
            <MapSelect display="inline" label="Hash" choices={hashChoices} selected={filteredHashes} onChange={mapSet(setFilteredHashes)} />
            <MapSelect display="inline" label="Distance (Yds)" choices={distanceChoices} selected={filteredDistances} onChange={mapSet(setFilteredDistances)} />
            <MapSelect display="block-open" label="Field Zone" choices={fieldZoneChoices} selected={filteredFieldZones} onChange={mapSet(setFilteredFieldZones)} />
            <MapSelect display="block-open" label="Previous Play" choices={previousGainChoices} selected={filteredPreviousGains} onChange={mapSet(setFilteredPreviousGains)} />
          </div>
        </div>
      }
    </div>
  </ShellWithSideBar>);
}
